import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Store recommendation modifications by patientId and recommendationId
  recommendationUpdates: {},
  // Store flagged recommendations so they remain visible
  activeFlaggedRecommendations: {},
};

export const smartRecommendationsSlice = createSlice({
  name: "smartRecommendations",
  initialState,
  reducers: {
    // Update a single recommendation status
    updateRecommendation: (state, { payload }) => {
      const { patientId, recommendationId, status, feedback, item } = payload;

      // Create nested structure if it doesn't exist
      if (!state.recommendationUpdates[patientId]) {
        state.recommendationUpdates[patientId] = {};
      }

      // Store the update
      state.recommendationUpdates[patientId][recommendationId] = {
        status,
        feedback,
      };

      // If the status is "Rejected" (flagged) or "Refused" (X), store the full recommendation
      // so we can keep it visible even after navigation
      if (status === "Rejected" || status === "Refused") {
        // Create nested structure if it doesn't exist
        if (!state.activeFlaggedRecommendations[patientId]) {
          state.activeFlaggedRecommendations[patientId] = {};
        }

        // Store the full item with updated status
        if (item) {
          state.activeFlaggedRecommendations[patientId][recommendationId] = {
            ...item,
            status,
            feedback,
          };
        }
      }
      // If the status changed from Rejected/Refused to something else,
      // remove it from flagged items
      else if (
        status !== "Rejected" &&
        status !== "Refused" &&
        state.activeFlaggedRecommendations[patientId]?.[recommendationId]
      ) {
        delete state.activeFlaggedRecommendations[patientId][recommendationId];
      }
    },

    // Keep a recommendation with special status visible
    keepFlaggedVisible: (state, { payload }) => {
      const { patientId, item } = payload;

      // Only store if it's a valid item and has a Rejected or Refused status
      if (
        item &&
        item.id &&
        (item.status === "Rejected" || item.status === "Refused")
      ) {
        // Create nested structure if it doesn't exist
        if (!state.activeFlaggedRecommendations[patientId]) {
          state.activeFlaggedRecommendations[patientId] = {};
        }

        // Store the full item
        state.activeFlaggedRecommendations[patientId][item.id] = item;
      }
    },

    // Reset all Smart Recommendations state
    resetRecommendations: (state) => {
      state.recommendationUpdates = {};
      state.activeFlaggedRecommendations = {};
    },

    // Reset only flagged/refused recommendations for a specific patient
    resetPatientRecommendations: (state, { payload }) => {
      const { patientId } = payload;

      // Reset only for the specific patient
      if (state.recommendationUpdates[patientId]) {
        state.recommendationUpdates[patientId] = {};
      }

      if (state.activeFlaggedRecommendations[patientId]) {
        state.activeFlaggedRecommendations[patientId] = {};
      }
    },
  },
});

export const {
  updateRecommendation,
  resetRecommendations,
  resetPatientRecommendations,
  keepFlaggedVisible,
} = smartRecommendationsSlice.actions;

export default smartRecommendationsSlice.reducer;
