import { createSlice } from "@reduxjs/toolkit";

export const initialGetFlagsState = {
  flagsIsLoading: false,
  flagsSuccess: null,
  flagsData: {},
};

const name = "getFlags";

export const getFlagsSlice = createSlice({
  name,
  initialState: initialGetFlagsState,
  reducers: {
    getFlags: (state, action) => {
      state.flagsIsLoading = true;
    },
    getFlagsSuccess: (state, action) => {
      state.flagsIsLoading = false;
      state.flagsSuccess = true;
      state.flagsData = action.payload;
    },
    getFlagsFail: (state, action) => {
      state.flagsIsLoading = false;
      state.flagsSuccess = false;
      state.flagsData = {};
    },
    clearGetFlagsData: (state, action) => {
      state.flagsIsLoading = false;
      state.flagsSuccess = null;
      state.flagsData = {};
    },
  },
});

export const { getGetFlags, clearGetFlagsData } = getFlagsSlice.actions;

export default getFlagsSlice.reducer;

export const getFlagsApi = () => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/feature-flags/`,
  },
  type: "getFlags",
});
