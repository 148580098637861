import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { IconButton } from "components/common/Button/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "components/common/FormComponents/Form";
import { Trash } from "components/common/SVG/Trash";
import EditIcon from "@mui/icons-material/EditOutlined";
import SelectField from "components/common/FormComponents/SelectField";
import { fetchLabs } from "apiClients/encounters";
import ItemDisplayWrapper from "./ItemDisplayWrapper";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";

const LabDisplay = ({
  sx,
  lab,
  onDelete,
  setStatus,
  aiGenerated,
  note,
  setShowNotesInput,
  showStatus,
}) => {
  return (
    <ItemDisplayWrapper
      sx={sx}
      showEdit={false}
      onDelete={onDelete}
      setStatus={setStatus}
      aiGenerated={aiGenerated}
      title={`${lab?.lab_name}`}
      showFavorite={false}
      canBeDeleted={lab?.aiGenerated ? false : true}>
      {note && showStatus && (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Text
            fontWeight={500}
            variant="caption"
            color="textSecondary"
            sx={{ marginTop: "4px" }}
            whiteSpace="normal">
            {note}
          </Text>
          <Box
            onClick={() => setShowNotesInput(true)}
            sx={{
              marginLeft: "8px",
              height: "10px",
              width: "10px",
            }}>
            <EditIcon sx={{ height: "20px", width: "20px" }} />
          </Box>
        </Box>
      )}
    </ItemDisplayWrapper>
  );
};
const LabForm = ({ labs, onSubmit, onCancel, aiGenerated = false }) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLab, setSelectedLab] = useState(null);

  const handleSearch = async (value) => {
    if (!value) return;
    setIsLoading(true);
    try {
      const results = await fetchLabs({ query: value });

      const mappedResults = results
        .map((s) => ({
          label: s?.test_name,
          value: s?.test_code,
          cpt_codes: s?.test_cpt_code,
          instruction: s?.test_cpt_code,
        }))
        .filter((item) => !labs?.some((l) => l.lab_name === item.label));
      setOptions(mappedResults);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const showSubmitButton = selectedLab;

  const handleSubmit = () => {
    if (!selectedLab) return;
    onSubmit({
      aiGenerated,
      canBeDeleted: !aiGenerated,
      lab_name: selectedLab.label.trim(),
      lab_code: selectedLab.value,
      cpt_codes: selectedLab.cpt_codes,
      status: "Accepted",
    });
  };

  return (
    // TODO - switch to grid
    <Box
      display="flex"
      flexDirection="row"
      sx={{ marginTop: "12px", width: "100%" }}>
      <EditIcon
        sx={{ color: theme.palette.common.purple, margin: "4px 4px 0 0" }}
      />
      <Form>
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
          <Box display="flex" flexDirection="row">
            <Box sx={{ marginRight: "8px" }}>
              <SelectField
                disabled={aiGenerated}
                placeholder="Search Lab Keyword"
                onSearch={debounce((value) => {
                  handleSearch(value);
                }, DEBOUNCE_DELAY)}
                options={options}
                loading={isLoading}
                value={selectedLab}
                onChange={(_, v) => setSelectedLab(v)}
                openOnFocus={false}
                extendedSearchOptions={["description"]}
                showMoreInLabel={{ show: true, value: "value" }}
                onClear={() => setSelectedLab(null)}
                sx={{ width: "450px" }}
              />
            </Box>
            {showSubmitButton && (
              <IconButton
                height="40px"
                onClick={handleSubmit}
                sx={{ minWidth: "40px" }}>
                <CheckIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            )}
            <IconButton
              onClick={onCancel}
              sx={{
                marginLeft: "8px",
                height: "40px",
                width: "40px",
              }}>
              <Trash />
            </IconButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export const Lab = ({
  sx,
  lab,
  labs,
  onEdit,
  setLabs,
  labReqId,
  mutation,
  createNewLab,
  aiGenerated = false,
  onDelete = () => {},
  setShowInput = () => {},
}) => {
  const [note, setNote] = useState(lab?.note || "");
  const [labStatus, setLabStatus] = useState("");
  const [showNotesInput, setShowNotesInput] = useState(false);

  function hitMutation(val) {
    val.lab_request_id = labReqId;
    mutation.mutateAsync(val);
  }

  const handleDelete = () => {
    lab.delete = true;
    hitMutation(lab);
    onDelete(lab.id);
    if (!lab?.aiGenerated) {
      setLabs((prevLabs) =>
        prevLabs.map((l) =>
          l.lab_name === lab.lab_name ? { ...l, deleted: true } : l,
        ),
      );
    }
    setShowInput(false);
  };

  const setStatus = (feedback) => {
    if (feedback === "Flagged") setShowNotesInput(true);
    else {
      setShowNotesInput(false);
    }
    const updateLab = {
      ...lab,
      status: feedback,
    };
    setLabStatus(feedback === "Flagged" ? "Flagged" : "");
    setLabs(labs.map((l) => (l.lab_code === lab.lab_code ? updateLab : l)));
    if (aiGenerated)
      onEdit(lab.lab_name, feedback !== "" ? feedback : "Ordered");
  };

  const handleSubmit = (labData) => {
    let formattedLab = {
      name: labData.lab_name.trim(),
      code: labData.lab_code,
      cpt_codes: labData.cpt_codes,
    };
    hitMutation(formattedLab);
    setLabs([...(labs || []), labData]);
    setShowInput(false);
  };

  if (createNewLab) {
    return (
      <LabForm
        onSubmit={handleSubmit}
        onCancel={() => {
          setShowInput(false);
        }}
        labs={labs}
        aiGenerated={aiGenerated}
      />
    );
  }

  return (
    <>
      <LabDisplay
        sx={sx}
        lab={lab}
        note={note}
        setStatus={setStatus}
        onDelete={handleDelete}
        aiGenerated={aiGenerated}
        setShowNotesInput={setShowNotesInput}
        showStatus={!showNotesInput && labStatus === "Flagged"}
      />
      {showNotesInput && labStatus === "Flagged" && (
        <Box sx={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
          <InputField
            defaultValue={note}
            sx={{ width: "100%" }}
            inputLabel={"Enter note"}
            onChange={(e) => setNote(e.target.value)}
          />
          <IconButton
            height="40px"
            onClick={() => {
              setShowNotesInput(false);
              onEdit(lab.lab_name, "", note);
            }}
            sx={{ minWidth: "40px", marginLeft: "8px" }}>
            <CheckIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </Box>
      )}
    </>
  );
};
