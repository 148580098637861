import React, { useRef } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Text from "./common/Typography/Text";
import { LogoIcon } from "assets/svg/Logo";

const PatientVisitSummaryPDF = ({ patientData, onComplete }) => {
  const printRef = useRef(null);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return moment(dateString).format("MM/DD/YYYY");
  };

  const generatePDF = async () => {
    if (!printRef.current) return;

    try {
      const printContent = printRef.current;
      const canvas = await html2canvas(printContent, {
        scale: 2,
        logging: false,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const paddingTop = 7;
      const paddingBottom = 7;
      const paddingLeft = 7;
      const paddingRight = 7;

      const availablePageHeight = pageHeight - paddingTop - paddingBottom;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = 0;

      while (position < canvas.height) {
        // Create a new canvas for the cropped part
        const croppedCanvas = document.createElement("canvas");
        const ctx = croppedCanvas.getContext("2d");

        // Set the dimensions for the cropped canvas
        croppedCanvas.width = canvas.width;
        croppedCanvas.height = (availablePageHeight * canvas.width) / imgWidth;

        // Draw the cropped portion onto the new canvas
        ctx.drawImage(
          canvas,
          0,
          position, // Source: x, y (starting point in original canvas)
          canvas.width,
          croppedCanvas.height, // Source: width, height
          0,
          0, // Destination: x, y (starting point in new canvas)
          croppedCanvas.width,
          croppedCanvas.height, // Destination: width, height
        );

        const croppedImgData = croppedCanvas.toDataURL("image/png");

        // Add the cropped portion to the PDF
        pdf.addImage(
          croppedImgData,
          "PNG",
          paddingLeft,
          paddingTop,
          imgWidth - paddingLeft - paddingRight,
          availablePageHeight,
        );

        position += croppedCanvas.height; // Move position down for the next segment

        if (position < canvas.height) {
          pdf.addPage();
        }
      }

      // Open the PDF in a new tab instead of saving
      window.open(pdf.output("bloburl"), "_blank");

      if (onComplete) onComplete();
    } catch (error) {
      if (onComplete) onComplete(error);
    }
  };

  // Call generatePDF immediately after render
  React.useEffect(() => {
    // Small timeout to ensure the ref is properly attached
    const timer = setTimeout(() => {
      generatePDF();
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{ position: "absolute", left: "-9999px", top: 0, padding: 2 }}>
      <Paper ref={printRef} sx={{ p: 4, width: "210mm" }}>
        {/* Nephrolytics Header */}
        <Box sx={{ mb: 2, display: "flex", gap: 1 }}>
          <Typography fontSize={"12px"} variant="h6" color="text.secondary">
            Powered By
          </Typography>
          <Box>
            <LogoIcon height="14px" width="14px" />
          </Box>
          <Typography
            fontSize={"13px"}
            variant="h6"
            fontWeight={600}
            color="text.secondary">
            Nephrolytics
          </Typography>
        </Box>

        {/* Patient Information Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            fontSize={"16px"}
            variant="h6"
            color="primary"
            fontWeight={600}
            sx={{ mb: 1 }}>
            PATIENT INFORMATION
          </Typography>
          <Box sx={{ display: "grid", gridTemplateColumns: "140px 1fr" }}>
            <Typography fontSize={"14px"} fontWeight={600}>
              Name:
            </Typography>
            <Typography fontSize={"14px"}>
              {patientData.patient_info.name}
            </Typography>

            <Typography fontSize={"14px"} fontWeight={600}>
              Date Of Birth:
            </Typography>
            <Typography fontSize={"14px"}>
              {formatDate(patientData.patient_info.date_of_birth)}
            </Typography>

            <Typography fontSize={"14px"} fontWeight={600}>
              Date Of Visit:
            </Typography>
            <Typography fontSize={"14px"}>
              {formatDate(patientData.patient_info.date_of_visit)}
            </Typography>

            <Typography fontSize={"14px"} fontWeight={600}>
              Provider:
            </Typography>
            <Typography fontSize={"14px"}>
              {patientData.patient_info.provider_name}
            </Typography>
          </Box>

          <Typography
            fontSize={"16px"}
            variant="h6"
            color="primary"
            fontWeight={600}
            sx={{ mt: 2 }}>
            REASON FOR VISIT
          </Typography>
          <Typography fontSize={"14px"}>
            {patientData.reason_for_visit}
          </Typography>
        </Box>

        {/* Action Items Section */}
        {patientData.action_items && (
          <Box sx={{ mb: 2 }}>
            <Typography
              fontSize={"16px"}
              variant="h6"
              color="primary"
              fontWeight={600}
              sx={{ mb: 1 }}>
              ACTION ITEMS
            </Typography>

            {patientData.action_items?.medications?.length > 0 && (
              <Box sx={{ mb: 1 }}>
                <Typography fontWeight={600} sx={{ mb: 1 }}>
                  Medications
                </Typography>
                <List disablePadding>
                  {patientData.action_items.medications.map((med, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemText
                        primary={
                          <Box pl={1} component="div">
                            •{" "}
                            <Box fontSize={"14px"} component="span">
                              {med.medication}
                            </Box>
                            {med.instructions && (
                              <Box fontSize={"14px"} component="span">
                                {" "}
                                ({med.instructions}):{" "}
                              </Box>
                            )}
                            {(med.action
                              ? med.action !== "ADD"
                              : med.status !== "Accepted" &&
                                med.status !== "active") && (
                              <Box fontSize={"14px"} component="span">
                                Discontinued
                              </Box>
                            )}
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {patientData.action_items?.labs_ordered?.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography fontWeight={600} sx={{ mb: 1 }}>
                  Labs Ordered
                </Typography>
                <List disablePadding>
                  {patientData.action_items.labs_ordered.map((lab, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemText
                        primary={
                          <Box pl={1} fontSize={"14px"} component="div">
                            • {lab}
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {patientData.action_items?.imaging_ordered?.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography fontWeight={600} sx={{ mb: 1 }}>
                  Imaging
                </Typography>
                <List disablePadding>
                  {patientData.action_items?.imaging_ordered?.map(
                    (imaging, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemText
                          primary={
                            <>
                              {typeof imaging == "string" ? (
                                <Box pl={1} fontSize={"14px"} component="div">
                                  • {imaging}
                                </Box>
                              ) : (
                                <Box pl={1} component="div">
                                  •{" "}
                                  <Box fontSize={"14px"} component="span">
                                    {imaging.category}
                                  </Box>
                                  {imaging.status && (
                                    <Box fontSize={"14px"} component="span">
                                      {" "}
                                      ({imaging.status})
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </>
                          }
                        />
                      </ListItem>
                    ),
                  )}
                </List>
              </Box>
            )}

            {patientData.action_items.referrals.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography fontWeight={600} sx={{ mb: 1 }}>
                  Referrals
                </Typography>
                <List disablePadding>
                  {patientData.action_items.referrals.map((referral, index) => (
                    <ListItem key={index} disablePadding sx={{ py: 0.5 }}>
                      <ListItemText
                        primary={
                          <Box pl={1} fontSize={"14px"} component="div">
                            • {referral}
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {patientData.action_items.follow_up && (
              <Box sx={{ mb: 2 }}>
                <Typography fontWeight={600} sx={{ mb: 1 }}>
                  Follow-Up
                </Typography>
                <List disablePadding>
                  <ListItem disablePadding sx={{ py: 0.5 }}>
                    <ListItemText
                      primary={
                        <Box pl={1} fontSize={"14px"} component="div">
                          • {patientData.action_items.follow_up}
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            )}
          </Box>
        )}

        {/* Education Section */}
        {patientData.education && patientData.education.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography
              fontSize={"16px"}
              variant="h6"
              color="primary"
              fontWeight={600}
              sx={{ mb: 2 }}>
              EDUCATION
            </Typography>
            <List disablePadding>
              {patientData.education.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemText
                    primary={
                      <Box pl={1} fontSize={"14px"} component="div">
                        • {item}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* Lab Results Section */}
        {patientData.labs && patientData.labs.length > 1 && (
          <Box sx={{ mb: 4 }}>
            <Typography
              fontSize={"16px"}
              variant="h6"
              color="primary"
              fontWeight={600}
              sx={{ mb: 2 }}>
              RECENT LABS
            </Typography>

            <TableContainer component={Paper} variant="outlined" sx={{ mb: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell>Lab</TableCell>
                    {patientData.labs[0].slice(1, 4).map((header, index) => (
                      <TableCell key={index} align="center">
                        {header.value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientData.labs.slice(1, 4).map((row, rowIndex) => {
                    // Skip empty rows where all cells except test name are empty
                    if (
                      !row.slice(1).some((cell) => cell.value.trim() !== "")
                    ) {
                      return null;
                    }

                    return (
                      <TableRow
                        key={rowIndex}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
                        }}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: "medium" }}>
                          {row[0].value}
                        </TableCell>
                        {row.slice(1, 4).map((cell, cellIndex) => (
                          <TableCell key={cellIndex} align="center">
                            {cell.value}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {/* Contact Information */}
        <Box sx={{ mt: 4 }}>
          <Typography
            fontSize={"16px"}
            variant="h6"
            color="primary"
            fontWeight={600}
            sx={{ mb: 1 }}>
            CONTACT US
          </Typography>
          <Typography fontSize={"14px"}>{patientData.contact_info}</Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default PatientVisitSummaryPDF;
