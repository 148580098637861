import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Fragment, memo, useState } from "react";
import CustomTooltip from "../Tooltips/CustomTooltip";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 9,
    width: "fit-content",
    "& .MuiButtonBase-root.MuiTab-root": {
      textTransform: "capitalize !important",
      backgroundColor: "transparent",
    },
  },
  selectedTab: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabContainer: {
    overflow: "auto !important",
    display: "block !important",
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, borderTab = false, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      data-testid={`horizontal-tab-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        position: "relative",
        borderTop: borderTab ? " 2px solid #D9D9D9" : "",
        marginTop: borderTab ? "-2px" : "",
        height: "auto",
      }}>
      {value === index && (
        <Box
          sx={{
            height: "100%",
          }}>
          {children}
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const HorizontalTabs = ({
  childrenArray = [],
  borderTab = false,
  isDisplay = true,
  handleChildrenIndex,
  defaultValue = 0,
  setKeyindicatorFlag,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleChildrenIndex && handleChildrenIndex(newValue);
  };
  return (
    <TabContext
      // if children contains more than 1 tab then it will show active tab
      value={childrenArray?.length > 1 ? value : null}
      className={classes.tabContainer}>
      <Box className={classes.root}>
        <TabList
          data-testid={"horizontal-tab-comp"}
          onChange={handleChange}
          orientation="horizontal"
          aria-label="Tabs"
          sx={{
            marginLeft: "10px",
            "& .MuiTabs-indicator": {
              bottom: "0px",
              zIndex: 1,
            },
          }}>
          {childrenArray.map(
            (
              {
                titleDetails,
                tabIcon,
                tabIconSrc,
                left,
                right,
                number,
                isNumber,
                titleIcon,
                tooltip,
              },
              index,
            ) => {
              const titleContent = (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px !important",
                    fontWeight: "600",
                    ...titleDetails?.titleStyle,
                  }}
                  onClick={() => showInfoTooltip && setShowInfoTooltip(false)}>
                  {titleDetails?.title}

                  <CustomTooltip
                    placement="bottom"
                    arrow
                    tooltipText={tooltip?.text}
                    tooltipTextSx={tooltip?.sx}
                    clickToOpen={{
                      isOpenOnClickEnabled: true,
                      isOpen: showInfoTooltip,
                    }}>
                    {titleIcon && (
                      <span
                        style={{
                          marginLeft: "8px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => setShowInfoTooltip(!showInfoTooltip)}>
                        {titleIcon}
                      </span>
                    )}
                  </CustomTooltip>
                  {tabIcon && left && (
                    <span>
                      <img
                        src={tabIconSrc}
                        alt="Tab Icon"
                        style={{ marginRight: "5px" }}
                      />

                      {isNumber && left && (
                        <span
                          style={{
                            background: "#FF5E5E",
                            minWidth: "45px",
                            borderRadius: "15px",
                            padding: "2px 5px",
                            marginLeft: "5px",
                            color: "#ffffff",
                          }}>
                          {number}
                        </span>
                      )}
                    </span>
                  )}
                </div>
              );
              return (
                <Tab
                  data-testid={`horizontalTab-${titleDetails?.title}`}
                  key={index}
                  label={titleContent}
                  onClick={() => {
                    if (titleDetails?.title === "Active Patients") {
                      setKeyindicatorFlag && setKeyindicatorFlag(1);
                    } else if (titleDetails?.title === "ESRD") {
                      setKeyindicatorFlag && setKeyindicatorFlag(2);
                    } else if (titleDetails?.title === "New Patients") {
                      setKeyindicatorFlag && setKeyindicatorFlag(3);
                    } else if (titleDetails?.title === "Revenue") {
                      setKeyindicatorFlag && setKeyindicatorFlag(4);
                    }
                  }}
                  {...a11yProps(value)}
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#FFFFFF",
                    color: "#2D3748 !important",
                    minHeight: "31px",
                    maxWidth: "fit-content",
                    fontSize: "14px",
                    fontWeight: "400 !important",
                    paddingBottom: "0px",
                    marginBottom: "0px",
                  }}
                />
              );
            },
          )}
        </TabList>
      </Box>
      {childrenArray.map(({ tab }, index) => (
        <Fragment key={index}>
          {isDisplay && (
            <CustomTabPanel
              borderTab={borderTab}
              value={value}
              index={index}
              className={classes.tabPanel}>
              {tab}
            </CustomTabPanel>
          )}
        </Fragment>
      ))}
    </TabContext>
  );
};
export default memo(HorizontalTabs);
