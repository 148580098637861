import React from "react";

const EditIcon = ({ height = "12", width = "13", fill = "none" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8171 0.522726C11.1201 -0.174239 9.99015 -0.174245 9.29317 0.522726L8.29386 1.52203L3.59061 6.22527C3.51437 6.30153 3.46029 6.39707 3.43414 6.50166L2.83924 8.88125C2.78856 9.08399 2.84796 9.29845 2.99572 9.44616C3.14348 9.59394 3.35793 9.65337 3.56066 9.60268L5.94022 9.00778C6.04486 8.98161 6.14034 8.92753 6.21661 8.85126L10.8856 4.18225L11.9192 3.14873C12.6161 2.45177 12.6161 1.32176 11.9192 0.624792L11.8171 0.522726ZM10.1345 1.36404C10.3668 1.13172 10.7435 1.13172 10.9758 1.36404L11.0779 1.4661C11.3102 1.69843 11.3102 2.0751 11.0779 2.30742L10.4731 2.91214L9.54785 1.95068L10.1345 1.36404ZM8.70636 2.79214L9.63167 3.75361L5.49185 7.89348L4.23398 8.20794L4.54844 6.95009L8.70636 2.79214ZM1.63169 3.67145C1.63169 3.3429 1.89803 3.07656 2.22658 3.07656H5.20106C5.52962 3.07656 5.79596 2.81021 5.79596 2.48166C5.79596 2.15311 5.52962 1.88676 5.20106 1.88676H2.22658C1.24093 1.88676 0.441895 2.6858 0.441895 3.67145V10.2153C0.441895 11.201 1.24093 12 2.22658 12H8.77044C9.75612 12 10.5551 11.201 10.5551 10.2153V7.24082C10.5551 6.91231 10.2888 6.64592 9.96023 6.64592C9.63167 6.64592 9.36533 6.91231 9.36533 7.24082V10.2153C9.36533 10.5439 9.099 10.8102 8.77044 10.8102H2.22658C1.89803 10.8102 1.63169 10.5439 1.63169 10.2153V3.67145Z"
        fill="#555555"
      />
    </svg>
  );
};

export default EditIcon;
