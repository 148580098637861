import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list";
import { INSERT_TABLE_COMMAND, TableNode } from "@lexical/table";

import CustomTooltip from "components/common/Tooltips/CustomTooltip";
import { Undo } from "components/common/SVG/Undo";
import { Redo } from "components/common/SVG/Redo";
import { Bold } from "components/common/SVG/Bold";
import { Italic } from "components/common/SVG/Italic";
import { List } from "components/common/SVG/List";
import { Table } from "components/common/SVG/Table";
import { Copy } from "components/common/SVG/Copy";

const LowPriority = 1;

const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [showTableErrorTooltip, setShowTableErrorTooltip] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
    }
  }, []);

  const createTable = () => {
    editor.read(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        let node = selection.anchor.getNode();
        while (node) {
          if (node instanceof TableNode) {
            setShowTableErrorTooltip(true);
            setTimeout(() => {
              setShowTableErrorTooltip(false);
            }, 2000);
            return;
          }
          node = node.getParent();
        }
      }

      editor.update(() => {
        editor.dispatchCommand(INSERT_TABLE_COMMAND, {
          columns: "2",
          rows: "2",
          includeHeaders: true,
        });
      });
    });
  };

  function updatePoweredBy(inputString) {
    return inputString.replace(/Powered By/g, "Powered By NEPHROLYTICS");
  }

  const handleCopy = () => {
    const plainText = editor.getEditorState().read(() => {
      return $getRoot().getTextContent();
    });
    navigator.clipboard.writeText(updatePoweredBy(plainText)).then(() => {
      setShowCopyTooltip(true);
      setTimeout(() => {
        setShowCopyTooltip(false);
      }, 2000);
    });
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        // Updation in the toolbar
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, $updateToolbar]);

  return (
    <div className="toolbar" ref={toolbarRef}>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        className="toolbar-item spaced"
        aria-label="Undo">
        <Undo className="format" />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        className="toolbar-item"
        aria-label="Redo">
        <Redo className="format" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
        className={"toolbar-item spaced " + (isBold ? "active" : "")}
        aria-label="Format Bold">
        <Bold className="format" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
        className={"toolbar-item spaced " + (isItalic ? "active" : "")}
        aria-label="Format Italics">
        <Italic className="format" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        }}
        className={"toolbar-item spaced"}
        aria-label="Unordered List">
        <List className="format" />
      </button>
      <CustomTooltip
        tooltipText="Cannot create table inside another table"
        arrow
        placement="top"
        clickToOpen={{
          isOpenOnClickEnabled: true,
          isOpen: showTableErrorTooltip,
        }}>
        <button
          onClick={() => createTable()}
          className="toolbar-item spaced"
          aria-label="Insert Table">
          <Table className="format" />
        </button>
      </CustomTooltip>
      <CustomTooltip
        tooltipText="Copied"
        arrow
        placement="top"
        clickToOpen={{
          isOpenOnClickEnabled: true,
          isOpen: showCopyTooltip,
        }}>
        <button
          onClick={handleCopy}
          className="toolbar-item spaced"
          aria-label="Copy">
          <Copy className="format" />
        </button>
      </CustomTooltip>
    </div>
  );
};

export default ToolbarPlugin;
