import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { IconButton } from "components/common/Button/IconButton";
import Text from "components/common/Typography/Text";
import { EditOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "components/common/FormComponents/Form";
import { Trash } from "components/common/SVG/Trash";
import SelectField from "components/common/FormComponents/SelectField";
import { fetchCpts } from "apiClients/encounters";
import ItemDisplayWrapper from "./ItemDisplayWrapper.js";
import { NoteInput } from "../NoteInput";

const NOT_REVIEWED = "Not Reviewed";

const CPTCodeForm = ({ onSubmit, onCancel, cptCodes, aiGenerated = false }) => {
  const [selectedCPTCode, setSelectedCPTCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = async (value) => {
    if (!value) return;
    setIsLoading(true);
    try {
      const results = await fetchCpts({ query: value });
      const mappedResults = results
        .map((item) => {
          const description = `${item.cpt_code} - ${item.description}`;
          return {
            label: description,
            value: item.cpt_code,
            description: item.description,
          };
        })
        .filter((item) => !cptCodes?.some((d) => d.cpt_code === item.value));
      setOptions(mappedResults);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const showSubmitButton = selectedCPTCode;

  const handleSubmit = () => {
    if (!selectedCPTCode) return;
    onSubmit({
      cpt_code: selectedCPTCode.value,
      description: selectedCPTCode.description,
      status: "Accepted",
      canBeDeleted: !aiGenerated,
      aiGenerated,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ marginTop: "12px", width: "100%" }}>
      <Form>
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
          <Box display="flex" flexDirection="row">
            <Box sx={{ marginRight: "8px" }}>
              <SelectField
                placeholder="Search CPT Code - Or - Keyword"
                onSearch={handleSearch}
                options={options}
                loading={isLoading}
                value={selectedCPTCode}
                onChange={(_, v) => setSelectedCPTCode(v)}
                openOnFocus={false}
                extendedSearchOptions={["description"]}
                onClear={() => setSelectedCPTCode(null)}
                sx={{ width: "450px" }}
              />
            </Box>
            {showSubmitButton && (
              <IconButton
                height="40px"
                onClick={handleSubmit}
                sx={{ minWidth: "40px" }}>
                <CheckIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            )}
            <IconButton
              onClick={onCancel}
              sx={{
                marginLeft: "8px",
                height: "40px",
                width: "40px",
              }}>
              <Trash />
            </IconButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

const CPTCodeDisplay = ({ cptCode, aiGenerated, onDelete, setStatus, sx }) => {
  return (
    <ItemDisplayWrapper
      sx={sx}
      aiGenerated={aiGenerated}
      onDelete={onDelete}
      setStatus={setStatus}
      canBeDeleted={cptCode?.canBeDeleted}
      showEdit={false}
      title={`${cptCode?.cpt_code} - ${cptCode?.description}`}
    />
  );
};

export const CPTCode = ({
  aiGenerated = false,
  setCpts,
  cptCodes,
  onDelete = () => {},
  onAdd,
  onEdit,
  cptCode,
  createNewCPT,
  setShowInput = () => {},
  sx,
}) => {
  const theme = useTheme();
  const [note, setNote] = useState(cptCode?.note || "");
  const [isEditing, setIsEditing] = useState(false);
  const [showNotesInput, setShowNotesInput] = useState(false);

  const handleDelete = () => {
    onDelete("cpt_codes", cptCode);
    if (!cptCode?.aiGenerated) {
      setCpts(cptCodes?.filter((d) => d.cpt_code !== cptCode?.cpt_code));
    }
    setShowInput(false);
  };

  const setStatus = (feedback) => {
    let newStatus = "";
    const currentFeedback = cptCode.status === feedback;
    if (currentFeedback) {
      newStatus = NOT_REVIEWED;
    } else {
      newStatus = feedback;
    }

    const updatedCPTCode = {
      ...cptCode,
      status: newStatus,
      note: "",
    };
    if (feedback === "Flagged") {
      setShowNotesInput(true);
    } else {
      setNote("");
      setShowNotesInput(false);
    }
    setCpts(
      cptCodes.map((d) =>
        d.cpt_code === cptCode.cpt_code ? updatedCPTCode : d,
      ),
    );
    onEdit(updatedCPTCode);
  };

  const handleSubmit = (cptCodeData) => {
    if (isEditing) {
      setCpts(
        cptCodes.map((d) =>
          d.cpt_code === cptCode.cpt_code ? cptCodeData : d,
        ),
      );
    } else {
      setCpts([...(cptCodes || []), cptCodeData]);
    }
    onAdd(false, cptCodeData);
    setIsEditing(false);
    setShowInput(false);
  };

  let optimisticDescription = cptCode?.description;
  const [description, setDescription] = useState("");

  // NOTE -> this is getting called too many times.
  useEffect(() => {
    const fetchDescription = async () => {
      try {
        const results = await fetchCpts({
          query: cptCode.cpt_code,
        });
        const match = results.find(
          (item) => item.cpt_code === cptCode.cpt_code,
        );
        if (match) {
          setDescription(match.description);
        }
      } catch {}
    };
    if (optimisticDescription) return;
    if (!cptCode?.description) {
      fetchDescription();
    }
  }, [cptCode]);

  const showNotes = () => {
    if (showNotesInput) return;
    if (note) {
      return (
        <Box
          sx={{ margin: "8px 0 0 28px" }}
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Text variant="body2" whiteSpace="normal">
            {note}
          </Text>
          <IconButton
            variant="text"
            width="20px"
            height="20px"
            sx={{
              marginLeft: "8px",
              color: theme.palette.common.grey,
            }}
            onClick={() => {
              setShowNotesInput(true);
            }}>
            <EditOutlined
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Box>
      );
    }
  };

  if (createNewCPT || isEditing) {
    return (
      <CPTCodeForm
        onSubmit={handleSubmit}
        onCancel={() => {
          setIsEditing(false);
          setShowInput(false);
        }}
        cptCodes={cptCodes}
        aiGenerated={aiGenerated}
      />
    );
  }
  return (
    <>
      <CPTCodeDisplay
        setStatus={setStatus}
        cptCode={{
          ...cptCode,
          description: optimisticDescription || description,
        }}
        aiGenerated={aiGenerated}
        onDelete={handleDelete}
        sx={sx}
      />
      {showNotes()}
      {showNotesInput && (
        <NoteInput
          showCancelButton={false}
          feedback={note}
          handleFeedbackSubmit={(newNote) => {
            setShowNotesInput(false);
            setNote(newNote);
            const updatedCPTCode = {
              ...cptCode,
              note: newNote,
            };
            setCpts(
              cptCodes.map((d) =>
                d.cpt_code === cptCode.cpt_code ? updatedCPTCode : d,
              ),
            );
            onEdit(updatedCPTCode);
          }}
          handleFeedbackCancel={() => {
            setShowNotesInput(false);
          }}
        />
      )}
    </>
  );
};
