import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getFavorites,
  createFavorite,
  updateFavorite,
  deleteFavorite,
} from "apiClients/favorites.api";

export const FAVORITES_QUERY_KEY = "favorites";

export const useFavorites = (userId) => {
  const queryClient = useQueryClient();

  const favoritesQuery = useQuery({
    queryKey: [FAVORITES_QUERY_KEY, userId],
    queryFn: () => getFavorites(userId),
    enabled: !!userId,
  });

  const createFavoriteMutation = useMutation({
    mutationFn: (favoriteData) => createFavorite(userId, favoriteData),
    onSuccess: () => {
      queryClient.invalidateQueries([FAVORITES_QUERY_KEY, userId]);
    },
  });

  const updateFavoriteMutation = useMutation({
    mutationFn: ({ favoriteId, favoriteData }) =>
      updateFavorite(userId, favoriteId, favoriteData),
    onSuccess: () => {
      queryClient.invalidateQueries([FAVORITES_QUERY_KEY, userId]);
    },
  });

  const deleteFavoriteMutation = useMutation({
    mutationFn: (favoriteId) => deleteFavorite(userId, favoriteId),
    onSuccess: () => {
      queryClient.invalidateQueries([FAVORITES_QUERY_KEY, userId]);
    },
  });

  return {
    favorites: favoritesQuery.data,
    isLoading: favoritesQuery.isLoading,
    isError: favoritesQuery.isError,
    error: favoritesQuery.error,
    createFavorite: createFavoriteMutation.mutate,
    updateFavorite: updateFavoriteMutation.mutate,
    deleteFavorite: deleteFavoriteMutation.mutate,
    isCreating: createFavoriteMutation.isLoading,
    isUpdating: updateFavoriteMutation.isLoading,
    isDeleting: deleteFavoriteMutation.isLoading,
  };
};
