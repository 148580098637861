import { Box, Grid, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import { useSelector } from "react-redux";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";
import { checkNullOrUndefinedString } from "utils/helper";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import moment from "moment";
import { dateFormat } from "utils/date";

const LabResults = ({
  setComponentName,
  sortedFormattedLabs,
  LabObservedDateTime,
}) => {
  const theme = useTheme();
  const { isLabResultsLoading } = useSelector((state) => state.resultsLabs);

  return (
    <Box
      sx={{
        height: "40vh",
        marginTop: "24px",
      }}>
      <Box sx={{ height: "100%" }}>
        <>
          <Box
            data-testid="scd-labs-modal-btn"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "12px",
            }}>
            <SkeletonWrapper
              condition={!isLabResultsLoading}
              id="measured-date-skeleton"
              props={[SkeletonPropsStyleGenerator("20%", "40px")]}
              sx={{ marginLeft: "20px" }}>
              {sortedFormattedLabs?.length > 2 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}>
                  <Text
                    data-testid="scd-lab-results-date-measured-heading"
                    variant="h2"
                    marginLeft="20px">
                    Measured:{" "}
                  </Text>
                  <Text
                    data-testid="scd-lab-results-date-measured-date"
                    variant="h3"
                    marginLeft="8px">
                    {LabObservedDateTime
                      ? moment(LabObservedDateTime).format(dateFormat)
                      : ""}
                  </Text>
                </Box>
              )}
            </SkeletonWrapper>
            <SkeletonWrapper
              condition={!isLabResultsLoading}
              id="measured-date-skeleton"
              props={[SkeletonPropsStyleGenerator("20%", "40px")]}
              sx={{ marginRight: "20px" }}>
              {sortedFormattedLabs?.length > 2 && (
                <Text
                  variant="h2"
                  color={"lightBlue"}
                  sx={{ marginRight: "20px", cursor: "pointer" }}
                  onClick={() => {
                    setComponentName("previousLabs");
                  }}>
                  {" "}
                  Previous Labs{" "}
                </Text>
              )}
            </SkeletonWrapper>
          </Box>
          <SkeletonWrapper
            multipleCount={5}
            sx={{ marginLeft: "20px" }}
            id="lab-res-details-skeleton"
            condition={!isLabResultsLoading}
            props={[SkeletonPropsStyleGenerator("95%", "40px", "150px")]}>
            {sortedFormattedLabs?.length > 2 ? (
              <>
                <Grid
                  container
                  spacing={2} // Add spacing between items
                  sx={{
                    maxHeight: "36vh",
                    overflowY: "auto",
                    padding: "20px", // Add padding around the grid
                  }}>
                  {sortedFormattedLabs?.map(
                    ({ code, val, at_risk, range, documentId }, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={6}
                        xl={6}
                        xxl={4}
                        key={`item-${index}`}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flex: 1,
                          }}>
                          <Text
                            data-testid={`scd-labs-label-${code}`}
                            variant="h3"
                            minWidth="70px"
                            sx={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                              flex: 1,
                              fontWeight: "700",
                            }}>
                            {code}:
                          </Text>
                          {checkNullOrUndefinedString(range) ? (
                            <CustomTooltip
                              tooltipText={
                                <Box sx={{ padding: "5px" }}>
                                  <Box>
                                    <Text variant="h3" sx={{ fontWeight: 500 }}>
                                      <strong>{code} Reference Range</strong>
                                    </Text>
                                  </Box>
                                  <Box sx={{ marginTop: "5px" }}>
                                    <Text variant="formLabel">
                                      {checkNullOrUndefinedString(range)}
                                    </Text>
                                  </Box>
                                </Box>
                              }
                              arrow>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}>
                                {documentId ? (
                                  <a
                                    href={`/api/documents/${documentId}/view-pdf/`}
                                    target="_blank"
                                    style={{
                                      marginRight: "5px",
                                      wordBreak: "break-all",
                                      whiteSpace: "pre-wrap",
                                      textDecoration: "underline",
                                      color: theme.palette.common.purple,
                                    }}
                                    rel="noreferrer">
                                    {checkNullOrUndefinedString(
                                      Array.isArray(val) && val.length > 1
                                        ? val.join("-")
                                        : val,
                                    )}
                                  </a>
                                ) : (
                                  <Text
                                    data-testid={`scd-labs-value-${val}`}
                                    variant="bodyS"
                                    marginLeft="10px"
                                    marginRight="10px"
                                    sx={{
                                      color:
                                        at_risk === "at_risk"
                                          ? theme.palette.error.light
                                          : "inherit",
                                      cursor: "context-menu",
                                      wordBreak: "break-all",
                                      whiteSpace: "pre-wrap",
                                    }}>
                                    {checkNullOrUndefinedString(
                                      Array.isArray(val) && val.length > 1
                                        ? val.join("-")
                                        : val,
                                    )}
                                  </Text>
                                )}
                              </Box>
                            </CustomTooltip>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                              {documentId ? (
                                <a
                                  href={`/api/documents/${documentId}/view-pdf/`}
                                  target="_blank"
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    textDecoration: "underline",
                                    color: theme.palette.common.purple,
                                    wordBreak: "break-all",
                                    whiteSpace: "pre-wrap",
                                  }}
                                  rel="noreferrer">
                                  {checkNullOrUndefinedString(
                                    Array.isArray(val) && val.length > 1
                                      ? val.join("-")
                                      : val,
                                  )}
                                </a>
                              ) : (
                                <Text
                                  data-testid={`scd-labs-value-${val}`}
                                  variant="bodyS"
                                  marginLeft="10px"
                                  marginRight="10px"
                                  sx={{
                                    wordBreak: "break-all",
                                    whiteSpace: "pre-wrap",
                                  }}>
                                  {checkNullOrUndefinedString(
                                    Array.isArray(val) && val.length > 1
                                      ? val.join("-")
                                      : val,
                                  )}
                                </Text>
                              )}
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    ),
                  )}
                </Grid>
              </>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "250px",
                  flex: 1,
                }}>
                No Data Found
              </Box>
            )}
          </SkeletonWrapper>
        </>
      </Box>
    </Box>
  );
};

export default LabResults;
