import { memo } from "react";

export const Trash = memo(({ fill = "2D3748" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none">
      <path
        d="M2.9585 13.375C2.56891 13.375 2.23541 13.2363 1.95798 12.9589C1.68054 12.6814 1.54183 12.3479 1.54183 11.9583V2.75H0.833496V1.33333H4.37516V0.625H8.62516V1.33333H12.1668V2.75H11.4585V11.9583C11.4585 12.3479 11.3198 12.6814 11.0424 12.9589C10.7649 13.2363 10.4314 13.375 10.0418 13.375H2.9585ZM10.0418 2.75H2.9585V11.9583H10.0418V2.75ZM4.37516 10.5417H5.79183V4.16667H4.37516V10.5417ZM7.2085 10.5417H8.62516V4.16667H7.2085V10.5417Z"
        fill={fill}
      />
    </svg>
  );
});
