import { Box, CircularProgress } from "@mui/material";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { memo, useEffect, useState } from "react";
import { flexColumnCenterCenter } from "styles";
import { useParams } from "react-router";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import MedicationDetail from "./MedicationDetail";
import { useQuery } from "@tanstack/react-query";
import { fetchAllMedications } from "apiClients/medications";

const getActiveAndInActiveMedications = (medications) => {
  const active =
    medications?.filter(
      (med) =>
        med?.status === "active" ||
        (med?.status === null &&
          (med?.end_date === null ||
            (med?.end_date === null && med?.start_date === null))),
    ) ?? [];

  const inactive =
    medications?.filter(
      (med) =>
        med?.status === "stopped" ||
        (med?.status === null && med?.end_date !== null),
    ) ?? [];

  const refused = medications?.filter((med) => med?.status === "refused") ?? [];

  return { active, inactive, refused };
};

const Medications = () => {
  let { patientId } = useParams();
  const [activeMedications, setActiveMedications] = useState([]);
  const [inActiveMedications, setInActiveMedications] = useState([]);
  const [refusedMedications, setRefusedMedications] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: ["medications", patientId],
    queryFn: async () => await fetchAllMedications(patientId),
  });

  useEffect(() => {
    const { active, inactive, refused } = getActiveAndInActiveMedications(
      data?.results,
    );
    setActiveMedications(active);
    setInActiveMedications(inactive);
    setRefusedMedications(refused);
  }, [data]);

  return (
    <Box sx={{ padding: "24px", maxHeight: "70vh", overflowY: "auto" }}>
      <Box>
        <Text id="scd-medications-data" variant="h2" fontWeight="600">
          Active
        </Text>
      </Box>
      <Box data-testid={`scd-medication-main-box-id`}>
        <SkeletonWrapper
          id={"skeleton-medication-strip"}
          condition={
            isLoading &&
            ![...(inActiveMedications || []), ...(activeMedications || [])]
              ?.length
              ? false
              : true
          }
          multipleCount={3}
          props={[SkeletonPropsStyleGenerator("100%", "60px", "150px")]}>
          <MedicationDetail
            activeMedications={activeMedications}
            inActiveMedications={inActiveMedications}
            refusedMedications={refusedMedications}
          />
        </SkeletonWrapper>
        {isLoading &&
        [...(inActiveMedications || []), ...(activeMedications || [])].length >
          0 ? (
          <Box sx={{ marginTop: "5px", ...flexColumnCenterCenter }}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default memo(Medications);
