import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SmartSummaryIcon from "assets/svg/light/ai_sparkle.svg";
import FlagOutlined from "@mui/icons-material/FlagOutlined";
import Text from "components/common/Typography/Text";
import ActionItems from "./ActionItems";

const ItemDisplayWrapper = ({
  sx,
  title,
  onEdit,
  children,
  onDelete,
  setStatus,
  aiGenerated,
  canBeDeleted,
  showEdit = true,
  showFavorite,
  status,
  onFavorite,
}) => {
  const theme = useTheme();
  const userCreated = canBeDeleted;
  const [isRefused, setIsRefused] = useState(status === "Refused");
  const [isFlagged, setIsFlagged] = useState(status === "Flagged");
  const [isChecked, setIsChecked] = useState(status === "Accepted");

  const getIcon = () => {
    if (isFlagged) {
      return (
        <FlagOutlined
          sx={{
            color: theme.palette.error.main,
            height: "20px",
            width: "20px",
          }}
        />
      );
    }
    if (isRefused) {
      return (
        <ClearOutlinedIcon
          sx={{
            color: theme.palette.text.secondary,
            height: "20px",
            width: "20px",
          }}
        />
      );
    }
    if (aiGenerated && !isChecked) {
      return (
        <img
          src={SmartSummaryIcon}
          style={{ height: "20px" }}
          alt="Smart Summary Icon"
        />
      );
    }
    return (
      <CheckIcon
        sx={{
          color: theme.palette.success.main,
          height: "20px",
          width: "20px",
        }}
      />
    );
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
    setIsRefused(false);
    setIsFlagged(false);
    setStatus(!isChecked ? "Accepted" : "");
  };

  const handleRefuseClick = () => {
    setIsRefused(!isRefused);
    setIsFlagged(false);
    setIsChecked(false);
    setStatus(!isRefused ? "Refused" : "");
  };

  const handleFlagClick = () => {
    setIsFlagged(!isFlagged);
    setIsRefused(false);
    setIsChecked(false);
    setStatus(!isFlagged ? "Flagged" : "");
  };

  const headerTextColor = () => {
    if (isFlagged) {
      return theme.palette.error.main;
    }
    if (isRefused) {
      return theme.palette.text.secondary;
    }
    if (aiGenerated && !isChecked) {
      return theme.palette.common.purple;
    }
    return theme.palette.success.main;
  };

  return (
    <Box display="flex" flexDirection="column" sx={sx}>
      {/* Header Section */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ WebkitAlignItems: "unset", alignItems: "unset" }}>
          {getIcon()}
          <Box ml={1} display="flex" flexDirection="column">
            <Text
              fontWeight={700}
              variant="body2"
              sx={{ color: headerTextColor() }}
              whiteSpace="normal">
              {title}
            </Text>
            {children}
          </Box>
        </Box>
        <ActionItems
          onCheckmark={handleCheckmarkClick}
          onRefuse={handleRefuseClick}
          onEdit={onEdit}
          onFlag={handleFlagClick}
          onDelete={onDelete}
          showDelete={userCreated}
          showCheckmark={!userCreated}
          showEdit={showEdit}
          showFlag={!userCreated}
          showRefuse={!userCreated}
          isRefused={isRefused}
          isFlagged={isFlagged}
          isChecked={isChecked}
          showFavorite={showFavorite}
          onFavorite={onFavorite}
          theme={theme}
        />
      </Box>
    </Box>
  );
};

export default ItemDisplayWrapper;
