import {
  cardSpaceStyling,
  cardImageBoxStyling,
  cardNameSkeletonStyling,
  cardImageSkeletonStyling,
  cardButtonSkeletonStyling,
  cardDiseaseSkeletonStyling,
  cardSkeletonResponsiveStyling,
  cardVisitReasonSkeletonStyling,
} from "styles/Dashboard/SchedulePatientsStyle";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";
import {
  flexRow,
  justifyContentSpaceBetween,
  flexColumn,
  justifyContentCenter,
} from "styles";

const CardsSkeleton = () => {
  return (
    <Box
      data-testid={"main-card-skeleton-body"}
      sx={cardSkeletonResponsiveStyling}>
      <Box>
        <Box
          sx={{ ...flexRow, ...justifyContentSpaceBetween, padding: "none" }}>
          <Skeleton
            data-testid="date-time-skeleton"
            variant="rectangle"
            width={370}
            height={24}
            sx={{ marginTop: "20px", borderRadius: "10px" }}
          />
        </Box>
        <Box sx={cardImageBoxStyling}>
          <Box style={{ ...flexColumn, ...justifyContentCenter }}>
            <Skeleton
              data-testid="card-name-skeleton"
              variant="rectangle"
              sx={cardNameSkeletonStyling}
            />
            <Skeleton
              data-testid="card-last-visit-skeleton"
              variant="rectangle"
              sx={cardDiseaseSkeletonStyling}
            />
          </Box>
          <Skeleton
            data-testid="card-patient-image-skeleton"
            variant="rectangle"
            sx={cardImageSkeletonStyling}
          />
        </Box>
        <Box>
          <Skeleton
            data-testid="card-disease-skeleton"
            variant="rectangle"
            sx={cardDiseaseSkeletonStyling}
          />
          <Skeleton
            data-testid="card-visit-heading-skeleton"
            variant="rectangle"
            sx={cardSpaceStyling}
          />
          <Skeleton
            data-testid="card-visit-reason-skeleton"
            variant="rectangle"
            sx={cardVisitReasonSkeletonStyling}
          />
        </Box>
      </Box>
      <Box>
        <Box sx={{ ...flexRow }}>
          <Skeleton
            data-testid="card-button-skeleton"
            variant="rectangle"
            sx={cardButtonSkeletonStyling}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CardsSkeleton;
