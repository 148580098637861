import { Box, useTheme } from "@mui/material";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVitalsList } from "redux/features/Vitals/VitalsSlice";
import LabResults from "./LabResults/LabResults";
import LabTrends from "./LabResults/LabTrends";
import VitalTrends from "./Vitals/VitalTrends";
import Vitals from "./Vitals/Vitals";
import { getSortedFormattedLabs } from "utils/labs/utils";
import { RoundExclamatory } from "components/common/SVG/RoundExclamatory";

const VitalLabMedWrapper = ({ setComponentName }) => {
  const theme = useTheme();
  let dispatch = useDispatch();
  let { patientId } = useParams();

  const [outOfRangeLabsCount, setOutOfRangeLabsCount] = useState(0);
  const [isGraphDisplay, setIsGraphDisplay] = useState(1);
  const [activeSubTab, setActiveSubTab] = useState("Latest");
  const [sortedFormattedLabs, setSortedFormattedLabs] = useState([]);
  const [childrenArrayIndex, setChildrenArrayIndex] = useState(0);

  const { labResults } = useSelector((state) => state.resultsLabs);

  const { count, sortedLabsObservations, scannedLabsArePresent } =
    getSortedFormattedLabs(labResults?.[0]);

  const getFormattedLabs = () => {
    // set the count
    setOutOfRangeLabsCount(count);
    setSortedFormattedLabs(sortedLabsObservations);

    return;
  };

  useEffect(() => {
    getFormattedLabs();
  }, [labResults]);

  // to get the Vitals value
  useEffect(() => {
    dispatch(getVitalsList(patientId));
  }, [patientId]);

  const handleChildrenIndex = (index) => {
    setChildrenArrayIndex(index);
    setIsGraphDisplay(1);
  };

  const childrenArray = [
    {
      titleDetails: { title: "Vitals" },
      tab: activeSubTab === "Latest" ? <Vitals /> : <VitalTrends />,
    },
    {
      titleDetails: { title: "Lab Results" },
      tooltip: scannedLabsArePresent
        ? {
            text: (
              <>
                <b>NOTE</b>
                <br />
                Lab data extracted via OCR may be inaccurate. Confirm results
                with the original scanned report.
              </>
            ),
            sx: {
              fontSize: "15px",
              padding: "10px",
              color: theme?.palette?.common?.purple,
            },
          }
        : undefined,
      tab:
        activeSubTab === "Latest" ? (
          <LabResults
            setComponentName={setComponentName}
            sortedFormattedLabs={sortedFormattedLabs}
            LabObservedDateTime={labResults?.[0]?.lab_observed_datetime}
          />
        ) : (
          <LabTrends />
        ),
      titleIcon: scannedLabsArePresent ? <RoundExclamatory /> : undefined,
    },
  ];

  return (
    <Box style={{ position: "relative", height: "100%" }}>
      <Box style={{ height: "100%" }}>
        <Box
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "24px",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}>
          <HorizontalTabs
            isDisplay={isGraphDisplay === 1}
            handleChildrenIndex={handleChildrenIndex}
            childrenArray={childrenArray}
            defaultValue={0}
            borderTab
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "14px",
          right: "20px",
        }}>
        {childrenArrayIndex === 2 ? null : (
          <Box sx={{ lineHeight: "1.1", display: "flex" }}>
            <Box
              sx={{
                marginRight: "20px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "17px",
                borderBottom:
                  activeSubTab === "Latest" ? "2.5px solid #305fff" : "",
              }}
              onClick={() => setActiveSubTab("Latest")}>
              Latest
            </Box>
            <Box
              sx={{
                marginRight: "10px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "17px",
                borderBottom:
                  activeSubTab === "Historical" ? "2.5px solid #305fff" : "",
              }}
              data-testid="historical-tab-shifter-id"
              onClick={() => setActiveSubTab("Historical")}>
              Trends{" "}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VitalLabMedWrapper;
