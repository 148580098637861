import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

const EditorContextPlugin = () => {
  const [editor] = useLexicalComposerContext();

  function updatePoweredBy(inputString) {
    return inputString.replace(/Powered By/g, "Powered By NEPHROLYTICS");
  }

  useEffect(() => {
    function handleSectionsCopy(event) {
      let copyIcon = event.target;
      const mainSection = event.target.closest(".custom-div-node");
      let fullContent = mainSection.innerText;
      let formattedFullContent = fullContent.replace(/(\n\s*){2,}/g, "\n\n");

      navigator.clipboard
        .writeText(updatePoweredBy(formattedFullContent.trim()))
        .then(() => {
          copyIcon.style.opacity = "0.5";
          setTimeout(() => {
            copyIcon.style.opacity = "1";
          }, 500);
        });
    }

    const attachListeners = () => {
      const copyIcons = document.getElementsByClassName("copy-icon-editor");
      Array.from(copyIcons).forEach((icon) => {
        icon.style.cursor = "pointer";

        icon.removeEventListener("click", handleSectionsCopy);
        icon.addEventListener("click", handleSectionsCopy);
      });
    };

    attachListeners();

    const observer = new MutationObserver(attachListeners);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const copyIcons = document.getElementsByClassName("copy-icon-editor");
      Array.from(copyIcons).forEach((icon) => {
        icon.removeEventListener("click", handleSectionsCopy);
      });
      observer.disconnect();
    };
  }, [editor]);

  return null; // This is just a hook, it doesn't render anything
};

export default EditorContextPlugin;
