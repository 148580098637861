import { Box, Skeleton, useTheme } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchPatientDocuments } from "apiClients/documents.api";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { RoundExclamatory } from "components/common/SVG/RoundExclamatory";
import DocumentSummaryModal from "components/common/Modal/DocumentSummaryModal";

export const PatientDocuments = () => {
  const theme = useTheme();
  const { patientId } = useParams();
  const [lastDoc, setLastDoc] = useState(null);
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["patientsDocuments"],
    queryFn: async (data) => fetchPatientDocuments(data, patientId),
    staleTime: 10 * 60 * 1000, // 10 minutes stale time
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Fetch more data when the element is in view
          fetchNextPage();
        }
      },
      { threshold: 0.8 },
    ),
  );

  useEffect(() => {
    const currentObserver = observer.current;
    const currentRef = lastDoc;

    if (currentRef) {
      currentObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        currentObserver.unobserve(currentRef);
      }
    };
  }, [lastDoc]);

  const _visibleData = () => {
    return (
      data?.pages.reduce((acc, curr) => {
        return [...acc, ...curr.results];
      }, []) ?? {}
    );
  };

  const visibleData = _visibleData();

  const [isDocumentSummaryOpen, setIsDocumentSummaryOpen] = useState(false);
  const [documentSummary, setDocumentSummary] = useState("");

  return (
    <Box padding={4} sx={{ maxHeight: "96vh" }}>
      {isFetching && !hasNextPage ? (
        <>
          {Array(15)
            .fill("")
            .map((s, i) => (
              <Skeleton key={i} height={50} />
            ))}
        </>
      ) : (
        <Box>
          <Box>
            <Box sx={{ display: "flex", padding: "8px 0px" }}>
              <Text variant="h3" fontWeight={700} sx={{ flex: 2 }}>
                Filename
              </Text>
              <Text variant="h3" fontWeight={700} sx={{ flex: 1 }}>
                Category
              </Text>
              <Text variant="h3" fontWeight={700} sx={{ flex: 1 }}>
                Date
              </Text>
            </Box>
            <Box
              sx={{ maxHeight: "32vmax", height: "32vmax", overflowY: "auto" }}>
              {visibleData?.length > 0 ? (
                <>
                  {visibleData?.map((doc, index) => {
                    let summary = null;
                    if (doc?.summary_json) {
                      summary = {
                        ...doc.summary_json,
                        title: doc.title,
                      };
                    }
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          padding: "8px 0px",
                          borderTop: `1px solid ${theme.palette.common.lightGrey}`,
                        }}>
                        <Box
                          sx={{
                            flex: 2,
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "start",
                            overflow: "hidden",
                          }}>
                          <Text variant="h3" fontWeight={500}>
                            <a
                              href={`/api/documents/${doc?.id}/view-pdf/`}
                              target="_blank"
                              rel="noreferrer">
                              {doc.title}
                            </a>
                          </Text>
                          {doc.summary_json && (
                            <Box
                              onClick={() => {
                                setDocumentSummary(summary);
                                setIsDocumentSummaryOpen(true);
                              }}
                              style={{
                                marginLeft: "4px",
                                display: "flex",
                                cursor: "pointer",
                              }}>
                              <RoundExclamatory />
                            </Box>
                          )}
                        </Box>
                        <Text variant="h3" fontWeight={500} sx={{ flex: 1 }}>
                          {doc.category ? doc.category : "-"}
                        </Text>
                        <Text variant="h3" fontWeight={500} sx={{ flex: 1 }}>
                          {doc.received_datetime
                            ? moment(doc.received_datetime).format("MM/DD/YYYY")
                            : ""}
                        </Text>
                      </Box>
                    );
                  })}
                  <Box height={2} ref={setLastDoc}>
                    {isFetching ? <Skeleton height={50} /> : ""}
                  </Box>
                </>
              ) : (
                <Text marginY={6} variant="h3" center fontWeight={500}>
                  No documents found
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <DocumentSummaryModal
        documentSummary={documentSummary}
        isOpen={isDocumentSummaryOpen}
        onClose={() => setIsDocumentSummaryOpen(false)}
      />
    </Box>
  );
};
