import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { SubForm } from "../common/SubForm";
import { Required } from "validations/validators";
import { formatErrorsToAlerts } from "../common/utils";
import { Box, FormControl, FormControlLabel, Switch } from "@mui/material";
import {
  fetchLatestSmartNote,
  updateSmartNote,
} from "apiClients/latestSmartNote";
import HelpIcon from "assets/svg/light/helpIcon";
import HelpIconFilled from "assets/svg/colored/helpIconFilled";
import CustomPopover from "../common/popover";
import { alignPatientIntakeHeadingIconStyle } from "styles";
import {
  addPifLab,
  deletePifLab,
  fetchPifLabs,
  updatePifLab,
} from "apiClients/labs.api";

export const LabsReview = memo(({ errors }) => {
  const queryClient = useQueryClient();
  const [mappedResults, setMappedResults] = useState([]);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const labsInputs = [
    {
      label: "Category",
      key: "category",
      type: "select",
      options: [
        { value: "Scanned", label: "Scanned" },
        { value: "Electronic", label: "Electronic" },
      ],
      cols: 3,
      isFieldDisabled: (id) => {
        return id?.startsWith("lab-") ? true : false;
      },
    },
    {
      label: "Lab",
      key: "lab_name",
      cols: 3,
      isFieldDisabled: (id) => {
        return id?.startsWith("lab-") ? true : false;
      },
    },
    {
      label: "Status",
      key: "status",
      type: "select",
      isFieldDisabled: (id) => {
        return id?.startsWith("lab-") ? true : false;
      },
      options: [
        { label: "Ordered", value: "Ordered" },
        { label: "Completed (Partial)", value: "Completed (Partial)" },
        { label: "Completed (Final)", value: "Completed (Final)" },
        { label: "Completed (Doc Avail)", value: "Completed (Doc Avail)" },
        { label: "Completed (No Doc)", value: "Completed (No Doc)" },
        { label: "Canceled", value: "Canceled" },
      ],
      cols: 3,
      validators: [Required],
    },
    {
      label: "Notes",
      key: "note",
      cols: 2,
    },
  ];

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      const updatedPayload = {
        ...dirtyValue,
      };
      if (dirtyValue?.delete) return deletePifLab(patientId, dirtyValue?.id);
      if (updatedPayload?.smart_note_data) {
        delete updatedPayload.id;
        return updateSmartNote(patientId, updatedPayload);
      }
      if (updatedPayload?.id) {
        await updatePifLab(patientId, updatedPayload?.id, {
          ...updatedPayload,
          name: updatedPayload?.lab_name,
        });
        return await updatePifLab(
          patientId,
          mappedResults?.find(({ id }) => updatedPayload?.id == id)
            ?.lab_test_request__id,
          { ...updatedPayload, name: updatedPayload?.lab_name },
          true,
        );
      }
      return addPifLab(patientId, {
        ...updatedPayload,
        name: updatedPayload?.lab_name,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["latestSmartNote", patientId],
      });
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["latestSmartNote", patientId],
    queryFn: () => fetchLatestSmartNote(patientId),
  });

  const { data: externalLabsData = [], isLoading: externalLabLoading } =
    useQuery({
      queryKey: ["externalLabs", patientId],
      queryFn: () => fetchPifLabs(patientId),
    });

  const handleSubmit = async (dirtyValues) => {
    const updatedValues = { ...data };

    // Separate smart-data items from other items
    const smartLabtDataItems = dirtyValues?.filter((dtyVal) =>
      dtyVal?.id?.startsWith("lab-"),
    );
    const otherItems = dirtyValues?.filter(
      (dtyVal) => !dtyVal?.id?.startsWith("lab-"),
    );

    let operations = [];

    if (smartLabtDataItems?.length) {
      operations.push(
        mutation.mutateAsync({
          smart_note_data: {
            ...updatedValues?.smart_note_data,
            labs_to_order: smartLabtDataItems, // Pass all smart-data items together
          },
        }),
      );
    }
    otherItems?.forEach((item) => {
      operations.push(mutation.mutateAsync(item)); // Process each non-smart-data item separately
    });

    return Promise.allSettled(operations);
  };

  useEffect(() => {
    if (isLoading || externalLabLoading) return;
    const updatedData = structuredClone(
      data?.smart_note_data?.labs_to_order || [],
    );
    const mappedData = updatedData?.map((val, index) => {
      // Add unique ID to each entry
      const withId = {
        ...val,
        id: `lab-${index}-${Date.now()}`, // Create unique ID if not exists
      };

      if (
        val?.supporting_document_id &&
        val.category === "Completed (Doc Avail)"
      ) {
        return { ...withId, category: "Scanned", hideDeleteIcon: true };
      }
      return { ...withId, category: "Electronic", hideDeleteIcon: true };
    });
    registerForm("labs", handleSubmit, [...mappedData, ...externalLabsData]);
    setMappedResults([...mappedData, ...externalLabsData]);
  }, [data, externalLabsData, isLoading, externalLabLoading]);

  useEffect(() => {
    return () => {
      queryClient.resetQueries({
        queryKey: ["latestSmartNote", patientId],
      });
      queryClient.resetQueries({
        queryKey: ["externalLabs", patientId],
      });
    };
  }, [queryClient, patientId]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Text variant="h1">Labs</Text>
        <span
          onClick={handleClick}
          aria-describedby={"simple-popover"}
          style={alignPatientIntakeHeadingIconStyle}>
          {anchorEl == null ? <HelpIcon /> : <HelpIconFilled />}
        </span>
      </Box>
      <CustomPopover
        id={open ? "simple-popover" : undefined}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        <Box sx={{ padding: 2, maxWidth: "380px" }}>
          <Box>
            {" "}
            <Text marginY={1} variant="h4" fontWeight={700} color={"grey"}>
              GUIDELINE
            </Text>
          </Box>
          <Box>
            <Text variant="h4" fontWeight={700} sx={{ whiteSpace: "unset" }}>
              Confirm pending & completed labs.
            </Text>
          </Box>
          <ul style={{ paddingLeft: "16px" }}>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Recent labs? Ask if the patient had any blood work or urine tests
              done outside this clinic.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Pending tests from previous visits? Check if any labs ordered at
              the last visit were completed. If not, why?
            </li>
          </ul>
        </Box>
      </CustomPopover>
      <SubForm
        subSections={labsInputs}
        formKey="labs"
        dataLoading={isLoading}
        data={mappedResults}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
