import { ThemeProvider } from "@mui/material";
import ErrorHandler from "components/common/ErrorHandler";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import "styles/index.css";
import { createCustomTheme } from "styles/theming/theme";
import App from "./App";
import { persistor, store } from "./redux/store";
import {
  FeatureFlagsContext,
  fetchFeatureFlagData,
} from "./contexts/FeatureFlagsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createCustomTheme();

// Load the feature flag data from the backend while the browser is loading the
// application. This read-only data will be served by the feature flag context
// provider to any component that needs it.
const ffData = await fetchFeatureFlagData();

root.render(
  <FeatureFlagsContext.Provider value={ffData}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            transition={Slide}
            pauseOnHover
          />
          <ErrorHandler />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </FeatureFlagsContext.Provider>,
);
