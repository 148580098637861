import { apiDelete, apiGet, apiPost, apiPatch } from "./base";

export const fetchLatestSmartNote = async (patientId) => {
  const url = `/api/patients/${patientId}/encounters/latest_smart_note/`;
  const response = await apiGet(url);
  return response.data;
};

export const addSmartNote = async (patientId, data) => {
  const url = `/api/patients/${patientId}/encounters/latest_smart_note/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updateSmartNote = async (patientId, data) => {
  const url = `/api/patients/${patientId}/encounters/latest_smart_note/`;
  const response = await apiPatch(url, data);
  return response.data;
};

export const deleteSmartNote = async (patientId) => {
  const url = `/api/patients/${patientId}/encounters/latest_smart_note/`;
  const response = await apiDelete(url);
  return response.data;
};
