import { Box, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import Editor from "components/Editor/Editor";
import { convertSoapNoteJsontoHtml } from "utils/convertJsonToHtml";
import prettier from "prettier/standalone";
import parserHtml from "prettier/plugins/html";
import { useEffect, useState } from "react";

export const PastVisitTab = ({ data }) => {
  const [formattedHtml, setFormattedHtml] = useState("");

  async function formatHtml(htmlString) {
    try {
      const formattedHtml = await prettier.format(htmlString, {
        parser: "html",
        plugins: [parserHtml],
        htmlWhitespaceSensitivity: "ignore",
      });
      return formattedHtml;
    } catch (error) {
      return htmlString;
    }
  }

  useEffect(() => {
    if (data?.end_notes_html) {
      formatHtml(data.end_notes_html).then(setFormattedHtml);
    } else {
      setFormattedHtml(convertSoapNoteJsontoHtml(data)); // Use fallback
    }
  }, [data]);

  return (
    <>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box>
          <Box>
            <Box>
              <Text variant="propertyLabel">Created by</Text>
            </Box>
            <Text variant="bodyXs">{data?.user_name || "---"}</Text>
          </Box>
        </Box>
      </Box>
      <Box paddingY={1}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Text variant="h2">Soap Notes</Text>
        </Box>
        <AccordionNotes
          marginTop="20px"
          notes={formattedHtml}
          endNotesAvailable={!!data?.end_notes_html}
        />
      </Box>
    </>
  );
};

const AccordionNotes = ({ notes = null, marginTop, endNotesAvailable }) => {
  const theme = useTheme();
  return (
    <Box
      className="cus-textFocus"
      sx={{
        borderRadius: "11px",
        background: theme.palette.common.white,
        marginTop: marginTop,
      }}>
      {notes === "" || notes === null ? (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      ) : (
        <div
          className={`${
            endNotesAvailable ? `end_notes_html` : `soap-notes`
          }-editor`}
          style={{
            fontSize: "14px",
            padding: "5px",
            background: theme.palette.common.white,
            borderRadius: "10px",
            maxHeight: "38vh",
            overflow: "auto",
          }}>
          <Editor
            data={notes}
            editable={false}
            ShouldConvertFromMarkdownString={false}
          />
        </div>
      )}
    </Box>
  );
};
