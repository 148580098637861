import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import { IconButton } from "components/common/Button/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "components/common/FormComponents/Form";
import { Trash } from "components/common/SVG/Trash";
import EditIcon from "@mui/icons-material/EditOutlined";
import SelectField from "components/common/FormComponents/SelectField";
import { fetchImagingCodes } from "apiClients/encounters";
import ItemDisplayWrapper from "./ItemDisplayWrapper.js";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer.js";
import InputField from "components/common/FormComponents/InputField.js";
import { useFavorites } from "hooks/useFavorites";
import { useSelector } from "react-redux";
import { IMAGING } from "constants/favorites";
import { isDev } from "utils/isDev";

const ImagingDisplay = ({
  imaging,
  aiGenerated,
  onEdit,
  onDelete,
  setStatus,
  showStatus = false,
  note,
  sx,
  setShowNotesInput,
  onFavorite,
}) => {
  return (
    <ItemDisplayWrapper
      sx={sx}
      aiGenerated={aiGenerated}
      onEdit={onEdit}
      showEdit={false}
      showFavorite={isDev()}
      onDelete={onDelete}
      setStatus={setStatus}
      canBeDeleted={imaging?.canBeDeleted}
      title={`${imaging?.description ? imaging?.description : imaging?.action}`}
      onFavorite={onFavorite}>
      {note && showStatus && (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Text
            fontWeight={500}
            variant="caption"
            color="textSecondary"
            sx={{ marginTop: "4px" }}
            whiteSpace="normal">
            {note}
          </Text>
          <Box
            onClick={() => setShowNotesInput(true)}
            sx={{
              marginLeft: "8px",
              height: "10px",
              width: "10px",
            }}>
            <EditIcon sx={{ height: "20px", width: "20px" }} />
          </Box>
        </Box>
      )}
    </ItemDisplayWrapper>
  );
};
const ImagingForm = ({
  onSubmit,
  onCancel,
  imagings,
  aiGenerated = false,
  initialImaging = null,
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImaging, setSelectedImaging] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async (value) => {
    if (!value) return;
    // Store the query for future "load more" calls
    setSearchQuery(value);
    setIsLoading(true);
    try {
      const data = await fetchImagingCodes({ query: value, page: 1 });
      const mappedResults = data?.results
        .map((item) => ({
          label: item.loinc_code,
          value: item.description,
          description: item.description,
        }))
        .filter((item) => !imagings?.some((d) => d.loinc_code === item.value));
      setOptions(mappedResults);
      setPage(1);
      // Assume if results are less than the page size (e.g., 20) then no more pages
      setHasMore(data?.results?.length === 20);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMore = async () => {
    if (!searchQuery || !hasMore) return;
    setIsLoading(true);
    try {
      const nextPage = page + 1;
      const data = await fetchImagingCodes({
        query: searchQuery,
        page: nextPage,
      });
      const mappedResults = data?.results
        .map((item) => ({
          label: item.loinc_code,
          value: item.description,
          description: item.description,
        }))
        .filter((item) => !imagings?.some((d) => d.loinc_code === item.value));
      // Append new options to the existing options
      setOptions((prevOptions) => [...prevOptions, ...mappedResults]);
      setPage(nextPage);
      // Update hasMore based on if we received a full page of data
      setHasMore(data?.results?.length === 20);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const infiniteScrollDetails = {
    hasMoreToFetch: hasMore,
    fetchMore,
  };

  const showSubmitButton = selectedImaging;

  const handleSubmit = () => {
    if (!selectedImaging) return;
    onSubmit({
      description: selectedImaging.value,
      loinc_code: selectedImaging.label,
      canBeDeleted: !aiGenerated,
      status: "Accepted",
      action: selectedImaging.value,
      aiGenerated,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ marginTop: "12px", width: "100%" }}>
      <EditIcon
        sx={{ color: theme.palette.common.purple, margin: "4px 4px 0 0" }}
      />
      <Form>
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
          <Box display="flex" flexDirection="row">
            <Box sx={{ marginRight: "8px" }}>
              <SelectField
                options={options}
                loading={isLoading}
                openOnFocus={false}
                disabled={aiGenerated}
                // Use debounced onSearch to reduce API calls
                onSearch={debounce((value) => {
                  handleSearch(value);
                }, DEBOUNCE_DELAY)}
                value={selectedImaging}
                onClear={() => setSelectedImaging(null)}
                onChange={(_, v) => setSelectedImaging(v)}
                placeholder="Search Imaging - Or - LIONIC Code"
                showMoreInLabel={{ show: true, value: "value" }}
                extendedSearchOptions={["value"]}
                // Pass infiniteScrollDetails to support scrolling
                infiniteScrollDetails={infiniteScrollDetails}
                sx={{ width: initialImaging ? "322px" : "450px" }}
              />
            </Box>
            {showSubmitButton && (
              <IconButton
                height="40px"
                onClick={handleSubmit}
                sx={{ minWidth: "40px" }}>
                <CheckIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            )}
            <IconButton
              onClick={onCancel}
              sx={{ marginLeft: "8px", height: "40px", width: "40px" }}>
              <Trash />
            </IconButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

export const Imaging = ({
  aiGenerated = false,
  setImagings,
  imagings,
  onDelete = () => {},
  onAdd,
  imaging,
  createNewImaging,
  setShowInput = () => {},
  sx,
  editNote,
}) => {
  const { userId } = useSelector((state) => state.auth);
  const { createFavorite } = useFavorites(userId);
  const [note, setNote] = useState(imaging?.note || "");
  const [imagingStatus, setImagingStatus] = useState("");
  const [showNotesInput, setShowNotesInput] = useState(false);

  const handleFavorite = async () => {
    await createFavorite({
      name: imaging.action,
      type: IMAGING,
      data: {
        loinc_code: imaging.loinc_code || "",
        action: imaging.action,
        description: imaging.description || "",
      },
    });
  };

  const handleDelete = () => {
    onDelete(imaging.loinc_code);
    if (!imaging?.aiGenerated) {
      setImagings(
        imagings?.filter((d) => d.loinc_code !== imaging?.loinc_code),
      );
    }
    setShowInput(false);
  };

  const setStatus = (feedback) => {
    if (feedback === "Flagged") setShowNotesInput(true);
    else {
      setShowNotesInput(false);
    }
    setImagingStatus(feedback === "Flagged" ? "Flagged" : "");

    const updatedImaging = {
      ...imaging,
      status: feedback,
    };
    setImagings(
      imagings.map((d) =>
        d.loinc_code === imaging.loinc_code ? updatedImaging : d,
      ),
    );
    if (aiGenerated) editNote(imaging?.action, null, feedback);
  };

  const handleSubmit = (imagingData) => {
    imagingData.note = note;
    setImagings([...(imagings || []), imagingData]);
    onAdd(imagingData);
    setShowInput(false);
  };

  if (createNewImaging) {
    return (
      <ImagingForm
        onCancel={() => {
          setShowInput(false);
        }}
        note={note}
        setNote={setNote}
        imagings={imagings}
        onSubmit={handleSubmit}
        aiGenerated={aiGenerated}
        showNotesInput={showNotesInput}
        setShowNotesInput={setShowNotesInput}
      />
    );
  }

  return (
    <>
      <ImagingDisplay
        sx={sx}
        setStatus={setStatus}
        imaging={imaging}
        aiGenerated={aiGenerated}
        onDelete={handleDelete}
        note={note}
        showStatus={!showNotesInput && imagingStatus === "Flagged"}
        setShowNotesInput={setShowNotesInput}
        onFavorite={handleFavorite}
      />

      {showNotesInput && imagingStatus === "Flagged" && (
        <Box sx={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
          <InputField
            defaultValue={note}
            sx={{ width: "100%" }}
            inputLabel={"Enter note"}
            onChange={(e) => setNote(e.target.value)}
          />
          <IconButton
            height="40px"
            onClick={() => {
              setShowNotesInput(false);
              editNote(imaging?.action, note, null);
            }}
            sx={{ minWidth: "40px", marginLeft: "8px" }}>
            <CheckIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </Box>
      )}
    </>
  );
};
