import moment from "moment";
import nephroLogo from "../assets/svg/light/nephrolyticsloginlogo.svg";
import copyIcon from "../assets/svg/light/copyIcon.svg";

const dateFormat = "MM-DD-YYYY";

export function removeSnakeCasingAndCapitalize(str) {
  return str.replace(/_/g, " ").toUpperCase();
}
function verifyDate(date) {
  const iso8601Regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|\s*Z)$/;
  return iso8601Regex.test(date);
}

function formatDate(val) {
  const parsedDate = Date.parse(val);
  if (!isNaN(parsedDate)) {
    return moment.utc(`${val}`).format(dateFormat);
  }
  return val;
}

function verifyAndConvertDate(val) {
  if (verifyDate(val)) {
    return formatDate(val);
  }
  return val;
}

function convertKeyAndValue(key, value) {
  if (verifyDate(value)) {
    if (key.toLowerCase() === "datetime") {
      return ["Date", formatDate(value)];
    } else {
      return [key, formatDate(value)];
    }
  } else if (key.toLowerCase() === "height") {
    let convertedValue = value?.replace(/\[lb_av\]/g, "lbs");
    return [key, convertedValue];
  } else if (key.toLowerCase() === "weight") {
    let convertedValue = value?.replace(/\[in_us\]/g, "inch");
    return [key, convertedValue];
  } else {
    return [key, value];
  }
}

let lastVitalsRequiredOrder = [
  "datetime",
  "temperature",
  "blood_pressure",
  "heart_rate",
  "respiration_rate",
  "weight",
  "spo2",
];

function reArrangeLastVitals(obj) {
  const sortedVitalsKeys = [
    ...lastVitalsRequiredOrder,
    ...Object.keys(obj).filter((key) => !lastVitalsRequiredOrder.includes(key)),
  ];
  let sortedVitalsObj = sortedVitalsKeys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
  return sortedVitalsObj;
}
const addH2 = (
  key,
  str = "",
  withCopy = true,
  copyClass = "h2-section",
  lowerCase = false,
) => {
  return (
    str +
    `<h2>${lowerCase ? key : removeSnakeCasingAndCapitalize(key)} ${withCopy ? `<img class="copy-icon-editor ${copyClass}" src="${copyIcon}" alt="copy-icon" height="40px" width="31px" style="margin:-13px 3px; cursor: pointer;" />` : ""} </h2> \n`
  );
};

const addH3 = (key, str = "", withCopy = false, copyClass = "") => {
  return (
    str +
    `<div style="display: flex; align-items: center; justify-content: space-between;">
    <h3>${removeSnakeCasingAndCapitalize(key)}  ${withCopy ? `<img class="copy-icon-editor ${copyClass}" src="${copyIcon}" alt="copy-icon" height="40px" width="31px" style="margin:-13px 3px; cursor: pointer;" />` : ""}</h3>
   
  </div>\n`
  );
};

const addListOfStrings = (arr, str = "") => {
  if (arr?.length == 0) return str;
  let result = str + `<ul>\n`;
  arr?.forEach((val) => {
    result += `  <li>${val}</li>\n`;
  });
  result += `</ul>\n`;
  return result;
};

const addKeyValueString = (key, value, str = "") => {
  return (
    str +
    `<p><strong>${removeSnakeCasingAndCapitalize(key)}:</strong> ${value}</p>\n`
  );
};

const addKeyValueList = (obj = {}, str = "") => {
  if (!Object.keys(obj).length) return str;
  let result = str + `<ul>\n`;
  Object.entries(obj).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      result += `  <li><strong>${k}:</strong>\n`;
      const arrayList = addListOfStrings(v)
        .split("\n")
        .map((line) => `    ${line}`)
        .join("\n");
      result += arrayList;
      result += `  </li>\n`;
    } else if (typeof v === "object" && v !== null) {
      result += `  <li><strong>${k}:</strong>\n`;
      result += addKeyValueList(v);
      result += `  </li>\n`;
    } else {
      result += `  <li><strong>${k}:</strong> ${v}</li>\n`;
    }
  });
  result += `</ul>\n`;
  return result;
};

const addTable = (data, str) => {
  if (!Array.isArray(data) || data.length === 0) return str;

  str = "<table>\n";

  // Create table header using the first row of the array.
  str += "  <thead>\n    <tr>\n";
  data[0].forEach((header) => {
    str += `      <th>${header}</th>\n`;
  });
  str += "    </tr>\n  </thead>\n";

  // Create the table body using the rest of the rows.
  str += "  <tbody>\n";
  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    str += "    <tr>\n";
    row.forEach((cell) => {
      str += `      <td>${cell}</td>\n`;
    });
    str += "    </tr>\n";
  }
  str += "  </tbody>\n";
  str += "</table>\n";
  return str;
};
const lastVitalsReversed = {
  temperature: "temp",
  blood_pressure: "bp",
  heart_rate: "hr",
  respiration_rate: "rr",
  weight: "wt",
  spo2: "spo2",
  height: "height",
  bmi: "bmi",
  datetime: "datetime",
};

const diagnosisCategories = [
  "kidney",
  "htn",
  "electrolyte",
  "met_acid",
  "anemia",
  "proteinuria",
  "bmm",
  "iron",
  "phosphorus",
  "dm",
  "malnutrition",
  "kidney_transplant",
  "hyperuricemia",
  "fluid_overload",
  "vitamin_d",
  "calcium",
  "obesity",
  "other",
];
export function convertEndNoteJsonToHtml(
  patientData,
  data,
  labsData,
  prescriptions,
) {
  let mainNoteString = ``;

  function assessmentAndActionPlan(data, labsData) {
    let section = "";
    let subSection = "";
    let hasData = false;

    // Medications
    const hasMedChanges =
      data?.medication_changes?.length > 0 &&
      !data?.medication_changes?.every(
        (med) => med.status === "Refused" || med.status === "Flagged",
      );
    if (hasMedChanges) {
      let medSection = "";
      const medsMapping = {};
      data.medication_changes.forEach((med) => {
        // Use medication or drug_name as key
        const key = med.medication || med.drug_name;
        if (key && med.status !== "Refused" && med.status !== "Flagged") {
          medsMapping[key] = med.instructions ?? "";
        }
      });
      if (Object.keys(medsMapping).length > 0) {
        medSection += `<code>${addH2("Medications", "")}`;
        medSection += addKeyValueList(medsMapping, "");
        medSection += `</code>`;
        subSection += medSection;
        hasData = true;
      }
    }

    // Labs ordered
    const hasLabsToOrder = data?.labs_to_order?.length > 0;
    const hasLabsData = labsData?.length > 0;
    const hasImaginingData = data?.imaging?.length > 0;
    if (hasLabsToOrder || hasLabsData || hasImaginingData) {
      let labsSection = "";
      const labMapping = [];
      if (hasLabsToOrder) {
        data.labs_to_order.forEach((lab) => {
          if (
            lab?.lab_name &&
            lab.status !== "Flagged" &&
            lab.status !== "Refused"
          ) {
            labMapping.push(lab.lab_name);
          }
        });
      }
      if (hasLabsData) {
        labsData.forEach((lab) => {
          if (lab?.name) {
            labMapping.push(lab.name);
          }
        });
      }
      if (hasImaginingData) {
        data?.imaging.forEach((imaging) => {
          if (
            imaging?.action &&
            imaging.status !== "Flagged" &&
            imaging.status !== "Refused"
          ) {
            labMapping.push(imaging?.action);
          }
        });
      }
      if (labMapping.length > 0) {
        labsSection += `<code>${addH2("Labs ordered", "")}`;
        labsSection += addListOfStrings(labMapping, "");
        labsSection += `</code>`;
        subSection += labsSection;
        hasData = true;
      }
    }

    // Referrals
    const referralActions =
      data?.referrals
        ?.filter(
          (item) => item.status !== "Refused" && item.status !== "Flagged",
        )
        .map((item) => item.action)
        .filter(Boolean) ?? [];

    const procedureActions =
      data?.procedures
        ?.filter(
          (item) => item.status !== "Refused" && item.status !== "Flagged",
        )
        .map((item) => item.action)
        .filter(Boolean) ?? [];

    const allActions = [...referralActions, ...procedureActions];
    if (allActions.length > 0) {
      let referralsSection = "";
      referralsSection += `<code>${addH2("Referrals", "")}`;
      referralsSection += addListOfStrings(allActions, "");
      referralsSection += `</code>`;
      subSection += referralsSection;
      hasData = true;
    }

    // Follow-up plan
    const havePlanSection = data?.plan?.length > 0;
    const havePlanIns = data?.follow_up_instructions;
    if (havePlanSection || havePlanIns) {
      let followUpSection = "";
      followUpSection += `<code>${addH2("Follow-up plan", "")}`;
      if (havePlanSection) {
        let updatedPlan = [...data.plan];
        if (havePlanIns) {
          updatedPlan.push(data.follow_up_instructions);
        }
        followUpSection += addListOfStrings(updatedPlan, "");
        hasData = true;
      } else if (havePlanIns) {
        followUpSection += addListOfStrings([data.follow_up_instructions], "");
        hasData = true;
      }
      followUpSection += `</code>`;
      subSection += followUpSection;
    }

    // Billing (Cpt Codes)
    let cptCodesFieldType;
    let haveCptCodes;
    if (Array.isArray(data?.cpt_codes)) {
      haveCptCodes =
        data?.cpt_codes?.length > 0 &&
        !data?.cpt_codes?.every(
          (code) => code.status === "Refused" || code.status === "Flagged",
        );
      cptCodesFieldType = "arr";
    } else {
      if (data?.cpt_codes) {
        haveCptCodes = true;
        cptCodesFieldType = "str";
      }
    }
    if (haveCptCodes) {
      let billingSection = "";
      billingSection += `<code>${addH2("Billing", "")}`;
      let cptCodes;
      if (cptCodesFieldType === "arr") {
        cptCodes = data.cpt_codes
          .filter(
            (code) => code.status !== "Refused" && code.status !== "Flagged",
          )
          .map((code) => code.cpt_code);
      } else {
        cptCodes = [data.cpt_codes];
      }
      billingSection += addListOfStrings(cptCodes, "");
      billingSection += `</code>`;
      subSection += billingSection;
      hasData = true;
    }

    // Assessment & Plan section
    if (
      data?.diagnosis_data &&
      !Object.values(data.diagnosis_data)
        .flat()
        .every(
          (diagnosis) =>
            diagnosis.status &&
            (diagnosis.status.includes("Refused") ||
              diagnosis.status.includes("Flagged")),
        )
    ) {
      let assessmentSection = "";
      assessmentSection += `<code>${addH2("Assessment & Plan", "")}`;
      diagnosisCategories.forEach((category) => {
        if (data.diagnosis_data[category]?.length > 0) {
          data.diagnosis_data[category].forEach((diagnosis) => {
            if (
              diagnosis?.condition &&
              !diagnosis.localStatus?.includes("Refused") &&
              !diagnosis.localStatus?.includes("Flagged")
            ) {
              assessmentSection += addH2(
                `${diagnosis.condition} (ICD-10: ${diagnosis.icd_10_code || ""})`,
                "",
                false,
              );
              assessmentSection += addKeyValueList(
                {
                  Status: diagnosis.status,
                  Plan: diagnosis.plan,
                  ...(diagnosis.education
                    ? { education: diagnosis.education }
                    : {}),
                },
                "",
              );
              hasData = true;
            }
          });
        }
      });
      assessmentSection += `</code>`;
      subSection += assessmentSection;
    }

    // Build the header block with the upper 4 headings
    let headerBlock = `<p>Powered By <img class="logo-img" src="${nephroLogo}" alt=" (Nephrolytics Logo) " style="margin:-4px 3px;" /><br/><br/></p>`;
    if (patientData?.name) {
      headerBlock += `<p><strong>Name</strong>: ${patientData.name}<br/></p>`;
    }
    if (patientData?.age_gender) {
      headerBlock += `<p><strong>Age / Gender</strong>: ${patientData.age_gender}</p>`;
    }
    if (data?.encounter_date) {
      headerBlock += `<p><strong>Visit Date</strong>: ${moment(data.encounter_date).format(dateFormat)}<br/><br/></p>`;
    }

    // Add the Action Item Summary heading and the rest of the subSections
    section = addH3("Action item summary", "", true, "copy-section-1");
    section += subSection;

    if (hasData) {
      section = addH3("Action item summary", "", true, "copy-section-1");
      section += subSection;
      return `<code>${headerBlock} ${section}</code>`;
    }
    return `${headerBlock}`;
  }

  function reasonForVisit(data) {
    let section = "";
    let subSection = "";
    let hasData = false;

    // Chief Complaint
    if (data?.chief_complaint) {
      let chiefSection = "";
      chiefSection += `<code><p>${data.chief_complaint}</p>\n\n<p> </p></code>`;
      subSection += chiefSection;
      hasData = true;
    }

    // History of Present Illness
    if (data?.summary_paragraph) {
      let hpiSection = "";
      hpiSection += `<code>${addH2("History of Present Illness (HPI)", "")}`;
      hpiSection += `<p>${data.summary_paragraph}</p>\n\n<p> </p></code>`;
      subSection += hpiSection;
      hasData = true;
    }

    // Physical Exam
    const hasPhysicalExams =
      data?.physical_exam && Object.keys(data.physical_exam).length > 0;
    const hasLastVitals =
      data?.last_vitals && Object.keys(data.last_vitals).length > 0;
    if (hasPhysicalExams || hasLastVitals) {
      let peSection = "";
      peSection += `<code>${addH2("Physical Exam (PE)", "")}`;
      let examData = {};
      if (hasPhysicalExams) {
        if (hasLastVitals) {
          const { datetime, ...restLastVitals } = data.last_vitals;
          const formattedLastVitals = {
            ...restLastVitals,
            datetime: moment(datetime).format(dateFormat),
          };
          let vitalsStr = "";
          Object.entries(formattedLastVitals).forEach(([key, value], index) => {
            if (value != 0.0) {
              if (key === "datetime") {
                vitalsStr += `Date: ${value}`;
              } else {
                vitalsStr += `${lastVitalsReversed[key]}: ${value}`;
              }
              if (index < Object.entries(formattedLastVitals).length - 1) {
                vitalsStr += ", ";
              }
            }
          });
          examData = {
            ...data.physical_exam,
            Vitals: vitalsStr,
          };
        } else {
          examData = { ...data.physical_exam };
        }
        peSection += addKeyValueList({ ...examData }, "");
      } else if (hasLastVitals) {
        const { datetime, ...restLastVitals } = data.last_vitals;
        examData = {
          ...restLastVitals,
          datetime: moment(datetime).format(dateFormat),
        };
        let vitalsStr = "";
        Object.entries(examData).forEach(([key, value], index) => {
          if (value != 0.0) {
            if (key === "datetime") {
              vitalsStr += `Date: ${value}`;
            } else {
              vitalsStr += `${lastVitalsReversed[key]}: ${value}`;
            }
            if (index < Object.entries(examData).length - 1) {
              vitalsStr += ", ";
            }
          }
        });
        peSection += addKeyValueList({ Vitals: vitalsStr }, "");
      }
      peSection += `</code>`;
      subSection += peSection;
      hasData = true;
    }

    if (hasData) {
      section = addH3("Reason for Visit", "", true, "copy-section-3");
      section += subSection;
      return `<code>${section}</code>`;
    }
    return "";
  }

  function medicalHistory(data) {
    let section = "";
    let subSection = "";
    let hasData = false;

    // Past Medical History
    if (data?.past_medical_history?.length > 0) {
      let pmhSection = "";
      pmhSection += `<code> ${addListOfStrings(data.past_medical_history, "")}`;
      pmhSection += `</code>`;
      subSection += pmhSection;
      hasData = true;
    }

    // Past Surgical History
    if (data?.past_surgical_history?.length > 0) {
      let pshSection = "";
      pshSection += `<code>${addH2("Past Surgical History (PSH)", "")}`;
      pshSection += addListOfStrings(data.past_surgical_history, "");
      pshSection += `</code>`;
      subSection += pshSection;
      hasData = true;
    }

    // Social History
    if (data?.social_history?.length > 0) {
      let socialSection = "";
      socialSection += `<code>${addH2("Social History", "")}`;
      socialSection += addListOfStrings(data.social_history, "");
      socialSection += `</code>`;
      subSection += socialSection;
      hasData = true;
    }

    // Allergies
    if (data?.allergies?.length > 0) {
      let allergiesSection = "";
      allergiesSection += `<code>${addH2("Allergies", "")}`;
      allergiesSection += addListOfStrings(data.allergies, "");
      allergiesSection += `</code>`;
      subSection += allergiesSection;
      hasData = true;
    }

    // Medications
    if (data?.medications?.length > 0) {
      let medicationsSection = "";
      medicationsSection += `<code>${addH2("Medications", "")}`;
      medicationsSection += addListOfStrings(data.medications, "");
      medicationsSection += `</code>`;
      subSection += medicationsSection;
      hasData = true;
    }

    // Labs Summary
    if (data?.important_labs_v2?.length > 0) {
      let labsSummarySection = "";
      labsSummarySection += `<code>${addH2("Labs Summary", "")}`;
      const tableData = data.important_labs_v2.map((row) =>
        row.map((cell) => {
          if (cell.scanned_document_id) {
            return `<a href="/api/documents/${cell.scanned_document_id}/view-pdf/" target="_blank" rel="noreferrer" style="cursor: pointer;">${cell.value || ""}</a>`;
          }
          return cell.value || "";
        }),
      );
      labsSummarySection += addTable(tableData);
      labsSummarySection += `</code>`;
      subSection += labsSummarySection;
      hasData = true;
    }

    if (hasData) {
      section = addH3("Past Medical History", "", true, "copy-section-2");
      section += subSection;
      return `<code>${section}</code>`;
    }
    return "";
  }

  // Concatenate sections only if they have content
  mainNoteString += assessmentAndActionPlan(data, labsData);
  mainNoteString += reasonForVisit(data);
  mainNoteString += medicalHistory(data);

  return mainNoteString;
}

export function convertSoapNoteJsontoHtml(soapNotesData) {
  let mainNoteString = ``;

  function addSoapNotes(data) {
    if (!data?.soap_notes) return "";
    let section = `<p>${data.soap_notes}</p>\n\n`;
    section += `<p> </p>`;
    return section;
  }

  function addConditions(data) {
    let section = "";
    if (data?.related_conditions && data.related_conditions.length > 0) {
      section += addH3("CONDITIONS", "");
      data.related_conditions.forEach((condition) => {
        section += addH2(`${condition?.icd_10_code} - ${condition?.text}`);
        if (condition?.evidence) {
          section += addListOfStrings(
            typeof condition.evidence === "string"
              ? [condition.evidence]
              : condition.evidence,
          );
        }
      });
    }
    return section;
  }

  // Add SOAP notes and conditions to the main string
  mainNoteString += addSoapNotes(soapNotesData);
  mainNoteString += addConditions(soapNotesData);

  return mainNoteString;
}
