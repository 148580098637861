import { Box, useTheme } from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import { dateFormat } from "utils/date";
import { LogoIcon } from "assets/svg/Logo";
import Circle from "@mui/icons-material/Circle";
import { RoundExclamatory } from "components/common/SVG/RoundExclamatory";
import DocumentSummaryModal from "components/common/Modal/DocumentSummaryModal";

export const SmartSummary = ({
  patientData,
  documentsSinceLastEncounter,
  onTriggerRegeneration,
}) => {
  const theme = useTheme();
  const smartSummaryData = patientData?.smart_summary_data;
  const patientOverviewSummary = smartSummaryData?.pt_summary;
  const previousVisit = smartSummaryData?.previous_visit;
  const reasonForVisit = smartSummaryData?.reason_for_visit || "Follow up care";
  const lastVisitSummary = previousVisit?.summary;
  const medications = previousVisit?.med_mods;
  const lastVisitLabs = previousVisit?.labs;
  const proceduresOrdered = previousVisit?.procedures_ordered;
  const imagesOrdered = previousVisit?.images_ordered;
  const referrals = previousVisit?.referrals;
  const plan = previousVisit?.plan;
  const outsideEncountersSinceLastEncounter =
    smartSummaryData?.outside_encounters_since_last_encounter;
  const importantLabs = smartSummaryData?.important_labs_v2;

  useEffect(() => {
    if (patientData && !smartSummaryData && onTriggerRegeneration) {
      onTriggerRegeneration();
    }
  }, [patientData, smartSummaryData, onTriggerRegeneration]);

  // Get number of dates we have values for (excluding first column and reference range)
  const numDates = importantLabs?.[0]
    ?.slice(1, -1) // Exclude first column and reference range
    .filter(
      (col) =>
        importantLabs
          .slice(1)
          .some((row) => row[importantLabs[0].indexOf(col)]?.value), // Check if the column has any values
    ).length;

  // Only take as many header columns as we have dates, plus the first column and reference range
  const headers = importantLabs?.[0]
    ?.slice(0, numDates + 1)
    .concat(importantLabs?.[0]?.slice(-1)) // Add the last column (reference range)
    .map((header) => {
      return header?.value?.replace(/\//g, "-");
    });

  const labRows = importantLabs?.slice(1).map((lab) => {
    // Take columns for dates plus first column and reference range
    return lab
      ?.slice(0, numDates + 1)
      .concat(lab?.slice(-1)) // Add the last column (reference range)
      .map((labValues) => {
        if (labValues.scanned_document_id) {
          return {
            value: labValues.value,
            scanned_document_id: labValues.scanned_document_id,
          };
        }
        return labValues.value;
      });
  });

  const hasLabValues = importantLabs?.slice(1).some(
    (row) => row.slice(1, -1).some((cell) => cell.value), // Check if any lab has values (excluding first column and reference range)
  );

  const getStatusClass = (status) => {
    switch (status) {
      case "Ordered":
      case "CHANGE":
        return "action-item-status-ordered";
      case "Scheduled":
        return "action-item-status-scheduled";
      case "Completed No Docs":
      case "Completed With Docs":
      case "ADD":
        return "action-item-status-completed";
      case "Refused":
      case "STOP":
        return "action-item-status-refused";
      default:
        return "";
    }
  };

  const hasActionItems =
    medications?.length > 0 ||
    lastVisitLabs?.length > 0 ||
    proceduresOrdered?.length > 0 ||
    referrals?.length > 0 ||
    imagesOrdered?.length > 0;

  const hasOrders =
    lastVisitLabs?.length > 0 ||
    proceduresOrdered?.length > 0 ||
    referrals?.length > 0 ||
    imagesOrdered?.length > 0 ||
    plan?.length > 0;

  const hasActionItemsOrSummary = hasActionItems || lastVisitSummary;

  const medicationAction = (action) => {
    if (action === "ADD") {
      return "Started";
    }
    if (action === "STOP") {
      return "Discontinued";
    }
    if (action === "CHANGE") {
      return "Changed";
    }
    return action;
  };

  const [isDocumentSummaryOpen, setIsDocumentSummaryOpen] = useState(false);
  const [documentSummary, setDocumentSummary] = useState("");

  // Collect all document IDs that are already displayed
  const existingDocumentIds = [
    ...(lastVisitLabs?.map((lab) => lab.supporting_document_id) || []),
    ...(proceduresOrdered?.map((proc) => proc.supporting_document_id) || []),
    ...(referrals?.map((ref) => ref.supporting_document_id) || []),
  ].filter(Boolean); // Remove null/undefined values

  // Filter out documents that are already shown
  const uniqueDocuments = documentsSinceLastEncounter?.filter(
    (doc) => !existingDocumentIds.includes(doc.id),
  );

  const linkText = (item) => {
    if (item.action) {
      return item.action;
    }
    if (item.supporting_doc_summary) {
      try {
        const parsed = JSON.parse(item.supporting_doc_summary);
        return parsed.type;
      } catch {
        return "Document";
      }
    }
  };

  return (
    <Box
      sx={{
        padding: "24px",
        maxHeight: "58vh",
        overflowY: "auto",
      }}>
      <h4>Patient Overview</h4>
      {patientOverviewSummary && (
        <p className="ai-generated-content">{patientOverviewSummary}</p>
      )}

      <h4 className="section-heading">Reason for Visit</h4>
      <p>{reasonForVisit}</p>

      {hasActionItemsOrSummary && (
        <>
          <h4 className="section-heading">Last Visit Summary</h4>
          {lastVisitSummary && (
            <p className="ai-generated-content">{lastVisitSummary}</p>
          )}
        </>
      )}
      {hasActionItems && <h4 className="section-heading">Action Plan</h4>}
      {medications && medications?.length > 0 && (
        <>
          <p>
            <strong>Medications</strong>
          </p>
          <div>
            {medications.map((medication, index) => (
              <Box
                key={index}
                style={{
                  marginLeft: "8px",
                }}>
                <div
                  style={{
                    display: "flex",
                  }}>
                  <Circle
                    sx={{
                      fontSize: "4px",
                      marginRight: "4px",
                      marginTop: "7px",
                    }}
                  />
                  <div>
                    <span>{medication.medication}: </span>
                    {medication.instructions && (
                      <span>
                        <span className={getStatusClass(medication.action)}>
                          {medicationAction(medication.action)}
                        </span>{" "}
                        - {medication.instructions}
                      </span>
                    )}
                    {medication.note && <span> - {medication.note}</span>}
                  </div>
                </div>
              </Box>
            ))}
          </div>
        </>
      )}
      {hasOrders && <strong>Orders</strong>}
      {lastVisitLabs && lastVisitLabs?.length > 0 && (
        <div>
          {lastVisitLabs.map((lab) => {
            return (
              <Box
                key={lab.lab_code}
                style={{
                  marginLeft: "8px",
                }}>
                <div
                  style={{
                    display: "flex",
                  }}>
                  <Circle
                    sx={{
                      fontSize: "4px",
                      marginRight: "4px",
                      marginTop: "7px",
                    }}
                  />
                  <div>
                    {lab.supporting_document_id && (
                      <a
                        target="_blank"
                        href={`/api/documents/${lab.supporting_document_id}/view-pdf/`}
                        rel="noreferrer">
                        {lab.lab_name}
                      </a>
                    )}
                    {!lab.supporting_document_id && <span>{lab.lab_name}</span>}
                    <span style={{ marginRight: "4px", marginLeft: "4px" }}>
                      -
                    </span>
                    <span className={getStatusClass(lab.status)}>
                      {lab.status}
                    </span>
                    {lab.note && <span> - {lab.note}</span>}
                  </div>
                </div>
              </Box>
            );
          })}
        </div>
      )}

      {proceduresOrdered && proceduresOrdered?.length > 0 && (
        <>
          {proceduresOrdered.map((procedure, index) => (
            <Box
              key={index}
              style={{
                marginLeft: "8px",
              }}>
              <div
                style={{
                  display: "flex",
                }}>
                <Circle
                  sx={{
                    fontSize: "4px",
                    marginRight: "4px",
                    marginTop: "7px",
                  }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  {procedure.supporting_document_id && (
                    <a
                      target="_blank"
                      href={`/api/documents/${procedure.supporting_document_id}/view-pdf/`}
                      rel="noreferrer">
                      {procedure.action}
                    </a>
                  )}
                  {!procedure.supporting_document_id && (
                    <span>{procedure.action}</span>
                  )}
                  <span style={{ marginRight: "4px", marginLeft: "4px" }}>
                    -
                  </span>
                  <span className={getStatusClass(procedure.status)}>
                    {procedure.status}
                  </span>
                  {procedure.note && <span> - {procedure.note}</span>}
                  {procedure.supporting_doc_summary && (
                    <div
                      onClick={() => {
                        setDocumentSummary(procedure.supporting_doc_summary);
                        setIsDocumentSummaryOpen(true);
                      }}
                      style={{
                        marginLeft: "4px",
                        display: "flex",
                        cursor: "pointer",
                      }}>
                      <RoundExclamatory />
                    </div>
                  )}
                </div>
              </div>
            </Box>
          ))}
        </>
      )}

      {imagesOrdered && imagesOrdered?.length > 0 && (
        <>
          {imagesOrdered.map((image, index) => (
            <Box
              key={index}
              style={{
                marginLeft: "8px",
              }}>
              <div
                style={{
                  display: "flex",
                }}>
                <Circle
                  sx={{
                    fontSize: "4px",
                    marginRight: "4px",
                    marginTop: "7px",
                  }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  {image.supporting_document_id && (
                    <a
                      target="_blank"
                      href={`/api/documents/${image.supporting_document_id}/view-pdf/`}
                      rel="noreferrer">
                      {image.action}
                    </a>
                  )}
                  {!image.supporting_document_id && <span>{image.action}</span>}
                  <span style={{ marginRight: "4px", marginLeft: "4px" }}>
                    -
                  </span>
                  <span className={getStatusClass(image.status)}>
                    {image.status}
                  </span>
                  {image.note && <span> - {image.note}</span>}
                  {image.supporting_doc_summary && (
                    <div
                      onClick={() => {
                        setDocumentSummary(image.supporting_doc_summary);
                        setIsDocumentSummaryOpen(true);
                      }}
                      style={{
                        marginLeft: "4px",
                        display: "flex",
                        cursor: "pointer",
                      }}>
                      <RoundExclamatory />
                    </div>
                  )}
                </div>
              </div>
            </Box>
          ))}
        </>
      )}

      {referrals && referrals?.length > 0 && (
        <div>
          {referrals.map((referral, index) => (
            <Box
              key={index}
              style={{
                marginLeft: "8px",
              }}>
              <div
                style={{
                  display: "flex",
                }}>
                <Circle
                  sx={{
                    fontSize: "4px",
                    marginRight: "4px",
                    marginTop: "7px",
                  }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  {referral.supporting_document_id && (
                    <a
                      target="_blank"
                      href={`/api/documents/${referral.supporting_document_id}/view-pdf/`}
                      rel="noreferrer">
                      {linkText(referral)}
                    </a>
                  )}
                  {!referral.supporting_document_id && (
                    <span>{referral.action}</span>
                  )}
                  <span style={{ marginRight: "4px", marginLeft: "4px" }}>
                    -
                  </span>
                  <span className={getStatusClass(referral.status)}>
                    {referral.status}
                  </span>
                  {referral.note && <span> - {referral.note}</span>}
                  {referral.supporting_doc_summary && (
                    <div
                      onClick={() => {
                        setDocumentSummary(referral.supporting_doc_summary);
                        setIsDocumentSummaryOpen(true);
                      }}
                      style={{
                        marginLeft: "4px",
                        display: "flex",
                        cursor: "pointer",
                      }}>
                      <RoundExclamatory />
                    </div>
                  )}
                </div>
              </div>
            </Box>
          ))}
        </div>
      )}

      {plan && plan?.length > 0 && (
        <div>
          {plan.map((plan, index) => (
            <Box
              key={index}
              style={{
                marginLeft: "8px",
              }}>
              <div
                style={{
                  display: "flex",
                }}>
                <Circle
                  sx={{
                    fontSize: "4px",
                    marginRight: "4px",
                    marginTop: "7px",
                  }}
                />
                <div
                  className="ai-generated-content"
                  style={{ display: "flex", alignItems: "center" }}>
                  {plan}
                </div>
              </div>
            </Box>
          ))}
        </div>
      )}

      {(uniqueDocuments?.length > 0 ||
        outsideEncountersSinceLastEncounter?.length > 0) && (
        <>
          <h4 className="section-heading">Other Documents</h4>
          {uniqueDocuments?.map((document, index) => {
            let summary = null;
            if (document?.summary_json) {
              summary = {
                ...document.summary_json,
                title: document.title,
              };
            }
            return (
              <Box
                key={index}
                style={{
                  marginLeft: "8px",
                }}>
                <div
                  style={{
                    display: "flex",
                  }}>
                  <Circle
                    sx={{
                      fontSize: "4px",
                      marginRight: "4px",
                      marginTop: "7px",
                    }}
                  />
                  <a
                    target="_blank"
                    href={`/api/documents/${document.id}/view-pdf/`}
                    rel="noreferrer">
                    {document.title}
                  </a>
                  {document.received_datetime && (
                    <span style={{ marginLeft: "4px", marginRight: "4px" }}>
                      - {moment(document.received_datetime).format(dateFormat)}
                    </span>
                  )}
                  {document.summary_json && (
                    <div
                      onClick={() => {
                        setDocumentSummary(summary);
                        setIsDocumentSummaryOpen(true);
                      }}
                      style={{
                        marginLeft: "4px",
                        display: "flex",
                        cursor: "pointer",
                      }}>
                      <RoundExclamatory />
                    </div>
                  )}
                </div>
              </Box>
            );
          })}
          {outsideEncountersSinceLastEncounter?.map((encounter, index) => (
            <Box
              key={index}
              style={{
                marginLeft: "8px",
              }}>
              <div
                style={{
                  display: "flex",
                }}>
                <Circle
                  sx={{
                    fontSize: "4px",
                    marginRight: "4px",
                    marginTop: "7px",
                  }}
                />
                <div>
                  {encounter?.category} -{" "}
                  {moment(encounter.date).format(dateFormat)}
                  {encounter.reason && ` - ${encounter.reason}`}
                </div>
              </div>
            </Box>
          ))}
        </>
      )}
      {importantLabs && importantLabs?.length > 0 && hasLabValues && (
        <>
          <h4 className="section-heading">Recent Labs</h4>
          <table className="EditorTheme__table">
            <thead>
              <tr>
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    className="EditorTheme__tableCell EditorTheme__tableCellHeader">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {labRows?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row?.map((cell, cellIndex) => (
                    <td key={cellIndex} className="EditorTheme__tableCell">
                      {cellIndex === 0 ? (
                        <strong>{cell === "eGFR" ? "eGFR (5.1)" : cell}</strong>
                      ) : typeof cell === "object" ? (
                        <a
                          className="ai-generated-content"
                          target="_blank"
                          href={`/api/documents/${cell.scanned_document_id}/view-pdf/`}
                          rel="noreferrer">
                          {cell.value || "-"}
                        </a>
                      ) : (
                        cell || "-"
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px 12px",
          marginTop: "18px",
          borderRadius: "6px",
          background: theme.palette.background.disclaimer,
        }}>
        <span style={{ marginLeft: "5px", marginRight: "5px" }}>
          <LogoIcon />
        </span>
        <span style={{ fontSize: "12px", fontWeight: 500 }}>
          This Smart Summary is AI-generated using multiple clinical data
          sources and may contain errors or omissions. Please review for
          accuracy before clinical use.
        </span>
      </Box>
      <DocumentSummaryModal
        documentSummary={documentSummary}
        isOpen={isDocumentSummaryOpen}
        onClose={() => setIsDocumentSummaryOpen(false)}
      />
    </Box>
  );
};
