import { $isLinkNode } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getNearestNodeFromDOMNode,
  $getSelection,
  $isRangeSelection,
} from "lexical";
import { useEffect } from "react";

function isLinkDomNode(domNode) {
  return domNode.nodeName.toLowerCase() === "a";
}

function getLinkDomNode(event, editor) {
  return editor.getEditorState().read(() => {
    const domNode = event.target;

    if (isLinkDomNode(domNode)) {
      return domNode;
    }

    if (domNode.parentNode && isLinkDomNode(domNode.parentNode)) {
      return domNode.parentNode;
    }

    return null;
  });
}

function ClickableLinkPlugin({ filter, newTab = true }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    function onClick(e) {
      const linkDomNode = getLinkDomNode(e, editor);

      if (!linkDomNode) {
        return;
      }

      const href = linkDomNode.getAttribute("href");

      if (linkDomNode.getAttribute("contenteditable") === "false" || !href) {
        return;
      }

      const selection = editor.getEditorState().read($getSelection);
      if ($isRangeSelection(selection) && !selection.isCollapsed()) {
        return;
      }

      let linkNode = null;
      editor.update(() => {
        const maybeLinkNode = $getNearestNodeFromDOMNode(linkDomNode);
        if ($isLinkNode(maybeLinkNode)) {
          linkNode = maybeLinkNode;
        }
      });

      if (linkNode === null || (filter && !filter(e, linkNode))) {
        return;
      }

      try {
        window.open(
          href,
          newTab || e.metaKey || e.ctrlKey ? "_blank" : "_self",
        );
      } catch {
        // It didn't work, which is better than throwing an exception!
      }
    }

    return editor.registerRootListener((rootElement, prevRootElement) => {
      if (prevRootElement) {
        prevRootElement.removeEventListener("click", onClick);
      }

      if (rootElement) {
        rootElement.addEventListener("click", onClick);
      }
    });
  }, [editor, filter, newTab]);

  return null;
}

export default ClickableLinkPlugin;
