import InputField from "components/common/FormComponents/InputField";
import { Box } from "@mui/material";
import { IconButton } from "components/common/Button/IconButton";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";

export const NoteInput = ({
  feedback,
  handleFeedbackSubmit,
  handleFeedbackCancel,
  showCancelButton = true,
}) => {
  const [localFeedback, setLocalFeedback] = useState(feedback);
  return (
    <Box display="flex" flexDirection="row" sx={{ margin: "8px 0 0 28px" }}>
      <InputField
        cols={11}
        value={localFeedback}
        onChange={(e) => {
          setLocalFeedback(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && localFeedback?.length > 0) {
            handleFeedbackSubmit(localFeedback);
          }
        }}
        name="feedback"
        placeholder="Enter Note"
        sxInputProps={{
          fontSize: "14px",
        }}
      />
      {localFeedback?.length > 0 && (
        <IconButton
          height="40px"
          onClick={() => handleFeedbackSubmit(localFeedback)}
          sx={{
            marginLeft: "8px",
            minWidth: "40px",
          }}>
          <CheckIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
          />
        </IconButton>
      )}
      {showCancelButton && (
        <IconButton
          height="40px"
          onClick={handleFeedbackCancel}
          sx={{
            marginLeft: "8px",
            minWidth: "40px",
          }}>
          <ClearOutlinedIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};
