import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearLoginMessage,
  onLogin,
  setCheckLoggedInUser,
  getCurrentUser,
  getAccountsApi,
  clearRedirectUrl,
} from "redux/features/Auth/loginSlice";
import { onPressEnter } from "utils/onPressEnter";
import { loginValidationSchema } from "validations/auth/login/login.schema";
import LoginWrapper from "./LoginWrapper";
import { toast } from "react-toastify";

const LoginForm = ({ oidcEnabled }) => {
  // This gate is used to ensure that redirects to OIDC endpoints are not performed
  // with multiple re-renders until specifically set by a new login attempt or
  // logout request.
  const [readyToRedirect, setReadyToRedirect] = useState(false);

  const {
    loggedIn,
    firstTimeLogin,
    isLoading,
    message,
    checkLoggedInUser,
    isAuthorized,
    redirectUrl,
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: () => {
      dispatch(onLogin(formik.values));
    },
  });

  // Clear any login message when the page is first rendered.
  useEffect(() => {
    dispatch(clearLoginMessage());
  }, [dispatch]);

  // This effect displays toast messages whenever the login flow sets a message
  // to be displayed.
  useEffect(() => {
    if (message !== "") {
      // Display the message as a toast notification
      // You can replace this with your own toast notification logic
      dispatch(clearLoginMessage());
      toast.error(message);
    }
  }, [dispatch, message]);

  // Allow an initial check for a logged in user when the login page is first
  // loaded. This is useful for not prompting to login again if the user browses
  // away from the app.
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(clearRedirectUrl());
      setReadyToRedirect(true);
      dispatch(setCheckLoggedInUser(true));
    }, 1500);

    return () => clearTimeout(timer);
  }, [dispatch]);

  // Check for a logged in user either initially when loading the login page or
  // after a successful authentication. Do not check if the user is already logged in.
  useEffect(() => {
    if (checkLoggedInUser && !loggedIn) {
      dispatch(setCheckLoggedInUser(false));
      dispatch(getCurrentUser());
    }
  }, [checkLoggedInUser, dispatch, loggedIn]);

  // Once a user is logged in, make a call to GET /accounts to ensure the user
  // is authorized for at least one account. If the API returns a 403 error, they
  // will be treated as unauthorized and prevented from accessing the application.
  useEffect(() => {
    if (loggedIn) {
      dispatch(getAccountsApi());
    }
  }, [dispatch, loggedIn]);

  // If a user is both logged in and authorized to use the application, redirect
  // them to the appropriate page.
  useEffect(() => {
    if (loggedIn && isAuthorized) {
      if (firstTimeLogin) {
        navigate("/settings?tab=0");
      } else {
        navigate("/appointments");
      }
    }
  }, [loggedIn, isAuthorized, navigate, firstTimeLogin]);

  // This effect wiil redirect the user to the OIDC endpoint if the redirect URL is set
  useEffect(() => {
    if (readyToRedirect && oidcEnabled && redirectUrl !== "") {
      window.location.assign(redirectUrl);
    }
  }, [readyToRedirect, redirectUrl, oidcEnabled]);

  return (
    <LoginWrapper>
      {!oidcEnabled && (
        <Box sx={{ maxWidth: "100%", width: "300px", marginTop: "33px" }}>
          <Grid container spacing={1} flexDirection={"column"}>
            <InputField
              readonly={isLoading}
              onKeyDown={(e) => onPressEnter(e, () => formik.submitForm())}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              placeholder="Your Username Here"
              label="Username"
              formik={formik}
              name="username"
            />
            <InputField
              readonly={isLoading}
              onKeyDown={(e) => onPressEnter(e, () => formik.submitForm())}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              placeholder={"Your Password Here"}
              label="Password"
              formik={formik}
              name="password"
              type={"password"}
            />
          </Grid>
          <Button
            isLoading={isLoading}
            text="Login"
            sx={{
              width: "100%",
              height: "38px",
              marginTop: "10px",
              "&:hover": {
                color: "#fff",
              },
              backgroundImage:
                "linear-gradient(270deg, #4C2631 1.99%, #285677 90.93%)",
            }}
            onClick={formik.handleSubmit}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}>
            <Text
              variant="bodyS"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/forgotPassword")}>
              Forgot Password?
            </Text>
          </Box>
        </Box>
      )}
      {oidcEnabled && (
        <Box sx={{ maxWidth: "100%", width: "300px", marginTop: "33px" }}>
          <p>Logging in user...</p>
        </Box>
      )}
    </LoginWrapper>
  );
};

export default LoginForm;
