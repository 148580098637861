import { combineReducers } from "redux";
import admin from "./Admin/AdminSlice";
import allHistory from "./AllHistories/AllHistorySlice";
import allergies from "./Allergies/AllergiesSlice";
import specificAppointment from "./Appointments/AppointmentsSlice";
import createNewPassword from "./Auth/creatNewPasswordSlice";
import auth from "./Auth/loginSlice";
import cptCode from "./CptCodes/CptCodeSlice";
import encounter from "./Encounter/EncounterSlice";
import familyHistory from "./FamilyHistory/FamilyHistorySlice";
import healthSummary from "./HealthSummary/HealthSummarySlice";
import icdCode from "./IcdCodes/IcdCodesSlice";
import labs from "./Labs/labsSlice";
import labProviders from "./Labs/LabProvidersSlice";
import labLocations from "./Labs/labLocationsSlice";
import locations from "./Locations/locationsSlice";
import medications from "./Medications/MedicationsSlice";
import medicines from "./Medications/MedicinesSlice";
import orderMedications from "./Medications/OrderMedicationsSlice";
import MyTeams from "./MyTeam/myTeamSlice";
import patients from "./Patients/ScheduledAppointmentSlice";
import resultsLabs from "./Patients/ViewLabResultsSlice";
import historyNotes from "./Patients/ViewNotesHistorySlice";
import specificPatient from "./Patients/getSpecificPatientsSlice";
import pharmacy from "./Pharmacy/PharmacySlice";
import changePassword from "./PrivacySecurity/ChangePasswordSlice";
import saya from "./Saya/SayaSlice";
import sendMessage from "./SendMessage/SendMessageSlice";
import termsMeds from "./TermsAndMeds/TermsMedsSlice";
import vitals from "./Vitals/VitalsSlice";
import globalError from "./common/globalError";
import options from "./common/optionSlice";
import forgotPassword from "./Auth/forgotPasswordSlice";
import globalStates from "./globalStateSlice";
import recording from "./AmbientAI/RecordingSlice";
import getFlags from "./Flags/FlagsSlice";
import smartRecommendations from "./SmartRecommendations/smartRecommendationsSlice";

const appReducer = combineReducers({
  auth,
  saya,
  labs,
  getFlags,
  allHistory,
  admin,
  vitals,
  recording,
  globalStates,
  cptCode,
  icdCode,
  options,
  MyTeams,
  patients,
  termsMeds,
  locations,
  encounter,
  sendMessage,
  resultsLabs,
  orderMedications,
  medications,
  globalError,
  historyNotes,
  pharmacy,
  healthSummary,
  changePassword,
  specificPatient,
  specificAppointment,
  createNewPassword,
  familyHistory,
  medicines,
  allergies,
  forgotPassword,
  labProviders,
  labLocations,
  smartRecommendations,
});

const rootReducer = (state, action) => {
  if (
    action.type === "auth/logoutSuccess" ||
    action.type === "auth/logoutOidc"
  ) {
    const { locations, getFlags } = state; // Extract the `locations` and `getFlags` slices

    localStorage.clear();
    state = appReducer(undefined, action);

    // Restore the location and feature flag slices
    return {
      ...state,
      locations,
      getFlags,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
