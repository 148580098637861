import { useQuery } from "@tanstack/react-query";
import { searchPerscriptions } from "apiClients/perscriptions";
import { useEffect, useState } from "react";
import { debounce, DEBOUNCE_DELAY } from "utils/debouncer";
import SelectField from "./SelectField";
import { Required } from "validations/validators";

export const MedicationSelectInput = ({
  patientId,
  disabled = false,
  name,
  value,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [innerLoading, setInnerLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const search = debounce((term) => {
    setSearchTerm(term);
  }, DEBOUNCE_DELAY);

  const { data: { results } = {}, isLoading } = useQuery({
    queryKey: ["medications", patientId, searchTerm],
    queryFn: async ({ signal }) =>
      await searchPerscriptions(patientId, searchTerm, signal),
    enabled: !!searchTerm && searchTerm.length > 2,
  });

  useEffect(() => {
    setInnerLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (results) {
      setSearchResults(mapResults(results));
    }
  }, [results]);

  // Set the inner loading state and clear the search results when the search term changes.
  const startSearch = (value) => {
    // If the value is less than 2 characters, we don't want to search so we don't need to show the loading spinner.
    setInnerLoading(value.length > 2);
    setSearchResults([]);
  };

  const mapResults = (values) =>
    (values ?? []).map((s, idx) => ({
      key: s.dispensable_drug_id + idx,
      label: s.drug_name,
      value: s.dispensable_drug_id,
      description: s.purpose,
    }));

  return (
    <SelectField
      value={value}
      label="Medication"
      cols={4}
      name={name}
      placeholder="Medication Name..."
      freeSolo
      onSearch={(value) => {
        startSearch(value);
        search(value);
      }}
      loading={innerLoading}
      options={searchResults}
      validators={[Required]}
      disabled={disabled}
    />
  );
};
