import { createContext, useRef } from "react";

export const FormContext = createContext({
  getFormObject: () => {},
  getFormValue: () => {},
});

export const Form = ({ onSubmit, intakeRef, initialValues, children }) => {
  const formRef = useRef(null);

  // This function will transform the flat form data into a nested object.
  function transformToNestedObject(input) {
    const output = structuredClone(initialValues);

    for (const key in input) {
      // Split by both [] and . and filter out empty values
      const keys = key.split(/[[\].]+/).filter(Boolean);

      // Start at the root of the default values object.
      let current = output;

      for (let i = 0; i < keys.length; i++) {
        const keyPart = keys[i];
        if (i === keys.length - 1) {
          // If we are at the last key, we can set the value.
          current[keyPart] = input[key];
        } else {
          if (!current[keyPart]) {
            // If the key does not exist, we need to create an object or array depending on the next key. If the next key is a number, we create an array. otherwise, we create an object.
            current[keyPart] = isNaN(keys[i + 1]) ? {} : [];
          }
          // Move to the next key.
          current = current[keyPart];
        }
      }
    }

    return output;
  }

  function getFormObject() {
    const formData = new FormData(intakeRef?.current || formRef?.current);
    const formValues = Object.fromEntries(formData.entries());
    return transformToNestedObject(formValues);
  }

  // Get the value of a key from the form data. key example: "name[0][key]" or "section.name"
  const getFormValue = (
    key,
    options,
    formKey = "",
    // If not form value is passed, we will use the current form value.
    current = getFormObject(),
  ) => {
    const keys = key?.split(/[\[\]\.]+/)?.filter(Boolean); // Split by both [] and . and filter out empty values
    for (let i = 0; i < keys?.length; i++) {
      const keyPart = keys?.[i];
      if (formKey.includes(keyPart)) continue;
      if (current[keyPart] === undefined) return "";
      current = current[keyPart];
    }

    if (options) {
      return options.find((option) => option.value === current);
    }

    return current;
  };

  return (
    <FormContext.Provider value={{ getFormObject, getFormValue }}>
      <form
        ref={intakeRef || formRef}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          const form = e.target;
          if (!form.checkValidity()) {
            const invalidFields = Array.from(form.elements).filter(
              (element) => !element.validity.valid,
            );
            if (invalidFields.length) {
              // Bring the element into focus
              invalidFields[0].focus();
              // Blur the element to make sure it has been "touched"
              invalidFields[0].blur();
            }
            return;
          }
          const formData = new FormData(e.target);
          const formValues = Object.fromEntries(formData.entries());
          const nestedObj = transformToNestedObject(formValues);
          onSubmit(nestedObj);
        }}>
        {children}
      </form>
    </FormContext.Provider>
  );
};
