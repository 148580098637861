import LoginForm from "./components/LoginForm";
import { useContext } from "react";
import UnauthorizedModal from "./components/UnauthorizedModal";
import { isFeatureEnabled, oidcFeature } from "../../../utils/featureFlags";
import { FeatureFlagsContext } from "../../../contexts/FeatureFlagsContext";

const Login = () => {
  const flagsData = useContext(FeatureFlagsContext);
  const isOidcEnabled = isFeatureEnabled(flagsData, oidcFeature);

  return (
    <>
      <UnauthorizedModal />
      <LoginForm oidcEnabled={isOidcEnabled} />
    </>
  );
};

export default Login;
