import * as React from "react";
import Popover from "@mui/material/Popover";

export default function CustomPopover({
  id,
  onClose,
  open,
  anchorEl,
  children,
}) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}>
      {children}
    </Popover>
  );
}
