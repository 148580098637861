import React from "react";
import ReactDOM from "react-dom/client";
import PatientVisitSummaryPDF from "./PatientVisitSummaryPDF";

// Function to generate visit summary PDF
export const generateVisitSummaryPDF = (patientData = {}) => {
  return new Promise((resolve, reject) => {
    // Create a temporary div to render our PDF component
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);

    // Create a root for rendering
    const root = ReactDOM.createRoot(tempDiv);

    // Function to clean up after PDF generation
    const cleanUp = (error) => {
      root.unmount(); // Unmount the component
      document.body.removeChild(tempDiv);

      if (error) {
        reject(error);
      } else {
        resolve();
      }
    };

    // Render the PDF component
    root.render(
      <PatientVisitSummaryPDF patientData={patientData} onComplete={cleanUp} />,
    );
  });
};
