import React from "react";
import { Box, IconButton } from "@mui/material";
import Text from "components/common/Typography/Text";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useFavorites } from "hooks/useFavorites";
import { useSelector } from "react-redux";
import { Trash } from "components/common/SVG/Trash";

const FavoriteItem = ({ item, onAdd, onDelete }) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "12px",
        alignItems: "center",
      }}>
      <IconButton
        size="small"
        onClick={() => onAdd(item)}
        sx={{
          p: 0,
          mt: 0.5,
          mr: 1,
        }}
        color="primary">
        <AddCircleOutlineIcon />
      </IconButton>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Text variant="body2" color="textPrimary" sx={{ fontWeight: 700 }}>
          {item.name}
        </Text>
        {item.description && (
          <Text
            variant="caption"
            color="textSecondary"
            component="div"
            sx={{ mt: 0.5 }}>
            {item.description}
          </Text>
        )}
      </Box>
      <IconButton
        size="small"
        onClick={() => onDelete(item)}
        sx={{ ml: "auto" }}>
        <Trash />
      </IconButton>
    </Box>
  );
};

const Favorites = ({ onAddItem, onDeleteItem, sx = {} }) => {
  const { userId } = useSelector((state) => state.auth);
  const {
    favorites = { results: [] },
    isLoading,
    isError,
  } = useFavorites(userId);

  if (isLoading) {
    return (
      <Box
        sx={{
          p: 2,
          height: "100%",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.08)",
        }}>
        <Text variant="h3" fontWeight={700} sx={{ mb: 2 }}>
          FAVORITES
        </Text>
        <Text>Loading favorites...</Text>
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        sx={{
          p: 2,
          height: "100%",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.08)",
        }}>
        <Text variant="h3" fontWeight={700} sx={{ mb: 2 }}>
          FAVORITES
        </Text>
        <Text color="error">Error loading favorites</Text>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        height: "100%",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.08)",
        ...sx,
      }}>
      <Text variant="h3" fontWeight={700} sx={{ mb: 2 }}>
        FAVORITES
      </Text>
      {Array.isArray(favorites.results) && favorites.results.length > 0 ? (
        favorites.results.map((item, index) => (
          <FavoriteItem
            key={index}
            item={item}
            onAdd={onAddItem}
            onDelete={onDeleteItem}
          />
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            height: "100%",
          }}>
          <Text>No favorites found</Text>
        </Box>
      )}
    </Box>
  );
};

export default Favorites;
