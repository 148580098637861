import { createContext } from "react";
import { toast } from "react-toastify";
import { apiGet } from "../apiClients/base";

// Load the feature flag data from the backend, once while creating the
// FeatureFlagsContext.
export const fetchFeatureFlagData = async () => {
  try {
    const response = await apiGet("/api/feature-flags/");

    return response.data;
  } catch (error) {
    toast.error("Error communicating with the Nephrolytics backend:" + error);
    return {};
  }
};

// Create the context with the feature flags data
export const FeatureFlagsContext = createContext(null);
