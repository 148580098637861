import { Box } from "@mui/material";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { SmartRecommendation } from "./SmartRecommendation";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { fetchSmartRecommendations } from "apiClients/smartRecommendations";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

export const SmartRecommendations = ({
  loading,
  sx,
  isEndVisitView = false,
  childSx,
  resolved = [],
}) => {
  const { patientId } = useParams();
  const queryClient = useQueryClient();
  const [lastRecommendation, setLastRecommendation] = useState(null);
  const prevLoadingRef = useRef(loading);
  const observerRef = useRef(null);
  const fetchingRef = useRef(false);

  // Create memoized selectors using createSelector
  const selectRecommendationUpdates = useMemo(
    () =>
      createSelector(
        [(state) => state.smartRecommendations.recommendationUpdates],
        (recommendationUpdates) => recommendationUpdates[patientId] || {},
      ),
    [patientId],
  );

  const selectFlaggedRecommendations = useMemo(
    () =>
      createSelector(
        [(state) => state.smartRecommendations.activeFlaggedRecommendations],
        (activeFlaggedRecommendations) =>
          activeFlaggedRecommendations[patientId] || {},
      ),
    [patientId],
  );

  // Use the memoized selectors
  const recommendationUpdates = useSelector(selectRecommendationUpdates);
  const flaggedRecommendations = useSelector(selectFlaggedRecommendations);

  const [hasMostRecentData, setHasMostRecentData] = useState(false);

  useEffect(() => {
    if (prevLoadingRef.current === true && loading === false) {
      queryClient.invalidateQueries(["smartRecommendations", patientId]);
      setHasMostRecentData(false);
    }
    prevLoadingRef.current = loading;
  }, [loading, patientId, queryClient]);

  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["smartRecommendations", patientId],
    queryFn: ({ pageParam }) => {
      return fetchSmartRecommendations(patientId, pageParam);
    },
    staleTime: 10 * 60 * 1000, // 10 minutes stale time
    getNextPageParam: (lastPage) => {
      return lastPage.next;
    },
    enabled: !hasMostRecentData,
  });

  useEffect(() => {
    fetchingRef.current = isFetching;
  }, [isFetching]);

  // Automatically fetch all pages - at all times we should only have 'a few' smart recommendations (10-30)
  useEffect(() => {
    if (data && hasNextPage && !fetchingRef.current) {
      fetchingRef.current = true;
      setTimeout(() => {
        fetchNextPage().finally(() => {
          setTimeout(() => {
            fetchingRef.current = false;
          }, 300);
          setHasMostRecentData(true);
        });
      }, 300);
    } else {
      setHasMostRecentData(true);
    }
  }, [data, hasNextPage, fetchNextPage]);

  // Handle intersection observer for infinite loading with debounce
  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage && !fetchingRef.current) {
        // Set our ref to true to prevent multiple rapid calls
        fetchingRef.current = true;

        // Wait a bit before actually fetching to prevent spamming
        setTimeout(() => {
          fetchNextPage().finally(() => {
            setTimeout(() => {
              fetchingRef.current = false;
            }, 300);
          });
        }, 300);
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    const element = lastRecommendation;
    // Only create observer if we have an element and more pages to load
    if (!element || !hasNextPage) return;

    // Clean up previous observer
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // Create new observer with a higher threshold to reduce sensitivity
    const observer = new IntersectionObserver(handleObserver, {
      rootMargin: "100px",
      threshold: 0.5,
    });

    observer.observe(element);
    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [lastRecommendation, handleObserver, hasNextPage]);

  const processData = useCallback(() => {
    if (!data || !data.pages) return [];

    // Create a map to maintain original order by ID
    const itemsByIdWithOrder = new Map();

    // 1. First, collect all items from API data with their original index
    // This gives us the original ordering from the API
    let itemIndex = 0;
    data.pages.forEach((page) => {
      if (page.results) {
        page.results.forEach((item) => {
          // Store item with its original order index
          itemsByIdWithOrder.set(item.id, { item, originalIndex: itemIndex++ });
        });
      }
    });

    // 2. Process the items in original order
    const result = [];
    itemsByIdWithOrder.forEach(({ item, originalIndex }, itemId) => {
      // Check if item has been updated in Redux
      const reduxUpdate = recommendationUpdates[itemId];

      // Apply any Redux updates to the item
      const mergedItem = reduxUpdate
        ? {
            ...item,
            status: reduxUpdate.status,
            feedback: reduxUpdate.feedback,
          }
        : item;

      // Determine the effective status (after any Redux updates)
      const effectiveStatus = mergedItem.status;

      // Include an item if:
      // 1. It's not rejected/refused in the API data, OR
      // 2. It's in our Redux store of flagged/refused items that we want to keep visible
      if (
        (effectiveStatus !== "Rejected" && effectiveStatus !== "Refused") ||
        flaggedRecommendations[itemId]
      ) {
        // Add to result with original index for sorting later
        result.push({ ...mergedItem, originalIndex });
      }
    });

    // 3. Sort by original index to maintain the original order
    result.sort((a, b) => a.originalIndex - b.originalIndex);

    return result;
  }, [data, recommendationUpdates, flaggedRecommendations]);

  const visibleData = processData();

  const resolvedData = visibleData.map((item) => {
    const resolvedItem = resolved.find(
      (resolvedItem) => resolvedItem === item.normalized_comparison,
    );
    if (resolvedItem) {
      return {
        ...item,
        resolved: true,
      };
    }
    return item;
  });

  const displaySkeleton = () => {
    if (loading) return true;
    if (isFetching && !data?.pages?.length) return true;
    return false;
  };

  return (
    <Box
      sx={{
        margin: "24px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        ...sx,
      }}>
      {displaySkeleton() ? (
        <>
          {Array(7)
            .fill("")
            .map((_, i) => (
              <Skeleton
                key={i}
                height={50}
                data-testid="smart-recommendation-skeleton"
              />
            ))}
        </>
      ) : (
        <>
          {resolvedData.length > 0 ? (
            <>
              {resolvedData.map((item) => (
                <SmartRecommendation
                  key={item.id}
                  id={item.id}
                  headline={item.headline}
                  why={item.why}
                  guideline_ref={item.guideline_ref}
                  guideline_url={item.guideline_url}
                  priority={item.priority}
                  feedback={item.feedback}
                  status={item.status}
                  ai_generated={item.ai_generated}
                  originalIndex={item.originalIndex}
                  isEndVisitView={isEndVisitView}
                  sx={childSx}
                  resolved={item.resolved}
                />
              ))}

              {hasNextPage && (
                <Box height={10} ref={setLastRecommendation}>
                  {isFetching && (
                    <Skeleton
                      height={50}
                      data-testid="smart-recommendation-loading-skeleton"
                    />
                  )}
                </Box>
              )}
            </>
          ) : (
            <div>No data</div>
          )}
        </>
      )}
    </Box>
  );
};
