import { apiDelete, apiGet, apiPost, apiPut } from "./base";

export const fetchPatientDocuments = async ({ pageParam }, patientId) => {
  const url = `/api/patients/${patientId}/documents/`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const fetchPatientDocumentsSinceLastEncounter = async (patientId) => {
  const url = `/api/documents/documents-since-last-encounter/?patient_id=${patientId}`;
  const response = await apiGet(url);
  return response.data;
};

export const fetchPifDocs = async (patientId) => {
  const url = `/api/documents/external/?patient_id=${patientId}`;
  const response = await apiGet(url);
  return response.data;
};

export const addPifDocs = async (patientId, data) => {
  const url = `/api/patients/${patientId}/documents/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updatePifDocs = async (patientId, id, data) => {
  const url = `/api/patients/${patientId}/documents/${id}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const deletePifDocs = async (patientId, id) => {
  const url = `/api/patients/${patientId}/documents/${id}/`;
  const response = await apiDelete(url);
  return response.data;
};
