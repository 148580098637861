import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { SubForm } from "../common/SubForm";
import { Required } from "validations/validators";
import { FormContext } from "components/common/FormComponents/Form";
import { dateFormat } from "utils/date";
import { formatErrorsToAlerts } from "../common/utils";
import { Box, FormControl, FormControlLabel, Switch } from "@mui/material";
import {
  fetchLatestSmartNote,
  updateSmartNote,
} from "apiClients/latestSmartNote";
import HelpIcon from "assets/svg/light/helpIcon";
import HelpIconFilled from "assets/svg/colored/helpIconFilled";
import CustomPopover from "../common/popover";
import { alignPatientIntakeHeadingIconStyle } from "styles";
import {
  addMedicalReport,
  deleteMedicalReport,
  fetchAllLatestMedicalReports,
  fetchAllMedicalReports,
  updateMedicalReport,
} from "apiClients/medicalReports";

export const ImagingReview = memo(({ errors }) => {
  const queryClient = useQueryClient();
  const [mappedResults, setMappedResults] = useState([]);
  const { patientId, registerForm } = useContext(IntakeFormContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const imagingInputs = [
    {
      label: "Category",
      key: "category",
      type: "select",
      isFieldDisabled: (id) => {
        return id?.endsWith("-smart-data") ? true : false;
      },
      options: [
        { label: "CT", value: "CT" },
        { label: "MRI", value: "MRI" },
        { label: "X-Ray", value: "X-Ray" },
        { label: "Ultrasound", value: "Ultrasound" },
        { label: "Cardiac Imaging", value: "Cardiac Imaging" },
        { label: "Nuclear Medicine", value: "Nuclear Medicine" },
      ],
      cols: 4,
      freeSolo: true,
    },
    {
      isFieldDisabled: (id) => {
        return id?.endsWith("-smart-data") ? true : false;
      },
      label: "Type",
      key: "report_type",
      cols: 2,
    },
    {
      isFieldDisabled: (id) => {
        return id?.endsWith("-smart-data") ? true : false;
      },
      label: "Status",
      key: "status",
      type: "select",
      options: [
        { value: "Ordered", label: "Ordered" },
        { value: "Scheduled", label: "Scheduled" },
        { value: "Completed–Docs Avail", label: "Completed–Docs Avail" },
        { value: "Completed–No Docs", label: "Completed–No Docs" },
        { value: "Canceled", label: "Canceled" },
      ],
      cols: 4,
      validators: [Required],
    },
    {
      label: "External",
      key: "external_referral_type",
      customComponent: ({ id, key, name, value, onChange }) => {
        return (
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    key={key}
                    name={name}
                    checked={value == "on"}
                    disabled={id?.endsWith("-smart-data") ? true : false}
                    onChange={(e) => {
                      const syntheticEvent = {
                        target: {
                          name: name,
                          value: e.target.checked ? "on" : "off",
                        },
                      };
                      onChange?.(syntheticEvent);
                    }}
                  />
                }
                label="External"
              />
            </FormControl>
          </Box>
        );
      },
      cols: 2,
    },
    {
      type: "link",
      key: "supporting_document_id",
      cols: 2,
    },
  ];

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      const updatedPayload = {
        ...dirtyValue,
      };
      if (dirtyValue?.delete)
        return deleteMedicalReport(patientId, dirtyValue?.id);
      if (dirtyValue?.smart_note_data) {
        return;
      }
      if (dirtyValue?.id)
        return updateMedicalReport(patientId, updatedPayload?.id, {
          ...updatedPayload,
          date: moment().format("YYYY-MM-DD"),
        });
      addMedicalReport(patientId, {
        ...updatedPayload,
        date: moment().format("YYYY-MM-DD"),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["medications", patientId],
      });
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["latestSmartNote", patientId],
    queryFn: () => fetchLatestSmartNote(patientId),
  });

  const { data: imagingData, isLoading: imagingLoading } = useQuery({
    queryKey: ["allmedicalReports", patientId],
    queryFn: () => fetchAllLatestMedicalReports(patientId),
  });

  const handleSubmit = async (dirtyValues) => {
    const updatedValues = { ...data };

    // Separate smart-data items from other items
    const smarImagingtDataItems = dirtyValues?.filter((dtyVal) =>
      dtyVal?.id?.endsWith("-smart-data"),
    );
    const otherItems = dirtyValues?.filter(
      (dtyVal) => !dtyVal?.id?.endsWith("-smart-data"),
    );

    let operations = [];

    if (smarImagingtDataItems?.length) {
      operations.push(
        mutation.mutateAsync({
          smart_note_data: {
            ...updatedValues?.smart_note_data,
            imaging: smarImagingtDataItems?.map((item) => ({
              ...item,
              action: item?.report_type,
              external_referral_type:
                item?.external_referral_type == "on" ? "External" : "Referral",
            })), // Pass all smart-data items together
          },
        }),
      );
    }
    otherItems?.forEach((item) => {
      operations.push(
        mutation.mutateAsync({
          ...item,
          external_referral_type:
            item?.external_referral_type == "on" ? "External" : "Referral",
        }),
      ); // Process each non-smart-data item separately
    });

    return Promise.allSettled(operations);
  };

  useEffect(() => {
    if (isLoading || imagingLoading) return;
    const smartNoteImaging =
      data?.smart_note_data?.imaging?.map((item, idx) => ({
        ...item,
        external_referral_type:
          item?.external_referral_type == "External" ? "on" : "off",
        id: `${idx}-smart-data`,
        report_type: item?.action,
        hideDeleteIcon: true,
      })) || [];
    const medicationImaging =
      imagingData?.map((item) => ({
        ...item,
        external_referral_type:
          item?.external_referral_type == "External" ? "on" : "off",
      })) || [];
    const combinedData = [...smartNoteImaging, ...medicationImaging];

    registerForm("imagings", handleSubmit, combinedData);
    setMappedResults(combinedData);
  }, [data, imagingData, imagingLoading, isLoading]);

  useEffect(() => {
    return () => {
      queryClient.resetQueries({
        queryKey: ["latestSmartNote", patientId],
      });
      queryClient.resetQueries({
        queryKey: ["allmedicalReports", patientId],
      });
    };
  }, [queryClient, patientId]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        <Text variant="h1">Imaging</Text>
        <span
          onClick={handleClick}
          aria-describedby={"simple-popover"}
          style={alignPatientIntakeHeadingIconStyle}>
          {anchorEl == null ? <HelpIcon /> : <HelpIconFilled />}
        </span>
      </Box>
      <CustomPopover
        id={open ? "simple-popover" : undefined}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        <Box sx={{ padding: 2, maxWidth: "380px" }}>
          <Box>
            {" "}
            <Text marginY={1} variant="h4" fontWeight={700} color={"grey"}>
              GUIDELINE
            </Text>
          </Box>
          <Box>
            <Text variant="h4" fontWeight={700} sx={{ whiteSpace: "unset" }}>
              Confirm pending & completed labs.
            </Text>
          </Box>
          <ul style={{ paddingLeft: "16px" }}>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Recent imaging? Confirm if they had any X-rays, MRIs, CT scans,
              ultrasounds, or other imaging tests outside this clinic.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Pending tests from previous visits? Check if any imaging ordered
              at the last visit were completed. If not, why?
            </li>
          </ul>
        </Box>
      </CustomPopover>
      <SubForm
        subSections={imagingInputs}
        formKey="imagings"
        dataLoading={isLoading}
        data={mappedResults}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
