import CustomModal from "../../../../components/common/Modal/CustomModal";
import { Box } from "@mui/material";
import Button from "../../../../components/common/Button/Button";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isFeatureEnabled, oidcFeature } from "../../../../utils/featureFlags";
import {
  logoutApi,
  logoutOidc,
} from "../../../../redux/features/Auth/loginSlice";
import { FeatureFlagsContext } from "../../../../contexts/FeatureFlagsContext";

/*
 * This component is used to show a modal dialog when the user has logged in using
 * a credential that is not authorized for any customer accounts. The modal will
 * display when a message instructing the user who to contact for assistance, and
 * dismissing the dialog will result in a logout being performed.
 */
const UnauthorizedModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Determine which logout action to invoke based on OIDC feature flag
  const flagsData = useContext(FeatureFlagsContext);
  const oidcEnabled = isFeatureEnabled(flagsData, oidcFeature);
  const { loggedIn, isAuthorized, isAuthInProgress } = useSelector(
    (state) => state.auth,
  );

  const dispatch = useDispatch();

  const handleOkClick = () => {
    setShowModal(false);
    oidcEnabled ? dispatch(logoutOidc()) : dispatch(logoutApi());
  };

  // Show the modal if the user is logged in but not authorized after the login
  // flow is complete
  useEffect(() => {
    if (loggedIn && !isAuthorized && !isAuthInProgress) {
      setShowModal(true);
    }
  }, [loggedIn, isAuthorized, isAuthInProgress]);

  return (
    <>
      <CustomModal
        open={showModal}
        title="Unauthorized Access"
        maxWidth="sm"
        fade={true}
        showCloseIcon={false}
        actions={[
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleOkClick} text={"Logout"} />
          </Box>,
        ]}
        sxDialog={{
          "& .MuiDialog-paper": {
            maxWidth: "400px",
          },
        }}
        sxDialogContent={{
          padding: "0px",
          minWidth: "auto",
        }}>
        <Box sx={{ padding: "15px" }}>
          You are not authorized to use this application. Please contact your
          Practice Administrator or Nephrolytics support for assistance.
        </Box>
      </CustomModal>
    </>
  );
};

export default UnauthorizedModal;
