import React from "react";

const WarningIcon = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="28" height="28" rx="14" fill="#ED6C02" />
      <mask
        id="mask0_7353_59672"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="24"
        height="25">
        <rect x="2" y="2.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7353_59672)">
        <path
          d="M14 19.5C14.2833 19.5 14.5208 19.4042 14.7125 19.2125C14.9042 19.0208 15 18.7833 15 18.5C15 18.2167 14.9042 17.9792 14.7125 17.7875C14.5208 17.5958 14.2833 17.5 14 17.5C13.7167 17.5 13.4792 17.5958 13.2875 17.7875C13.0958 17.9792 13 18.2167 13 18.5C13 18.7833 13.0958 19.0208 13.2875 19.2125C13.4792 19.4042 13.7167 19.5 14 19.5ZM13 15.5H15V9.5H13V15.5ZM14 24.5C12.6167 24.5 11.3167 24.2375 10.1 23.7125C8.88333 23.1875 7.825 22.475 6.925 21.575C6.025 20.675 5.3125 19.6167 4.7875 18.4C4.2625 17.1833 4 15.8833 4 14.5C4 13.1167 4.2625 11.8167 4.7875 10.6C5.3125 9.38333 6.025 8.325 6.925 7.425C7.825 6.525 8.88333 5.8125 10.1 5.2875C11.3167 4.7625 12.6167 4.5 14 4.5C15.3833 4.5 16.6833 4.7625 17.9 5.2875C19.1167 5.8125 20.175 6.525 21.075 7.425C21.975 8.325 22.6875 9.38333 23.2125 10.6C23.7375 11.8167 24 13.1167 24 14.5C24 15.8833 23.7375 17.1833 23.2125 18.4C22.6875 19.6167 21.975 20.675 21.075 21.575C20.175 22.475 19.1167 23.1875 17.9 23.7125C16.6833 24.2375 15.3833 24.5 14 24.5ZM14 22.5C16.2333 22.5 18.125 21.725 19.675 20.175C21.225 18.625 22 16.7333 22 14.5C22 12.2667 21.225 10.375 19.675 8.825C18.125 7.275 16.2333 6.5 14 6.5C11.7667 6.5 9.875 7.275 8.325 8.825C6.775 10.375 6 12.2667 6 14.5C6 16.7333 6.775 18.625 8.325 20.175C9.875 21.725 11.7667 22.5 14 22.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default WarningIcon;
