import { Alert, AlertTitle, Box, Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { memo, useEffect, useState } from "react";
import MainContainer from "./MainContainer";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Button from "components/common/Button/Button";
import { alignItemsCenter } from "styles";
import { verifyDosepotConnection } from "apiClients/dosepot-connection.";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDosepotConnection } from "redux/features/globalStateSlice";
import DosepotConnectionModal from "components/DosepotConnectionModal";

const DashboardLayout = styled("div")(({ theme }) => ({
  display: "flex",
  "& .MuiAppBar-root": {
    zIndex: theme.zIndex.drawer + 1,
    "& .MuiTypography-root": {
      flexGrow: 1,
    },
  },
  "& .MuiDrawer-root": {
    flexShrink: 0,
    position: "fixed",
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      width: "11%",
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      width: "4.2%",
    },
    "& .MuiDrawer-paper": {
      backgroundColor: "transparent !important",
      height: "100%",
      marginTop: "83px",
      marginLeft: "5px",
      [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
        width: "75px",
        marginLeft: "5px",
      },
      [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        width: "75px",
        marginLeft: "5px",
      },
    },
  },
  "& .MuiListItem-root": {
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(3),
  },
  "& .MuiListItemIcon-root": {
    minWidth: theme.spacing(4),
    marginRight: theme.spacing(2),
    color: "#3245B4",
  },
  "& .MuiListItemText-primary": {
    fontWeight: 500,
    color: "#3245B4",
  },
  "& .MuiBox-root.MuiBox-height100": {
    height: "100%",
  },
  "& .MuiBox-root.MuiBox-sidebarExpanded": {
    width: theme.spacing(9),
    "& .MuiListItemText-primary": {
      display: "none",
    },
  },
  "& .MuiCheckbox-root:hover, & .MuiRadio-root:hover": {
    backgroundColor: "transparent",
  },
  ".MuiAutocomplete-hasPopupIcon.css-1koza9f-MuiAutocomplete-root .MuiOutlinedInput-root":
    {
      padding: "8.5px !important",
    },
}));

const StyledDrawer = styled(Drawer)({
  flexShrink: 0,
  overflow: "hidden",
  zIndex: 1200,
  "& .MuiDrawer-paper": {
    height: "calc(100% - 89px)",
    boxSizing: "border-box",
    borderRight: "none",
    transition: "width 0.3s ease-in-out",
  },
});

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [showDosepotConnectionModal, setShowDosepotConnectionModal] =
    useState(false);
  const { openDosepotConnection } = useSelector((state) => state.globalStates);
  const { loggedIn } = useSelector((state) => state.auth);
  const { data: dosepotConnection, isLoading: isDosepotConnectionLoading } =
    useQuery({
      enabled: loggedIn,
      queryKey: ["dosepotConnection"],
      queryFn: verifyDosepotConnection,
    });

  useEffect(() => {
    if (dosepotConnection?.status !== "OK") {
      dispatch(setOpenDosepotConnection(true));
    } else {
      dispatch(setOpenDosepotConnection(false));
    }
  }, [dosepotConnection]);

  return (
    <DashboardLayout data-testid="layout-container">
      <StyledDrawer variant="permanent" anchor="left">
        <Sidebar />
      </StyledDrawer>
      <MainContainer marginLeft="93px" marginTop="90px">
        {/* {openDosepotConnection && !isDosepotConnectionLoading && (
          <Box marginY={2}>
            <Alert
              sx={{
                background: "#FFDFB8",
                fontWeight: 500,
                "& .MuiAlert-action": { alignItems: "center" },
                "& .MuiAlert-message": { flex: 1 },
              }}
              severity="warning"
              action={[
                <Button
                  onClick={() => setShowDosepotConnectionModal(true)}
                  sx={{ alignItemsCenter }}
                  text={"Let's Fix It"}
                />,
                <Box
                  onClick={() => dispatch(setOpenDosepotConnection(false))}
                  sx={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    fontSize: "33px",
                    marginX: "14px",
                  }}>
                  ×
                </Box>,
              ]}>
              <AlertTitle sx={{ fontWeight: 700 }}>
                Dosespot Connection Unresolved.
              </AlertTitle>
              <Box>
                Your ability to send prescriptions to Dosespot will not work
                until the connection is resolved.
              </Box>
            </Alert>
          </Box>
        )} */}
        {children}
      </MainContainer>
      <Navbar />
      {/* <DosepotConnectionModal
        open={showDosepotConnectionModal}
        onClose={() => setShowDosepotConnectionModal(false)}
      /> */}
    </DashboardLayout>
  );
};

export default memo(MainLayout);
