import * as React from "react";
import { Suspense } from "react";
import { $applyNodeReplacement, createEditor, DecoratorNode } from "lexical";

const ImageComponent = React.lazy(
  // @ts-ignore
  () => import("./ImageComponent.js"),
);

export function convertImageElement(domNode) {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, width, height } = domNode;
    const styleString = domNode.getAttribute("style") || "";
    const className = domNode.getAttribute("class") || "";
    const styleObj = {};

    // Convert the inline style string to an object
    styleString.split(";").forEach((rule) => {
      const [property, value] = rule.split(":").map((item) => item.trim());
      if (property && value) {
        // Convert style properties to camelCase if needed
        const camelProperty = property.replace(/-([a-z])/g, (g) =>
          g[1].toUpperCase(),
        );
        styleObj[camelProperty] = value;
      }
    });

    const node = $createImageNode({
      altText,
      height,
      src,
      width,
      style: styleObj,
      className,
    });
    return { node };
  }
  return null;
}

export class ImageNode extends DecoratorNode {
  constructor(src, altText, maxWidth, width, height, style, className, key) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__maxWidth = maxWidth;
    this.__width = width || "123px";
    this.__height = height || "20px";
    this.__style = style || {};
    this.__className = className || "";
  }

  static getType() {
    return "image";
  }

  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__style,
      node.__className,
      node.__key,
    );
  }

  static importJSON(serializedNode) {
    const { altText, height, width, maxWidth, src, style, className } =
      serializedNode;
    const node = $createImageNode({
      altText,
      height,
      maxWidth,
      src,
      width,
      style,
      className,
    });
    return node;
  }

  exportDOM() {
    const element = document.createElement("img");
    element.setAttribute("src", this.__src);
    element.setAttribute("alt", this.__altText);
    element.setAttribute("width", this.__width.toString());
    element.setAttribute("height", this.__height.toString());
    // Apply inline styles from the style object
    Object.assign(element.style, this.__style);
    return { element };
  }

  static importDOM() {
    return {
      img: (node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  exportJSON() {
    return {
      altText: this.getAltText(),
      height: this.__height === "inherit" ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      src: this.getSrc(),
      type: "image",
      version: 1,
      width: this.__width === "inherit" ? 0 : this.__width,
      style: this.__style,
      className: this.__className,
    };
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  // View

  createDOM(config) {
    const span = document.createElement("span");
    const theme = config.theme;
    const className = theme.image;

    // Combine theme className with custom className
    const classNames = [className, this.__className].filter(Boolean).join(" ");

    if (classNames) {
      span.className = classNames;
    }

    Object.assign(span.style, this.__style);
    return span;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          altText={this.__altText}
          width={this.__width}
          height={this.__height}
          maxWidth={this.__maxWidth}
          nodeKey={this.getKey()}
          resizable={true}
          style={this.__style}
          className={this.__className}
        />
      </Suspense>
    );
  }
}

export function $createImageNode({
  altText,
  height,
  maxWidth = 500,
  src,
  width,
  style,
  className,
  key,
}) {
  return $applyNodeReplacement(
    new ImageNode(src, altText, maxWidth, width, height, style, className, key),
  );
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
