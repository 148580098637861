import { CodeNode } from "@lexical/code";

export class CustomDivNode extends CodeNode {
  static getType() {
    return "custom-div-node";
  }

  static clone(node) {
    // If CodeNode expects a language parameter first, pass undefined (or a default language)
    // then pass node.__key as the last argument.
    return new CustomDivNode(undefined, node.__key);
  }

  createDOM(config) {
    const element = document.createElement("div");
    element.className = "custom-div-node";
    return element;
  }

  updateDOM() {
    return false;
  }

  exportJSON() {
    return {
      type: "custom-div-node",
      version: 1,
      children: [],
    };
  }
}

export function $isCustomDivNode(node) {
  return node instanceof CustomDivNode;
}
