import moment from "moment";

// Function to check for null or undefined strings and format them
export const checkNullOrUndefinedString = (str) => {
  if (str == null || str === undefined) {
    return "";
  } else {
    // Ensure str is a string before performing replacements
    str = String(str)
      .replace(/null/gi, "")
      .replace(/undefined/gi, "");

    if (str.length > 1) return str.charAt(0).toUpperCase() + str.slice(1);
    else return null;
  }
};

// Function to calculate age from date of birth using moment.js
export const calculateAge = (dob) => {
  if (!dob) return "";
  return moment().diff(moment(dob), "years");
};

export const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
export const isArrayEmpty = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
};

// This function will transform the flat form data into a nested object.
export function transformToNestedObject(input, initialValues) {
  const output = structuredClone(initialValues);

  for (const key in input) {
    // Split by both [] and . and filter out empty values
    const keys = key.split(/[[\].]+/).filter(Boolean);

    // Start at the root of the default values object.
    let current = output;

    for (let i = 0; i < keys.length; i++) {
      const keyPart = keys[i];
      if (i === keys.length - 1) {
        // If we are at the last key, we can set the value.
        current[keyPart] = input[key];
      } else {
        if (!current[keyPart]) {
          // If the key does not exist, we need to create an object or array depending on the next key. If the next key is a number, we create an array. otherwise, we create an object.
          current[keyPart] = isNaN(keys[i + 1]) ? {} : [];
        }
        // Move to the next key.
        current = current[keyPart];
      }
    }
  }

  return output;
}

export function isFormDirty(dirtyValues) {
  // Iterate over each key-value pair in the dirtyValues object
  for (const [key, value] of Object.entries(dirtyValues)) {
    // Check if the value is an array and has a length greater than zero
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }
    // Check if the value is a string and is not empty
    if (typeof value === "string" && value.trim() !== "") {
      return true;
    }
  }
  // If no dirty values are found, return false
  return false;
}

export const getApiRedirect = (route) => {
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_API_URL) {
      return `${process.env.REACT_APP_API_URL}${route}?next=${window.location.origin}`;
    } else {
      return `http://localhost:8000${route}?next=${window.location.origin}`;
    }
  } else {
    return `${window.location.origin}${route}?next=${window.location.origin}`;
  }
};
