import { apiGet, apiPost, apiPut, apiDelete } from "./base";

export const getFavorites = async (userId) => {
  const response = await apiGet(`/api/users/${userId}/favorites/`);
  return response.data;
};

export const createFavorite = async (userId, favoriteData) => {
  const response = await apiPost(
    `/api/users/${userId}/favorites/`,
    favoriteData,
  );
  return response.data;
};

export const updateFavorite = async (userId, favoriteId, favoriteData) => {
  const response = await apiPut(
    `/api/users/${userId}/favorites/${favoriteId}/`,
    favoriteData,
  );
  return response.data;
};

export const deleteFavorite = async (userId, favoriteId) => {
  const response = await apiDelete(
    `/api/users/${userId}/favorites/${favoriteId}/`,
  );
  return response.data;
};
