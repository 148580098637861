import { useState, useMemo } from "react";
import { Box, useTheme, ClickAwayListener } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { updateRecommendation } from "redux/features/SmartRecommendations/smartRecommendationsSlice";
import FlagOutlined from "@mui/icons-material/FlagOutlined";
import DescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import Description from "@mui/icons-material/Description";
import EditOutlined from "@mui/icons-material/EditOutlined";
import SmartSummaryIcon from "assets/svg/light/ai_sparkle.svg";
import ChronicIcon from "assets/svg/colored/chronic.svg";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { updateSmartRecommendation } from "apiClients/smartRecommendations";
import { Refresh } from "components/common/SVG/Refresh";
import { IconButton } from "components/common/Button/IconButton";
import Text from "components/common/Typography/Text";
import { useParams } from "react-router-dom";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";
import { NoteInput } from "../NoteInput";

const ActionButtons = ({
  isEndVisitView,
  handleButtonClick,
  acceptedRecommendation,
  refusedRecommendation,
  deferredRecommendation,
  rejectedRecommendation,
  resolved,
  theme,
  guideline_url,
  guideline_ref,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const renderEndVisitButtons = () => {
    return (
      <>
        <IconButton
          // Do not allow the provider to click the checkmark if the recommendation is resolved;
          // Would we rather hide the checkmark instead?
          disabled={resolved}
          variant="text"
          onClick={() => handleButtonClick("Accepted")}
          sx={{
            marginLeft: "8px",
            height: "20px",
            width: "20px",
          }}>
          {/* If it's resolved, always show checkmark, not refresh */}
          {acceptedRecommendation && !resolved ? <Refresh /> : <CheckIcon />}
        </IconButton>
        <IconButton
          variant="text"
          width="20px"
          height="20px"
          onClick={() => handleButtonClick("Refused")}
          sx={{
            marginLeft: "8px",
            color: refusedRecommendation
              ? theme.palette.common.grey
              : theme.palette.common.blue,
          }}>
          {refusedRecommendation ? (
            <Refresh />
          ) : (
            <ClearOutlinedIcon
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          )}
        </IconButton>
      </>
    );
  };

  return (
    <Box display="flex" flexDirection="row">
      {isEndVisitView && renderEndVisitButtons()}
      <IconButton
        variant="text"
        width="20px"
        height="20px"
        onClick={() => handleButtonClick("Deferred")}
        sx={{
          marginLeft: "8px",
          color: deferredRecommendation
            ? theme.palette.warning.main
            : theme.palette.common.grey,
        }}>
        {deferredRecommendation ? (
          <Refresh />
        ) : (
          <img
            src={ChronicIcon}
            alt="Chronic Icon"
            style={{
              height: "20px",
            }}
          />
        )}
      </IconButton>
      <IconButton
        variant="text"
        width="20px"
        onClick={() => handleButtonClick("Rejected")}
        height="20px"
        sx={{
          marginLeft: "8px",
          color: theme.palette.common.blue,
        }}>
        {rejectedRecommendation ? (
          <Refresh />
        ) : (
          <FlagOutlined
            sx={{
              height: "20px",
              width: "20px",
            }}
          />
        )}
      </IconButton>
      <CustomTooltip
        tooltipText={
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                minWidth: "300px",
              }}>
              <Text
                variant="subtitle2"
                color="textSecondary"
                sx={{ fontWeight: 700 }}>
                CITATION
              </Text>
              <Text
                variant="body2"
                color="textPrimary"
                whiteSpace="normal"
                sx={{ fontWeight: 700 }}>
                {guideline_ref}
              </Text>
              <Text
                variant="body2"
                sx={{
                  cursor: "pointer",
                  color: theme.palette.common.blue,
                  "&:hover": { opacity: 0.8 },
                  textDecoration: "underline",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(guideline_url, "_blank");
                  setTooltipOpen(false);
                }}>
                See Guideline Document
              </Text>
            </Box>
          </ClickAwayListener>
        }
        clickToOpen={{
          isOpenOnClickEnabled: true,
          closeFn: () => setTooltipOpen(false),
          isOpen: tooltipOpen,
        }}
        placement="bottom-end">
        <IconButton
          variant="text"
          width="20px"
          height="20px"
          onClick={() => setTooltipOpen(!tooltipOpen)}
          sx={{
            marginLeft: "8px",
            marginBottom: "4px",
          }}>
          {tooltipOpen ? (
            <Description
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          ) : (
            <DescriptionOutlined
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          )}
        </IconButton>
      </CustomTooltip>
    </Box>
  );
};

export const SmartRecommendation = ({
  id,
  headline,
  why,
  guideline_url,
  guideline_ref,
  feedback,
  status,
  originalIndex, // Add this to capture the original position
  isEndVisitView = false,
  sx,
  resolved = false,
}) => {
  const { patientId } = useParams();
  const theme = useTheme();

  const REFUSED = "Refused";
  const REJECTED = "Rejected";
  const ACCEPTED = "Accepted";
  const DEFERRED = "Deferred";

  const dispatch = useDispatch();

  // Create a memoized selector for recommendations
  const selectRecommendationState = useMemo(
    () =>
      createSelector(
        [(state) => state.smartRecommendations.recommendationUpdates],
        (recommendationUpdates) => recommendationUpdates[patientId]?.[id],
      ),
    [patientId, id],
  );

  // Get saved recommendation state from Redux using the memoized selector
  const savedState = useSelector(selectRecommendationState);

  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [localFeedback, setLocalFeedback] = useState(
    savedState?.feedback ?? feedback,
  );

  // Set activeButton based on the saved status to ensure proper visual state
  const getInitialActiveButton = () => {
    const savedStatus = savedState?.status;
    if (
      savedStatus === REJECTED ||
      savedStatus === REFUSED ||
      savedStatus === DEFERRED ||
      savedStatus === ACCEPTED
    ) {
      return savedStatus;
    }
    return null;
  };

  const [activeButton, setActiveButton] = useState(getInitialActiveButton());
  const [localStatus, setLocalStatus] = useState(savedState?.status ?? status);

  const submitFeedback = async (action, newFeedback) => {
    setLocalFeedback(newFeedback);
    setLocalStatus(action);
    setShowFeedbackInput(false);
    // If this is a flag (Rejected) or X (Refused), we need to include the full item data
    if (action === REJECTED || action === REFUSED) {
      const fullItem = {
        id,
        headline,
        why,
        guideline_url,
        guideline_ref,
        status: action,
        feedback: newFeedback,
        originalIndex, // Include original position to maintain ordering
      };

      dispatch(
        updateRecommendation({
          patientId,
          recommendationId: id,
          status: action,
          feedback: newFeedback,
          item: fullItem,
        }),
      );
    } else {
      // For other statuses, just update the status and feedback
      dispatch(
        updateRecommendation({
          patientId,
          recommendationId: id,
          status: action,
          feedback: newFeedback,
        }),
      );
    }

    try {
      await updateSmartRecommendation(patientId, id, {
        feedback: newFeedback,
        status: action,
      });
    } catch {}
  };

  const revertFeedback = async () => {
    try {
      await updateSmartRecommendation(patientId, id, {
        feedback: null,
        status: "Not Reviewed",
      });
    } catch {}

    setLocalFeedback(null);
    setLocalStatus("Not Reviewed");

    dispatch(
      updateRecommendation({
        patientId,
        recommendationId: id,
        status: "Not Reviewed",
        feedback: null,
      }),
    );
  };

  const handleButtonClick = async (buttonType) => {
    // When a status comes in such as "Deferred", and there is no active button, then
    // the refresh icon will be shown. On click of te refresh button, we want to
    // reset the status to "Not Reviewed" and clear the feedback.
    if (activeButton === buttonType || localStatus === buttonType) {
      // If clicking the same button that's already active (refresh icon), reset it
      setActiveButton(null);
      setShowFeedbackInput(false);
      //
      if (localFeedback || localStatus === buttonType) {
        setLocalStatus("Not Reviewed");
        await revertFeedback();
      }
    } else if (activeButton !== buttonType) {
      setActiveButton(buttonType);
      setShowFeedbackInput(true);
      setLocalFeedback("");
      // Update status immediately so it persists even if the user navigates away
      setLocalStatus(buttonType);

      // If it's a flag (Rejected) or X (Refused), we need to save all item info to keep it visible
      if (buttonType === REJECTED || buttonType === REFUSED) {
        // Create a full item representation to save
        const fullItem = {
          id,
          headline,
          why,
          guideline_url,
          guideline_ref,
          status: buttonType,
          feedback: "",
          originalIndex, // Include original position to maintain ordering
          // Include any other properties that might be needed
        };

        dispatch(
          updateRecommendation({
            patientId,
            recommendationId: id,
            status: buttonType,
            feedback: "",
            item: fullItem,
          }),
        );
      } else {
        // For other statuses, just save the status update
        dispatch(
          updateRecommendation({
            patientId,
            recommendationId: id,
            status: buttonType,
            feedback: "",
          }),
        );
      }
    } else {
      setActiveButton(null);
      setLocalStatus("Not Reviewed");

      await updateSmartRecommendation(patientId, id, {
        feedback: null,
        status: "Not Reviewed",
      });

      dispatch(
        updateRecommendation({
          patientId,
          recommendationId: id,
          status: "Not Reviewed",
          feedback: null,
        }),
      );
    }
  };

  const handleFeedbackSubmit = (newFeedback) => {
    if (newFeedback?.length > 0) {
      submitFeedback(activeButton, newFeedback);
    }
  };

  const handleFeedbackCancel = async () => {
    try {
      // Only send network request if there was existing feedback
      if (feedback) {
        await revertFeedback();
      } else {
        // For new feedback attempt that was cancelled, revert the status
        if (
          activeButton === REJECTED ||
          activeButton === REFUSED ||
          activeButton === DEFERRED
        ) {
          const originalStatus = status || "Not Reviewed";
          setLocalStatus(originalStatus);

          dispatch(
            updateRecommendation({
              patientId,
              recommendationId: id,
              status: originalStatus,
              feedback: null,
            }),
          );
        }
      }
      // If we are clicking cancel on Flag, we want to reset the active button
      // clicking cancel on Defer, just means to close the input.
      // This is confusing & UX.
      if (activeButton === REJECTED) {
        setActiveButton(null);
      }
      setShowFeedbackInput(false);
    } catch {}
  };

  // Active button takes precedence over status.
  // So that when a deferred recommendation arrives, then the accept button is clicked
  // the recommendation now has a state associated with active.
  const deferredRecommendation =
    (localStatus === DEFERRED && activeButton === null) ||
    activeButton === DEFERRED;

  const acceptedRecommendation =
    (localStatus === ACCEPTED && activeButton === null) ||
    activeButton === ACCEPTED ||
    resolved === true;

  const refusedRecommendation =
    (localStatus === REFUSED && activeButton === null) ||
    activeButton === REFUSED;
  // We do not need to compare status because no recommendation will come in with a rejected status due to
  // our filtering.
  const rejectedRecommendation = activeButton === REJECTED;

  const getTextColor = () => {
    if (refusedRecommendation) return theme.palette.text.secondary;
    if (rejectedRecommendation) return theme.palette.error.main;
    if (deferredRecommendation) return theme.palette.warning.main;
    if (acceptedRecommendation) return theme.palette.success.main;
    return theme.palette.common.purple;
  };

  const recommendationIcon = () => {
    if (deferredRecommendation) {
      return (
        <img
          src={ChronicIcon}
          alt="Chronic Icon"
          style={{ height: "20px", filter: orangeChronicIconFilter }}
        />
      );
    }
    if (refusedRecommendation) {
      return (
        <ClearOutlinedIcon
          sx={{
            color: theme.palette.text.secondary,
            height: "20px",
            width: "20px",
          }}
        />
      );
    }

    if (rejectedRecommendation) {
      return (
        <FlagOutlined
          sx={{
            color: theme.palette.error.main,
            height: "20px",
            width: "20px",
          }}
        />
      );
    }
    if (acceptedRecommendation) {
      return (
        <CheckIcon
          sx={{
            color: theme.palette.success.main,
            height: "20px",
            width: "20px",
          }}
        />
      );
    }
    return (
      <img
        src={SmartSummaryIcon}
        style={{ height: "20px" }}
        alt="Smart Summary Icon"
      />
    );
  };

  const orangeChronicIconFilter =
    "invert(77%) sepia(38%) saturate(1115%) hue-rotate(346deg) brightness(101%) contrast(101%)";

  const showFeedback = () => {
    if (showFeedbackInput) return;
    if (localFeedback) {
      return (
        <Box
          sx={{ margin: "8px 0 0 28px" }}
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Text variant="body2" whiteSpace="normal">
            {localFeedback}
          </Text>
          <IconButton
            variant="text"
            width="20px"
            height="20px"
            sx={{
              marginLeft: "8px",
              color: theme.palette.common.grey,
            }}
            onClick={() => {
              setShowFeedbackInput(true);
            }}>
            <EditOutlined
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Box>
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" {...sx}>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" flexDirection="row" gap={1}>
            {recommendationIcon()}
            <Box display="flex" flexDirection="column">
              <Text
                fontWeight={700}
                variant="body2"
                sx={{ color: getTextColor() }}
                whiteSpace="normal">
                {headline}
              </Text>
              <Text
                fontWeight={500}
                variant="caption"
                color="textSecondary"
                sx={{ marginTop: "4px" }}
                whiteSpace="normal"
                title={why?.reasons?.join(" ")}>
                {why?.reasons?.map((reason, index) => (
                  <span key={index}>
                    {reason}
                    {index < why.reasons.length - 1 ? " " : ""}
                  </span>
                ))}
              </Text>
            </Box>
          </Box>
          <ActionButtons
            isEndVisitView={isEndVisitView}
            handleButtonClick={handleButtonClick}
            acceptedRecommendation={acceptedRecommendation}
            refusedRecommendation={refusedRecommendation}
            deferredRecommendation={deferredRecommendation}
            rejectedRecommendation={rejectedRecommendation}
            resolved={resolved}
            theme={theme}
            guideline_url={guideline_url}
            guideline_ref={guideline_ref}
          />
        </Box>
        {showFeedback()}
        {showFeedbackInput && (
          <NoteInput
            feedback={localFeedback}
            handleFeedbackSubmit={handleFeedbackSubmit}
            handleFeedbackCancel={handleFeedbackCancel}
            showCancelButton={false}
          />
        )}
      </Box>
    </Box>
  );
};
