import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import { IconButton } from "components/common/Button/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import { Form } from "components/common/FormComponents/Form";
import { Trash } from "components/common/SVG/Trash";
import EditIcon from "@mui/icons-material/EditOutlined";
import SelectField from "components/common/FormComponents/SelectField";
import InputField from "components/common/FormComponents/InputField";
import { fetchIcds } from "apiClients/encounters";
import ItemDisplayWrapper from "./ItemDisplayWrapper.js";
import ActionItems from "./ActionItems";
import { NoteInput } from "../NoteInput";
import { EditOutlined } from "@mui/icons-material";

const DiagnosisForm = ({
  index,
  onSubmit,
  onCancel,
  diagnoses,
  aiGenerated = false,
  initialDiagnosis = null,
}) => {
  const theme = useTheme();
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(
    initialDiagnosis
      ? {
          label: initialDiagnosis.condition,
          value: initialDiagnosis.icd_10_code,
          category: initialDiagnosis.category,
        }
      : null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [plan, setPlan] = useState(initialDiagnosis?.plan || "");
  const [status, setStatus] = useState(
    initialDiagnosis
      ? { label: initialDiagnosis.status, value: initialDiagnosis.status }
      : { label: "New", value: "New" },
  );

  const statusOptions = [
    { label: "Unknown", value: "Unknown" },
    { label: "New", value: "New" },
    { label: "Stable", value: "Stable" },
    { label: "Controlled", value: "Controlled" },
    { label: "Uncontrolled", value: "Uncontrolled" },
    { label: "Worsening", value: "Worsening" },
    { label: "Improving", value: "Improving" },
    { label: "Resolved", value: "Resolved" },
  ];

  const handleSearch = async (value) => {
    if (!value) return;
    setIsLoading(true);
    try {
      const results = await fetchIcds({ query: value });
      const mappedResults = results
        .map((item) => ({
          label: item.description,
          value: item.code,
          description: `${item.code} - ${item.description}`,
        }))
        .filter(
          (item) => !diagnoses?.some((d) => d.icd_10_code === item.value),
        );
      setOptions(mappedResults);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const showSubmitButton =
    selectedDiagnosis && status?.value && plan && plan.length > 0;

  const handleSubmit = () => {
    if (!selectedDiagnosis) return;
    onSubmit(
      {
        condition: selectedDiagnosis.label,
        icd_10_code: selectedDiagnosis.value,
        category: selectedDiagnosis?.category
          ? selectedDiagnosis.category
          : "other",
        status: status.value,
        plan: plan,
        canBeDeleted: !aiGenerated,
        aiGenerated,
        index,
      },
      initialDiagnosis,
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ marginTop: "12px", width: "100%" }}>
      <EditIcon
        sx={{ color: theme.palette.common.purple, margin: "4px 4px 0 0" }}
      />
      <Form>
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
          <Box display="flex" flexDirection="row">
            <Box sx={{ marginRight: "8px" }}>
              <SelectField
                placeholder="Search Diagnosis Code - Or - Keyword"
                onSearch={handleSearch}
                options={options}
                loading={isLoading}
                value={selectedDiagnosis}
                onChange={(_, v) => setSelectedDiagnosis(v)}
                openOnFocus={false}
                extendedSearchOptions={["description"]}
                showMoreInLabel={{ show: true, value: "description" }}
                onClear={() => setSelectedDiagnosis(null)}
                sx={{ width: initialDiagnosis ? "322px" : "450px" }}
              />
            </Box>
            {showSubmitButton && (
              <IconButton
                height="40px"
                onClick={handleSubmit}
                sx={{ minWidth: "40px" }}>
                <CheckIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            )}
            {!aiGenerated && (
              <IconButton
                onClick={onCancel}
                sx={{
                  marginLeft: "8px",
                  height: "40px",
                  width: "40px",
                }}>
                <Trash />
              </IconButton>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="start"
            justifyContent="start"
            sx={{ width: "100%", marginTop: "14px" }}>
            {/* TODO - label font should be bigger */}
            <SelectField
              inputLabel={"Status"}
              options={statusOptions}
              value={status}
              onChange={(_, value) => setStatus(value)}
              sx={{ maxWidth: "200px", marginRight: "8px" }}
            />
            <InputField
              inputLabel={"Plan"}
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
              sx={{ width: "350px" }}
            />
          </Box>
        </Box>
      </Form>
      {initialDiagnosis && (
        <ActionItems onEditCancel={onCancel} isEditing={true} theme={theme} />
      )}
    </Box>
  );
};

const DiagnosisDisplay = ({
  diagnosis,
  aiGenerated,
  onEdit,
  onDelete,
  setStatus,
  sx,
}) => {
  return (
    <ItemDisplayWrapper
      sx={sx}
      aiGenerated={aiGenerated}
      onEdit={onEdit}
      showEdit={aiGenerated}
      onDelete={onDelete}
      setStatus={setStatus}
      canBeDeleted={diagnosis?.canBeDeleted}
      title={`${diagnosis?.icd_10_code} - ${diagnosis?.condition}`}>
      <Text
        fontWeight={500}
        variant="caption"
        color="textSecondary"
        sx={{ marginTop: "4px" }}
        whiteSpace="normal">
        Status: {diagnosis.status.split("|")[0].trim()}
      </Text>
      <Text
        fontWeight={500}
        variant="caption"
        color="textSecondary"
        sx={{ marginTop: "4px" }}
        whiteSpace="normal">
        Plan: {diagnosis.plan}
      </Text>
    </ItemDisplayWrapper>
  );
};

export const Diagnosis = ({
  aiGenerated = false,
  setDiagnoses,
  diagnoses,
  onDelete = () => {},
  onAdd,
  onEdit,
  diagnosis,
  index,
  createNewDiagnosis,
  setShowInput = () => {},
  sx,
}) => {
  const theme = useTheme();

  const [note, setNote] = useState(diagnosis?.note || "");
  const [isEditing, setIsEditing] = useState(false);
  const [showNotesInput, setShowNotesInput] = useState(false);

  const handleDelete = () => {
    onDelete("diagnosis_data", diagnosis);
    if (!diagnosis?.aiGenerated) {
      setDiagnoses(
        diagnoses?.filter((d) => d.icd_10_code !== diagnosis?.icd_10_code),
      );
    }
    setShowInput(false);
  };

  const setStatus = (feedback) => {
    // Extract the base status (everything before the first " | ")
    const baseStatus = diagnosis.status.split(" | ")[0];

    // Check if the current feedback is already present
    const currentFeedback = diagnosis.status.includes(` | ${feedback}`);

    // If the current feedback is already there, remove all feedback
    // Otherwise, set the new feedback
    const newStatus = currentFeedback
      ? baseStatus
      : `${baseStatus} | ${feedback}`;
    const updatedDiagnosis = {
      ...diagnosis,
      localStatus: newStatus,
      note: "",
    };
    if (feedback === "Flagged") {
      setShowNotesInput(true);
    } else {
      setNote("");
      setShowNotesInput(false);
    }
    setDiagnoses(
      diagnoses.map((d) =>
        d.icd_10_code === diagnosis.icd_10_code ? updatedDiagnosis : d,
      ),
    );
    onEdit(updatedDiagnosis);
  };

  const showNotes = () => {
    if (showNotesInput) return;
    if (note) {
      return (
        <Box
          sx={{ margin: "8px 0 0 28px" }}
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Text variant="body2" whiteSpace="normal">
            {note}
          </Text>
          <IconButton
            variant="text"
            width="20px"
            height="20px"
            sx={{
              marginLeft: "8px",
              color: theme.palette.common.grey,
            }}
            onClick={() => {
              setShowNotesInput(true);
            }}>
            <EditOutlined
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Box>
      );
    }
  };

  const handleSubmit = (diagnosisData, initialDiagnosis) => {
    if (isEditing) {
      const existingDiagnosisIndex = diagnoses.findIndex(
        (d) => d.icd_10_code === diagnosisData.icd_10_code,
      );

      if (existingDiagnosisIndex !== -1) {
        // If the icd_10_code exists, replace it
        setDiagnoses(
          diagnoses.map((d, index) =>
            index === existingDiagnosisIndex ? diagnosisData : d,
          ),
        );
        onEdit(diagnosisData, true, initialDiagnosis);
      } else if (diagnosisData?.index !== undefined) {
        // If it doesn't exist, replace the diagnosis at the specified index
        setDiagnoses((prevDiagnoses) => {
          const updatedDiagnoses = [...prevDiagnoses];
          updatedDiagnoses[diagnosisData.index] = diagnosisData;
          return updatedDiagnoses;
        });
        onEdit(diagnosisData, true, initialDiagnosis);
      }
    } else {
      setDiagnoses([...(diagnoses || []), diagnosisData]);
      onAdd(true, diagnosisData);
    }
    setIsEditing(false);
    setShowInput(false);
  };

  if (createNewDiagnosis || isEditing) {
    return (
      <DiagnosisForm
        index={index}
        initialDiagnosis={isEditing ? diagnosis : null}
        onSubmit={handleSubmit}
        onCancel={() => {
          setIsEditing(false);
          setShowInput(false);
        }}
        diagnoses={diagnoses}
        aiGenerated={aiGenerated}
      />
    );
  }

  return (
    <>
      <DiagnosisDisplay
        setStatus={setStatus}
        diagnosis={diagnosis}
        aiGenerated={aiGenerated}
        onEdit={() => setIsEditing(true)}
        onDelete={handleDelete}
        sx={sx}
      />
      {showNotes()}
      {showNotesInput && (
        <NoteInput
          feedback={note}
          handleFeedbackSubmit={(newNote) => {
            setShowNotesInput(false);
            setNote(newNote);
            const updatedDiagnosis = {
              ...diagnosis,
              note: newNote,
            };
            setDiagnoses(
              diagnoses.map((d) =>
                d.icd_10_code === diagnosis.icd_10_code ? updatedDiagnosis : d,
              ),
            );
            onEdit(updatedDiagnosis);
          }}
          showCancelButton={false}
        />
      )}
    </>
  );
};
