export let flexRow = {
  display: "flex",
  flexDirection: "row",
};

export let flexColumn = {
  display: "flex",
  flexDirection: "column",
};

export let flexWrap = {
  flexWrap: "wrap",
};

export let alignItemsCenter = { alignItems: "center" };
export let alignItemsFlexStart = { alignItems: "flex-start" };
export let alignItemsFlexEnd = { alignItems: "flex-end" };
export let justifyContentCenter = { justifyContent: "center" };
export let justifyContentFlexEnd = { justifyContent: "flex-end" };
export let justifyContentSpaceBetween = { justifyContent: "space-between" };

export let flexRowCenterCenter = {
  ...flexRow,
  ...alignItemsCenter,
  ...justifyContentCenter,
  zIndex: 10,
};

export let flexColumnCenterCenter = {
  ...flexColumn,
  ...alignItemsCenter,
  ...justifyContentCenter,
};

export let flexRowCenter = {
  ...flexRow,
  ...alignItemsCenter,
};

export let loginFormContainerInner = {
  width: "100%",
  backgroundColor: "#f8f7fe",
  minHeight: "100vh",
  ...flexColumnCenterCenter,
};

export let logoBox = {
  marginBottom: "50px",
};

export let loginForm = {
  padding: "40px 100px",
  maxWidth: "550px",
  height: "400px",
  maxHeight: "400px",
  ...flexColumnCenterCenter,
};

export let forgotPasswordBox = {
  display: "flex",
  ...alignItemsFlexEnd,
  ...justifyContentFlexEnd,
  height: "40px",
};

export const dropzoneStyles = {
  display: "flex",
  flexDirection: "column",
  height: "60px",
  width: "60px",
  justifyContent: "center",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid #cccccc",
  borderRadius: "100%",
  padding: "10px",
  textAlign: "center",
  cursor: "pointer",
};

export const imageStyles = {
  maxWidth: "100%",
  maxHeight: "150px",
  height: "80px",
  width: "80px",
  backgroundSize: "contain",
  objectFit: "cover",
  objectPosition: "50% 0",
  borderRadius: "100%",
};

export const alignPatientIntakeHeadingIconStyle = {
  cursor: "pointer",
  position: "relative",
  top: 2,
};
