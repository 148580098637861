import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getApiRedirect } from "../../../utils/helper";

export const initialAuthStateValue = {
  userId: null,
  isLoading: false,
  firstTimeLogin: false,
  isLogoutLoading: false,
  account_id: null,
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  photo: "",
  designation: "",
  phoneNumber: "",
  title: "",
  dob: "",
  medicalLicenseNumber: "",
  loggedIn: false,
  message: "",
  userWasLoggedIn: false,
  checkLoggedInUser: false,
  isAuthorized: false,
  redirectUrl: "",
  doLogout: false,
  isAuthInProgress: false,
};

export const authName = "auth";

export const loginSlice = createSlice({
  name: "auth",
  initialState: initialAuthStateValue,
  reducers: {
    login: (state, action) => {
      state.isLoading = true;
      state.isAuthInProgress = true;
      state.message = "";
      state.userWasLoggedIn = false;
    },
    getAccountsSuccess: (state, action) => {
      state.account_id = action.payload?.active_account;
      state.username = action.payload?.username;
      state.userId = action.payload?.id;
      state.email = action.payload?.email;
      state.firstName = action.payload?.first_name;
      state.lastName = action.payload?.last_name;
      state.photo = action.payload?.photo;
      state.designation = action.payload?.designation;
      state.phoneNumber = action.payload?.phone_number;
      state.title = action.payload?.title;
      state.dob = action.payload?.birthdate;
      state.medicalLicenseNumber = action.payload?.medical_license_number;
    },
    loginSuccess: (state, action) => {
      // Don't actually mark the user as logged in, just trigger a follow-on call
      // to get the current user, which will keep a similar flow for normal and
      // OIDC login.
      state.checkLoggedInUser = true;
    },
    loginFail: (state, action) => {
      state.isLoading = false;
      state.isAuthInProgress = false;
      state.loggedIn = false;
      state.message = "Username or password is wrong!";
    },
    logout: (state) => {
      state.isLogoutLoading = true;
    },
    logoutSuccess: (state, action) => {
      // Only clear CSRF token, all else is cleared by the root reducer
      Cookies.remove("csrftoken");
    },
    clearUserLoggedIn: (state) => {
      state.userWasLoggedIn = false;
    },
    logoutFail: (state) => {
      state.isLogoutLoading = false;
    },
    clearLoginMessage: (state, action) => {
      state.message = "";
    },
    getCurrentUser: (state, action) => {
      state.loggedIn = false;
      state.isAuthInProgress = true;
      state.userWasLoggedIn = false;
    },
    getCurrentUserSuccess: (state, action) => {
      state.firstTimeLogin = action.payload?.first_login;
      state.loggedIn = true;
      state.userWasLoggedIn = true;
      state.account_id = action.payload?.active_account;
      state.username = action.payload?.username;
      state.userId = action.payload?.id;
      state.email = action.payload?.email;
      state.firstName = action.payload?.first_name;
      state.lastName = action.payload?.last_name;
      state.photo = action.payload?.photo;
      state.designation = action.payload?.designation;
      state.phoneNumber = action.payload?.phone_number;
      state.title = action.payload?.title;
      state.dob = action.payload?.birthdate;
      state.medicalLicenseNumber = action.payload?.medical_license_number;
    },
    getCurrentUserFail: (state, action) => {
      state.loggedIn = false;
      state.isLoading = false;
      state.isAuthInProgress = false;
      state.redirectUrl = getApiRedirect("/api/oidc-login/");
    },
    getAccountsApi: (state, action) => {
      state.isLoading = true;
      state.isAuthInProgress = true;
      state.isAuthorized = false;
    },
    getAccountsApiFail: (state, action) => {
      state.isLoading = false;
      state.isAuthInProgress = false;
      state.isAuthorized = false;
    },
    getAccountsApiSuccess: (state, action) => {
      state.isAuthorized = true;
      state.isLoading = false;
      state.isAuthInProgress = false;
    },
    logoutOidc: (state) => {
      // Only clear CSRF token, all else is cleared by the root reducer
      Cookies.remove("csrftoken");

      // Redirect to the OIDC logout endpoint
      window.location.assign(getApiRedirect("/api/oidc-logout/"));
    },
    setCheckLoggedInUser: (state, action) => {
      state.checkLoggedInUser = action.payload;
    },
    clearRedirectUrl: (state) => {
      state.redirectUrl = "";
    },
  },
});

export const {
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  clearLoginMessage,
  clearUserLoggedIn,
  logoutOidc,
  setCheckLoggedInUser,
  clearRedirectUrl,
} = loginSlice.actions;

export const selectUser = (state) => state.auth;

export default loginSlice.reducer;

export const onLogin = (data, remainLogin = false) => ({
  payload: {
    apiName: authName,
    data,
    onSuccess: loginSuccess,
    method: "post",
    url: "/api/api-login",
    rememberMe: remainLogin,
    skipLogout: true,
  },
  type: "login",
});

export const getAccountID = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/users/current/`,
  },
  type: "getAccounts",
});

export const logoutApi = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/api-logout/`,
    skipLogout: true,
  },
  type: "logout",
});

export const getCurrentUser = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/users/current/`,
    skipLogout: true,
  },
  type: "getCurrentUser",
});

// API call to obtain a list of accounts available to the user, or will return 403
// if the user is not authorized to view any accounts. This is used in the login
// flow to determine if the user is authorized to use the application.
export const getAccountsApi = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/accounts/`,
    skipLogout: true,
  },
  type: "getAccountsApi",
});
