import { Typography } from "@mui/material";
import { createCustomTheme } from "styles/theming/theme";

const Text = ({
  id,
  children,
  classes,
  variant = "h2",
  center = false,
  color,
  marginY = 0,
  whiteSpace = "nowrap",
  sx = {},
  title = children,
  ...props
}) => {
  const theme = createCustomTheme();

  const colors = {
    blue: theme?.palette?.themeBlue?.primary,
    purple: theme?.palette?.common?.purple,
    lightBlue: theme?.palette?.common?.blue,
    oceanBlue: "#315FFF",
    lightGreen: "#5EC4B2",
    orange: "#FF9314",
    warning: "#ED6C02",
    textSecondary: theme?.palette?.text?.secondary,
    textPrimary: theme?.palette?.text?.primary,
    grey: theme?.palette?.text?.secondary,
    white: theme?.palette?.common?.white,
    black: theme?.palette?.common?.black,
    red: theme.palette.error.main,
    redLight: theme.palette.error.light,
    green: theme.palette.common.green,
  };

  const textColor = colors[color] || "unset";

  return (
    <Typography
      data-testid={id}
      variant={variant}
      align={center ? "center" : undefined}
      className={classes}
      sx={{
        display: "inline-block",
        color: textColor,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace,
        maxWidth: "100%",
        ...sx,
      }}
      {...props}
      my={marginY}
      title={title}>
      {children}
    </Typography>
  );
};

export default Text;
