import React, { useState } from "react";
import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";
import { IconButton } from "components/common/Button/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/EditOutlined";
import ItemDisplayWrapper from "./ItemDisplayWrapper.js";
import InputField from "components/common/FormComponents/InputField.js";
import AddMedication from "../Medications/AddMedication";
import { getDosageValue } from "utils/prescriptions";

const PrescriptionDisplay = ({
  prescription,
  onEdit,
  onDelete,
  setStatus,
  showStatus = false,
  note,
  sx,
  setShowNotesInput,
}) => {
  const aiGenerated = prescription?.aiGenerated;

  const name = prescription?.medication || prescription?.drug_name;
  const instructions = prescription?.instructions;

  const dosageQuantityValue = prescription?.dosage_quantity_value;

  const instructionsMargin = dosageQuantityValue
    ? "4px 0 0px 16px"
    : "4px 0 0px 0";

  return (
    <ItemDisplayWrapper
      sx={sx}
      aiGenerated={aiGenerated}
      onEdit={onEdit}
      showEdit={true}
      showFavorite={false}
      onDelete={onDelete}
      setStatus={setStatus}
      canBeDeleted={prescription?.canBeDeleted}
      title={name}
      status={prescription?.status}>
      <Box
        display="flex"
        flexDirection="row"
        sx={{ marginTop: "4px", marginBottom: "8px" }}>
        {dosageQuantityValue && (
          <Box>
            <Text
              fontWeight={500}
              variant="caption"
              color="textSecondary"
              whiteSpace="normal">
              Amt: {dosageQuantityValue}
            </Text>
            <Text
              sx={{ marginLeft: "24px" }}
              fontWeight={500}
              variant="caption"
              color="textSecondary"
              whiteSpace="normal">
              {getDosageValue(prescription)?.label
                ? `${getDosageValue(prescription).label}`
                : ""}
            </Text>
          </Box>
        )}
        <Box>
          <Text
            fontWeight={500}
            variant="caption"
            color="textSecondary"
            sx={{ margin: instructionsMargin }}
            whiteSpace="normal">
            {instructions}
          </Text>
        </Box>
      </Box>
      {note && showStatus && (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Text
            fontWeight={500}
            variant="caption"
            color="textSecondary"
            sx={{ marginTop: "4px" }}
            whiteSpace="normal">
            {note}
          </Text>
          <Box
            onClick={() => setShowNotesInput(true)}
            sx={{
              marginLeft: "8px",
              height: "10px",
              width: "10px",
            }}>
            <EditIcon sx={{ height: "20px", width: "20px" }} />
          </Box>
        </Box>
      )}
    </ItemDisplayWrapper>
  );
};

export const Prescription = ({
  setPrescriptions,
  prescriptions,
  onDelete = () => {},
  prescription,
  createNewPrescription,
  setShowInput = () => {},
  sx,
  editNote,
  onAdd,
}) => {
  const aiGenerated = prescription?.aiGenerated;
  const lookupKey = prescription?.medication || prescription?.drug_name;

  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState(prescription?.note || "");
  const [showNotesInput, setShowNotesInput] = useState(false);

  const handleDelete = () => {
    onDelete(prescription.id);
    setPrescriptions(prescriptions?.filter((d) => d.id !== prescription.id));
    setShowInput(false);
  };

  const setStatus = (feedback) => {
    if (feedback === "Flagged") setShowNotesInput(true);
    else {
      setShowNotesInput(false);
      setNote("");
    }

    const updatedPrescription = {
      ...prescription,
      note: "",
      status: feedback,
    };

    setPrescriptions(
      prescriptions.map((d) => {
        if (d.medication === lookupKey || d.drug_name === lookupKey) {
          return updatedPrescription;
        }
        return d;
      }),
    );
    if (aiGenerated) {
      editNote(lookupKey, null, feedback);
    }
  };

  if (createNewPrescription || isEditing) {
    const handleSubmit = (perscriptionData) => {
      const isValid =
        (perscriptionData?.dosage_quantity_value &&
          perscriptionData?.drug_name &&
          perscriptionData?.dosage_period &&
          perscriptionData?.dosage_period_unit) ||
        (perscriptionData?.drug_name && perscriptionData?.instructions);

      if (isValid) {
        const updatedPrescription = {
          ...perscriptionData,
          canBeDeleted: !aiGenerated,
          aiGenerated,
          status: "Accepted",
        };

        if (isEditing) {
          setPrescriptions(
            prescriptions.map((d) => {
              if (d.medication === prescription.medication) {
                return updatedPrescription;
              }
              return d;
            }),
          );
          onAdd(updatedPrescription, prescription.medication);
        } else {
          setPrescriptions([...(prescriptions || []), updatedPrescription]);
          onAdd(updatedPrescription);
        }
        setShowInput(false);
        setIsEditing(false);
      }
    };

    let medicationData = prescription;

    if (aiGenerated && isEditing) {
      medicationData = {
        id: prescription.id,
        drug_name: prescription.medication ?? prescription.drug_name,
        instructions: prescription.instructions,
        dosage_quantity_value: prescription.dosage_quantity_value,
        dosage_period: prescription.dosage_period,
        dosage_period_unit: prescription.dosage_period_unit,
      };
      return (
        <AddMedication
          forceStatus={"active"}
          medicationData={medicationData}
          interimPageView={true}
          setIsOpenModal={(show) => {
            setShowInput(show);
            if (!show) setIsEditing(false);
          }}
          onSubmit={handleSubmit}
        />
      );
    }
    return (
      <AddMedication
        forceStatus={"active"}
        medicationData={prescription}
        interimPageView={true}
        setIsOpenModal={(show) => {
          setShowInput(show);
          if (!show) setIsEditing(false);
        }}
        onSubmit={handleSubmit}
      />
    );
  }

  const handleEditNote = (newNote) => {
    const updatedPrescription = {
      ...prescription,
      note: newNote,
      status: "Flagged",
    };

    setPrescriptions(
      prescriptions.map((d) => {
        if (d.medication === lookupKey || d.drug_name === lookupKey) {
          return updatedPrescription;
        }
        return d;
      }),
    );
    editNote(lookupKey, newNote, "Flagged");
  };

  return (
    <>
      <PrescriptionDisplay
        sx={sx}
        setStatus={setStatus}
        prescription={prescription}
        onDelete={handleDelete}
        note={note}
        onEdit={() => setIsEditing(true)}
        showStatus={!showNotesInput}
        setShowNotesInput={setShowNotesInput}
      />

      {showNotesInput && (
        <Box sx={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
          <InputField
            defaultValue={note}
            sx={{ width: "100%" }}
            inputLabel={"Enter note"}
            onChange={(e) => setNote(e.target.value)}
          />
          <IconButton
            height="40px"
            onClick={() => {
              setShowNotesInput(false);
              handleEditNote(note);
            }}
            sx={{ minWidth: "40px", marginLeft: "8px" }}>
            <CheckIcon sx={{ height: "20px", width: "20px" }} />
          </IconButton>
        </Box>
      )}
    </>
  );
};
