import { apiGet, apiPatch } from "./base";

export const fetchSmartRecommendations = async (patientId, pageParam) => {
  const url = pageParam || `/api/patients/${patientId}/smart-recommendations/`;
  const response = await apiGet(url);
  return response.data;
};

export const updateSmartRecommendation = async (
  patientId,
  recommendationId,
  data,
) => {
  const url = `/api/patients/${patientId}/smart-recommendations/${recommendationId}/`;
  const response = await apiPatch(url, data);
  return response.data;
};
