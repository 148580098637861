import { Box, Grid } from "@mui/material";
import * as toast from "hooks/notify";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import { HealthSummary } from "./components/HealthSummary";
import Medications from "./components/Medications/Medications";
import { scdBoxStyling } from "styles/SCD/healthSummaryStyle";
import {
  clearAllHistoryData,
  getAllHistoryList,
} from "redux/features/AllHistories/AllHistorySlice";
import {
  clearAllergiesData,
  getAllergiesByPatientId,
} from "redux/features/Allergies/AllergiesSlice";
import SmartSummaryIcon from "../../../assets/svg/light/ai_sparkle.svg";
import {
  clearEncounterData,
  createOrUpdateEncounter,
  getEncounterByAppointment,
  getEncounterById,
} from "redux/features/Encounter/EncounterSlice";
import { getFamilyHistoryList } from "redux/features/FamilyHistory/FamilyHistorySlice";
import { clearMedicationsData } from "redux/features/Medications/MedicationsSlice";
import { clearMedicinesData } from "redux/features/Medications/MedicinesSlice";
import { clearAllOrderMedicationsStates } from "redux/features/Medications/OrderMedicationsSlice";
import {
  clearLabsResultsMessage,
  getLabsResults,
} from "redux/features/Patients/ViewLabResultsSlice";
import {
  clearSpecificPatientData,
  getSpecificPatientDetails,
} from "redux/features/Patients/getSpecificPatientsSlice";
import { clearVitalsData } from "redux/features/Vitals/VitalsSlice";
import VitalLabMedWrapper from "./components/VitalLabMedWrapper";
import SCDHeader from "./components/SCDHeader";
import { PastVisit } from "./components/PastVisit/PastVisit";
import {
  planBoxStylingWrapper,
  planBoxTitleStyling,
} from "styles/SCD/planStyles";
import Text from "components/common/Typography/Text";
import { clearLabsList } from "redux/features/Labs/labsSlice";
import { clearPharmacyData } from "redux/features/Pharmacy/PharmacySlice";
import { useFeedback } from "hooks/useFeedback";
import { useQueryClient } from "@tanstack/react-query";
import { PatientDetailWrapper } from "components/common/PatientDetailWrapper";
import { IntakeFormProvider } from "./PatientIntakeForm/common/IntakeFormProvider";
import { PatientIntakeForm } from "./PatientIntakeForm/PatientIntakeForm";
import { setCurrentNavigationPath } from "redux/features/globalStateSlice";
import { PatientDocuments } from "./components/documents";
import EndVisitStepper from "./components/EndVisit/EndVisitStepper";
import LabResultDetail from "./components/LabResults/LabResultDetail";
import { SmartRecommendations } from "./components/SmartRecommendations/SmartRecommendations";
import {
  smartRecommendationsFeature,
  isFeatureEnabled,
} from "utils/featureFlags";
import { FeatureFlagsContext } from "../../../contexts/FeatureFlagsContext";
const PatientDetail = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  let { patientId, appointmentId } = useParams();
  const [isPostCalled, setIsPostCalled] = useState(null);
  const [scdContent, setScdContent] = useState({});
  const [componentName, setComponentName] = useState(null);
  const [startAppointment, setStartAppointment] = useState(false);
  const [isClosingEncounter, setIsClosingEncounter] = useState(false);
  const [otherEncounter, setOtherEncounter] = useState({});
  const [smartSummaryfeedback, setsmartSummaryFeedback] = useState("");
  const [
    updateSmartSummaryOnIntakeChange,
    setUpdateSmartSummaryOnIntakeChange,
  ] = useState(false);
  const scdRef = useRef();
  const markdownContentRef = useRef();
  const [smartDataRefreshing, setSmartDataRefreshing] = useState(false);

  const viewWidth = window.innerWidth;
  //get query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encounter_id = queryParams.get("encounter_id");
  const reviewLater = queryParams.get("review_later");

  // feature flags
  const flagsData = useContext(FeatureFlagsContext);

  // encounter request response
  const { encounter, encounterSuccess, encounterMessage } = useSelector(
    (state) => state.encounter,
  );

  const { account_id, userId } = useSelector((state) => state.auth);

  const { activeLocation: { value: activeLocationId } = {} } = useSelector(
    (state) => state.locations,
  );

  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient,
  );

  const { feedbackList } = useFeedback(
    patientId,
    otherEncounter?.id || encounter?.id,
  );

  const findLatestFeedback = useCallback(
    (feedbackArray, itemType) => {
      // Smart summary is associated with a patient and endnote is associated with an encounter
      const itemId =
        itemType === "smart.summary"
          ? patientId
          : otherEncounter?.id
            ? otherEncounter?.id
            : encounter?.id;
      return feedbackArray
        .filter(
          (item) =>
            item.item_for_feedback === itemType &&
            Number(item.item_id) === Number(itemId),
        )
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
    },
    [encounter, otherEncounter, patientId],
  );

  useEffect(() => {
    if (feedbackList?.length) {
      const findSmartSummaryFeedback = findLatestFeedback(
        feedbackList,
        "smart.summary",
      );
      setsmartSummaryFeedback(findSmartSummaryFeedback);
    }
  }, [feedbackList, patientId, otherEncounter, encounter, findLatestFeedback]);

  // Toast pop up on encounter response
  useEffect(() => {
    if (encounterSuccess === false) {
      toast.error(encounterMessage);
    }
  }, [encounterMessage, encounterSuccess]);

  useEffect(() => {
    // Api hit for labs data
    dispatch(getLabsResults(patientId));

    // Api hit for familyhistory for specific patient
    dispatch(getFamilyHistoryList(patientId));

    // Api hit for specific patient
    dispatch(getSpecificPatientDetails(patientId));

    // Api hit for allergies by patient id
    dispatch(getAllergiesByPatientId(patientId));

    // Api hit for all histories by patient id
    dispatch(getAllHistoryList(patientId));

    appointmentId && dispatch(getEncounterByAppointment(appointmentId));
  }, [appointmentId, dispatch, patientId]);

  useEffect(() => {
    encounter?.sign_close_ind === "c" && setStartAppointment(false);
  }, [encounter]);

  useEffect(() => {
    return () => {
      // Clear vitals data
      dispatch(clearVitalsData());

      // clear labs data
      dispatch(clearLabsResultsMessage());

      dispatch(setCurrentNavigationPath(null));

      // Clear patient data from global state
      dispatch(clearSpecificPatientData());

      // Clear all history data from global state
      dispatch(clearAllHistoryData());

      // Clear all medicines data
      dispatch(clearMedicinesData());

      // Clear encounter data from global state
      dispatch(clearEncounterData());

      // Clear encounter data by appointment
      dispatch(clearAllergiesData());

      // Clear all medications data
      dispatch(clearMedicationsData());

      //clear pharmacy data
      dispatch(clearPharmacyData());

      //clear orderMedication/prescriptions data
      dispatch(clearAllOrderMedicationsStates());

      queryClient.removeQueries(["encountersByPatient", patientId]);

      // clear labs list and stop current api request
      dispatch(clearLabsList());
    };
  }, [dispatch, patientId, queryClient]);

  // To send the encounter after validations
  function sendEncounter(plan_note, endNoteHtml, signCloseIn, encounterObj) {
    let encounterData = {
      ...encounterObj,
      patient_id: patientId,
      location_id: activeLocationId,
      appointment_id: appointmentId ?? null,
      // icds: [], // we are not sure yet whether we need icds for the mvp or not...
      // cpts: [],
      datetime: new Date().toISOString(),
      // followup_date: null,
      account_id: account_id,
      // followup_selection: "",
      sign_close_ind: signCloseIn,
      plan_note,
      end_notes_html: endNoteHtml,
    };

    delete encounterData["soap_notes"];

    // Clear encounter data from global state
    dispatch(clearEncounterData());

    // create or update encounter on the basis of data and validations
    dispatch(
      createOrUpdateEncounter(
        encounterData,
        encounterData?.id ? "PUT" : "POST",
        encounterData?.id,
      ),
    );
  }

  const close = useCallback(
    (refresh = false) => {
      if (componentName === "patientIntake") {
        setUpdateSmartSummaryOnIntakeChange(refresh);
      }
      dispatch(setCurrentNavigationPath(null));
      setComponentName(null);
      setOtherEncounter({});
    },
    [componentName, dispatch],
  );

  function onStartAppointment() {
    //encounter_id is the query param that we are getting from the url
    setStartAppointment(true);
    if (!encounter_id) {
      dispatch(
        createOrUpdateEncounter(
          {
            patient_id: patientId,
            location_id: activeLocationId,
            user_id: userId,
            datetime: new Date().toISOString(),
            account_id: account_id,
            sign_close_ind: "o",
          },
          "POST",
          null,
        ),
      );
    }
  }

  const healthMedTabsArray = [
    {
      titleDetails: { title: "Smart Summary" },
      tab: (
        <HealthSummary
          smartDataRefreshing={smartDataRefreshing}
          setSmartDataRefreshing={setSmartDataRefreshing}
          updateSmartSummaryOnIntakeChange={updateSmartSummaryOnIntakeChange}
          smartSummaryfeedback={smartSummaryfeedback}
          setUpdateSmartSummaryOnIntakeChange={
            setUpdateSmartSummaryOnIntakeChange
          }
          setsmartSummaryFeedback={setsmartSummaryFeedback}
        />
      ),
      tabIcon: true,
      tabIconSrc: SmartSummaryIcon,
      left: true,
    },
    {
      titleDetails: { title: "Medications" },
      tab: <Medications />,
    },
    {
      titleDetails: { title: "Documents" },
      tab: <PatientDocuments />,
    },
  ];

  useEffect(() => {
    encounter_id && dispatch(getEncounterById(encounter_id));
  }, [dispatch, encounter_id]);

  // open the modal if the encounter is in review later state
  // and the user is not in the middle of recording
  // and we have the reviewLater query param
  useEffect(() => {
    reviewLater &&
      encounter?.smart_note_data !== null &&
      setComponentName("endVisit");
  }, [encounter, reviewLater]);

  useEffect(() => {
    if (encounterSuccess && isClosingEncounter) {
      queryClient.invalidateQueries({
        queryKey: ["encountersByPatient", patientId],
      });
      setComponentName(null);
      setStartAppointment(false);
      setOtherEncounter({});
      dispatch(clearEncounterData());
      setIsClosingEncounter(false);
      dispatch(getEncounterById(encounter_id || encounter?.id));
    }
  }, [
    dispatch,
    encounter,
    encounter_id,
    patientId,
    queryClient,
    isClosingEncounter,
    encounterSuccess,
  ]);

  useEffect(() => {
    if (componentName === "endVisit")
      dispatch(setCurrentNavigationPath("Finalize Assessment & Plan Note"));
    if (componentName) {
      // Using switch-case to determine scdContent based on componentName
      switch (componentName) {
        case "pastVisits":
          dispatch(setCurrentNavigationPath("Past Visits"));
          setSmartDataRefreshing(false);
          setScdContent({
            body: (
              <PastVisit
                setOtherEncounter={setOtherEncounter}
                setComponentName={setComponentName}
                onClose={close}
              />
            ),
          });
          break;
        case "previousLabs":
          dispatch(setCurrentNavigationPath("Previous Labs"));
          setScdContent({
            body: (
              <LabResultDetail
                setComponentName={setComponentName}
                patientName={
                  (getSpecificPatientData?.first_name
                    ? getSpecificPatientData?.first_name
                    : "") +
                  " " +
                  (getSpecificPatientData?.last_name
                    ? getSpecificPatientData?.last_name
                    : "")
                }
              />
            ),
          });
          break;
        case "patientIntake":
          dispatch(setCurrentNavigationPath("Patient Intake"));
          setScdContent({
            body: (
              <IntakeFormProvider patientId={patientId}>
                <PatientIntakeForm onClose={close} />
              </IntakeFormProvider>
            ),
          });
          break;
        default:
          setScdContent(null);
          break;
      }
    }
  }, [close, dispatch, getSpecificPatientData, patientId, componentName]);

  useEffect(() => {
    if (location?.state?.intakeFlag === true) {
      setComponentName("patientIntake");
    }
  }, [location.state]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item lg={12} xl={12} xxl={12} md={12} sm={12} xs={12}>
          <SCDHeader
            ref={scdRef}
            setIsPostCalled={setIsPostCalled}
            patientId={patientId}
            setStartAppointment={onStartAppointment}
            startAppointment={startAppointment}
            setScdContent={setScdContent}
            setComponentName={setComponentName}
            componentName={componentName}
          />
          {componentName !== null ? (
            componentName !== "endVisit" ? (
              <PatientDetailWrapper>{scdContent?.body}</PatientDetailWrapper>
            ) : (
              <EndVisitStepper
                ref={markdownContentRef}
                isPostCalled={isPostCalled}
                sendEncounter={sendEncounter}
                setComponentName={setComponentName}
                encounter={otherEncounter?.id ? otherEncounter : encounter}
              />
            )
          ) : (
            <Box sx={{ marginTop: "23px", height: "calc(100vh - 150px)" }}>
              <Grid container spacing={2} sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  className="healthsummary_wrapper"
                  sx={{ height: "95vh" }}>
                  <Box
                    sx={{
                      ...scdBoxStyling(),
                      height: "100%",
                      zIndex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    data-testid="scd-health-summary-box-id">
                    <HorizontalTabs
                      childrenArray={healthMedTabsArray}
                      borderTab={true}
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  data-testid="scd-vital-labs-box-id"
                  className="vital-lab-med-wrapper"
                  sx={{ height: "95vh" }}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}>
                    <Box
                      sx={{
                        ...scdBoxStyling(),
                        flex: "1 1 50%",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <VitalLabMedWrapper setComponentName={setComponentName} />
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                      }}
                      data-testid="scd-plan-box-id">
                      <Box
                        sx={{
                          ...planBoxStylingWrapper,
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <Box
                          sx={planBoxTitleStyling(viewWidth)}
                          data-testid={"assesment-and-plan-heading-id"}>
                          <Box>
                            <img
                              src={SmartSummaryIcon}
                              alt="Tab Icon"
                              style={{ marginRight: "5px" }}
                            />
                          </Box>
                          <Text variant="h3">Smart Recommendations</Text>
                        </Box>
                        <Box>
                          {isFeatureEnabled(
                            flagsData,
                            smartRecommendationsFeature,
                          ) ? (
                            <SmartRecommendations
                              loading={
                                smartDataRefreshing ||
                                updateSmartSummaryOnIntakeChange
                              }
                              sx={{
                                maxHeight: {
                                  lg: "26vh",
                                },
                              }}
                              childSx={{ marginBottom: "12px" }}
                            />
                          ) : (
                            <>Coming Soon</>
                          )}
                        </Box>
                      </Box>
                      {encounter?.sign_close_ind === "c" && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgb(253 250 250 / 50%)",
                            zIndex: 1000,
                            pointerEvents: "auto",
                            cursor: "not-allowed",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientDetail;
