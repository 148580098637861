import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { CssBaseline } from "@mui/material";
import MainLayoutContext from "contexts/MainLayoutContext";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "routes/Router";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getFlagsApi } from "redux/features/Flags/FlagsSlice";

function App() {
  const [title, setTitle] = useState("");
  const [titleBody, setTitleBody] = useState("");
  const queryClient = new QueryClient();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFlagsApi());
  }, [dispatch]);

  return (
    <MainLayoutContext.Provider
      value={{
        title: title,
        setTitle: setTitle,
        titleBody: titleBody,
        setTitleBody: setTitleBody,
      }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CssBaseline />
          <Router />
        </BrowserRouter>
      </QueryClientProvider>
    </MainLayoutContext.Provider>
  );
}

export default App;
