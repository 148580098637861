import { RecentMedicalEncounters } from "./sections/RecentMedicalEncounters";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { MedicationsReview } from "./sections/MedicationsReview";
import { AllergyReview } from "./sections/AllergyReview";
import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import { IntakeFormContext } from "./common/IntakeFormProvider";
import { getDirtyValues } from "./common/utils";
import Text from "components/common/Typography/Text";
import { PharmacyReview } from "./sections/PharmacyReview";
import { Form } from "components/common/FormComponents/Form";
import { PatientDetailHeader } from "components/common/PatientDetailWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { leftArrow } from "assets/svg/leftArrow";
import { ReasonForVisit } from "./sections/ReasonForVisitReview";
import { DocsReview } from "./sections/DocsReview";
import { ImagingReview } from "./sections/ImagingReview";
import { LabsReview } from "./sections/labReview";
import { SaveConfirmationModal } from "./sections/SaveConfirmationModal";
import { useNavigationBlocker } from "hooks/useNavigationBlocker";
import { isFormDirty, transformToNestedObject } from "utils/helper";
import { InsuranceReview } from "./sections/InsuranceReview";

export const PatientIntakeForm = ({ onClose = () => {} }) => {
  const initialFormValues = {
    pharmacies: [],
    medicalEncounters: [],
    imagingReview: [],
    reasonForVisit: "",
    medications: [],
    allergies: [],
    labs: [],
    medicalReports: [],
    docsReview: [],
    insurance: [],
    consent: {},
  };
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { initialValues, handlers, mappers, patientId } =
    useContext(IntakeFormContext);
  const location = useLocation();
  const navigate = useNavigate();
  const intakeRef = useRef();
  const sections = [
    {
      id: "reason-for-visit",
      title: "Reason For Visit",
      component: ReasonForVisit,
      key: "reasonForVisit",
    },
    {
      id: "medical-encounters",
      title: "Encounters",
      component: RecentMedicalEncounters,
      key: "medicalEncounters",
    },
    {
      id: "medications-review",
      title: "Medications",
      component: MedicationsReview,
      key: "medications",
    },
    {
      id: "allergy-review",
      title: "Allergies",
      component: AllergyReview,
      key: "allergies",
    },
    {
      id: "labs-review",
      title: "Labs",
      component: LabsReview,
      key: "labs",
    },
    {
      id: "imaging-review",
      title: "Imaging",
      component: ImagingReview,
      key: "imagingReview",
    },
    {
      id: "documents-review",
      title: "Documents",
      component: DocsReview,
      key: "docsReview",
    },
    {
      id: "pharmacy-review",
      title: "Pharmacy",
      component: PharmacyReview,
      key: "pharmacies",
    },
    {
      id: "insurance-review",
      title: "Insurance",
      component: InsuranceReview,
      key: "insurance",
    },
  ];
  const [isSaving, setIsSaving] = useState(false);

  const checkDirtyValidity = () => {
    if (isSaving) return false;
    const formValues = getFormObject();
    const dirtyValues = getDirtyValues(formValues, initialValues, mappers);
    return isFormDirty(dirtyValues);
  };

  const { showComponent, confirmNavigation, cancelNavigation } =
    useNavigationBlocker({
      isSaving,
      when: isSaving ? false : checkDirtyValidity,
    });

  function getFormObject() {
    const formData = new FormData(intakeRef?.current);
    const formValues = Object.fromEntries(formData.entries());
    return transformToNestedObject(formValues, initialValues);
  }

  function close(refresh = false, isSavingForm = false) {
    const formValues = getFormObject();
    const dirtyValues = getDirtyValues(formValues, initialValues, mappers);
    const isDirty = isFormDirty(dirtyValues);
    if (isDirty && !isSavingForm) {
      setOpen(true);
    } else {
      onClose(refresh);
      setErrors({});
    }
  }

  const intakeFlag = location.state?.intakeFlag || false;
  const comingFrom = location.state?.from;

  async function handleSubmit(values) {
    setIsSaving(true);
    const errorMap = {};
    const dirtyValues = getDirtyValues(values, initialValues, mappers);
    setLoading(true);
    setErrors({});
    for (const [key, value] of Object.entries(dirtyValues)) {
      if (handlers[key]) {
        const results = await handlers[key](value);
        const errors = results?.filter(
          (result) => result.status === "rejected",
        );
        if (errors?.length) {
          errorMap[key] = errors;
        }
      }
    }

    const reasonForVisitResults = await handlers["reasonForVisit"](
      values?.reasonForVisit,
    );
    const reasonForVisitErrors = reasonForVisitResults?.filter(
      (result) => result.status === "rejected",
    );

    if (reasonForVisitErrors.length) {
      errorMap["reasonForVisit"] = reasonForVisitErrors;
    }

    setLoading(false);
    if (Object.keys(errorMap).length === 0) {
      if (intakeFlag) {
        navigate(comingFrom ?? "/patients");
      } else {
        close(true, true);
      }
    } else {
      setErrors(errorMap);
    }
    setIsSaving(false);
  }

  const backToPatientList = () => {
    const formValues = getFormObject();
    const dirtyValues = getDirtyValues(formValues, initialValues, mappers);
    const isDirty = isFormDirty(dirtyValues);
    if (isDirty) {
      setOpen(true);
    } else {
      navigate(comingFrom ? "/appointments" : "/patients");
    }
  };

  const sectionRefs = {
    "reason-for-visit": useRef(null),
    "medical-encounters": useRef(null),
    "medications-review": useRef(null),
    "allergy-review": useRef(null),
    "labs-review": useRef(null),
    "imaging-review": useRef(null),
    "documents-review": useRef(null),
    "pharmacy-review": useRef(null),
    "insurance-review": useRef(null),
  };

  return (
    <>
      <SaveConfirmationModal
        open={showComponent}
        onConfirm={confirmNavigation}
        onClose={cancelNavigation}
      />
      <SaveConfirmationModal
        open={open}
        onConfirm={() => onClose()}
        onClose={setOpen}
      />
      <Form
        intakeRef={intakeRef}
        onSubmit={handleSubmit}
        initialValues={initialFormValues}>
        <>
          <PatientDetailHeader title="Patient Intake Form">
            {intakeFlag ? (
              <Button
                onClick={backToPatientList}
                variant="outlined"
                text={
                  comingFrom
                    ? "Back To All Apppointments"
                    : "Back To All Patients"
                }
                leftSide={true}
                iconDetails={{
                  allowIcon: true,
                  icon: leftArrow,
                }}
                sx={{
                  minWidth: "100px",
                  marginRight: "15px",
                }}
              />
            ) : (
              <Button
                onClick={() => close(false)}
                variant="outlined"
                text="cancel"
                sx={{
                  width: "105px",
                  minWidth: "100px",
                  marginRight: "15px",
                }}
              />
            )}
            <Button
              variant={"contained"}
              isLoading={loading}
              text="Save"
              type="submit"
              sx={{
                width: "105px",
                minWidth: "100px",
              }}
            />
          </PatientDetailHeader>
          <Grid container>
            <Grid item xs={4} md={3}>
              <ReviewScroller sections={sections} sectionRefs={sectionRefs} />
            </Grid>
            <Grid
              item
              xs={8}
              md={9}
              sx={{ maxHeight: "65vh", overflow: "auto" }}>
              <ol>
                {sections.map((section, index) => {
                  const Component = section.component;
                  return (
                    <Fragment key={index}>
                      <li
                        id={section.id}
                        style={{
                          fontSize: "24px",
                          maxWidth: "98%",
                          listStyleType: "none",
                        }}
                        ref={sectionRefs[section.id]}>
                        <Box sx={{ marginBottom: "48px" }}>
                          <Component
                            patientId={patientId}
                            errors={errors[section.key]}
                          />
                        </Box>
                      </li>
                    </Fragment>
                  );
                })}
              </ol>
            </Grid>
          </Grid>
        </>
      </Form>
    </>
  );
};

const ReviewScroller = ({ sections, sectionRefs }) => {
  const [activeSections, setActiveSections] = useState({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSections((prevActiveSections) => ({
              ...prevActiveSections,
              [entry.target.id]: true,
            }));
          } else {
            setActiveSections((prevActiveSections) => ({
              ...prevActiveSections,
              [entry.target.id]: false,
            }));
          }
        });
      },
      { threshold: 0.1 },
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionRefs]);

  function isActive(id) {
    if (Object.keys(activeSections).length === 0) {
      return false;
    }

    return (
      Object.entries(activeSections).find(([_, value]) => !!value)?.[0] === id
    );
  }

  return (
    <Box>
      <Text
        color="textSecondary"
        sx={{ margin: "24px 0 0 24px", fontWeight: "700" }}
        variant="bodyS">
        REVIEW SECTIONS
      </Text>
      <ol style={{ padding: "0 0 0 24px" }}>
        {sections.map((section, index) => (
          <li
            key={index}
            style={{
              position: "relative",
              listStyleType: "none",
              display: "flex",
              alignItems: "center",
            }}>
            <Box
              sx={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                backgroundColor: isActive(section.id)
                  ? "primary.main"
                  : "lightgrey",
                color: isActive(section.id) ? "white" : "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "12px",
                marginRight: "12px",
              }}>
              {index + 1}
            </Box>
            <Button
              variant="text"
              text={section.title}
              color={isActive(section.id) ? "primary" : "tertiary"}
              onClick={() => {
                sectionRefs[section.id].current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
              sx={{
                display: "inline-block",
                textAlign: "left",
                width: "100%",
                fontWeight: "500",
                fontSize: "1rem",
                padding: 0,
              }}
            />
            {isActive(section.id) && (
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  bottom: 0,
                  width: "4px",
                  backgroundColor: "primary.main",
                  height: "32px",
                  borderRadius: "12px",
                }}
              />
            )}
          </li>
        ))}
      </ol>
    </Box>
  );
};
