import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from "@lexical/markdown";
import { $generateNodesFromDOM } from "@lexical/html";
import { EDITOR_TRANSFORMERS } from "../MarkDownTransformers";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $getRoot,
  $isDecoratorNode,
  $isElementNode,
} from "lexical";

const ConversionsPlugin = forwardRef(
  ({ data, ShouldConvertFromMarkdownString }, ref) => {
    const [editor] = useLexicalComposerContext();

    const convertMarkDownToLexical = useCallback(
      (data) => {
        editor.update(() => {
          if (ShouldConvertFromMarkdownString) {
            $convertFromMarkdownString(
              data,
              EDITOR_TRANSFORMERS,
              undefined, // node
              true,
            );
          } else {
            const parser = new DOMParser();
            const parsedDoc = parser.parseFromString(data, "text/html");
            const nodes = $generateNodesFromDOM(editor, parsedDoc);

            const root = $getRoot();
            root.clear();

            nodes.forEach((node) => {
              if ($isElementNode(node) || $isDecoratorNode(node)) {
                root.append(node);
              } else {
                const paragraph = $createParagraphNode();
                paragraph.append(node);
                root.append(paragraph);
              }
            });
          }
        });
      },
      [editor, ShouldConvertFromMarkdownString],
    );

    function removeBackticks(str) {
      return str.replace(/```/g, "");
    }

    const convertLexicalFormatToMarkDown = useCallback(() => {
      const markdown = editor.getEditorState().read(() => {
        return $convertToMarkdownString(EDITOR_TRANSFORMERS, undefined, true);
      });
      return removeBackticks(markdown);
    }, [editor]);

    useImperativeHandle(ref, () => ({
      getPlanNotesInMarkdownFormat() {
        return convertLexicalFormatToMarkDown();
      },
    }));

    useEffect(() => {
      convertMarkDownToLexical(data);
    }, [data]);

    return <></>;
  },
);
export default ConversionsPlugin;
