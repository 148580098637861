import { memo, useContext, useEffect, useState } from "react";
import { SubForm } from "../common/SubForm";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { Required } from "validations/validators";
import moment from "moment";
import { dateFormat } from "utils/date";
import { formatErrorsToAlerts } from "../common/utils";
import { Box, FormControl, FormControlLabel, Switch } from "@mui/material";
import {
  fetchLatestSmartNote,
  updateSmartNote,
} from "apiClients/latestSmartNote";
import {
  addEncounterHistory,
  deleteEncounterHistory,
  fetchLatestEncounterHistory,
  updateEncounterHistory,
} from "apiClients/latestEncounterHistory";
import HelpIcon from "assets/svg/light/helpIcon";
import CustomPopover from "../common/popover";
import HelpIconFilled from "assets/svg/colored/helpIconFilled";
import { alignPatientIntakeHeadingIconStyle } from "styles";
import { FormContext } from "components/common/FormComponents/Form";

export const RecentMedicalEncounters = memo(({ errors }) => {
  const queryClient = useQueryClient();
  const [values, setValues] = useState([]);
  const { getFormValue } = useContext(FormContext);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { data, isLoading } = useQuery({
    queryKey: ["latestSmartNote", patientId],
    queryFn: () => fetchLatestSmartNote(patientId),
  });

  // Add a new query for encounter history
  const { data: encounterHistoryData, isLoading: encounterHistoryLoading } =
    useQuery({
      queryKey: ["latestEncounterHistory", patientId],
      queryFn: () => fetchLatestEncounterHistory(patientId),
    });

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (!dirtyValue.id) {
        return handleNewEncounter({
          ...dirtyValue,
          external_referral:
            dirtyValue?.external_referral == "on" ? "Referral" : "External",
        });
      }

      if (dirtyValue.delete) {
        return deleteEncounterHistory(patientId, dirtyValue.id);
      }

      if (dirtyValue.id.endsWith("-pre") || dirtyValue.id.endsWith("-ref")) {
        return handleSmartNoteUpdate(dirtyValue);
      }

      return handleExistingEncounter({
        ...dirtyValue,
        external_referral:
          dirtyValue?.external_referral == "on" ? "Referral" : "External",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["latestSmartNote", patientId],
      });
      queryClient.invalidateQueries({
        queryKey: ["latestEncounterHistory", patientId],
      });
    },
  });

  // Helper functions
  const handleNewEncounter = (dirtyValue) => {
    const formattedValue = formatEncounterData(dirtyValue);
    return addEncounterHistory(patientId, formattedValue);
  };

  const handleExistingEncounter = (dirtyValue) => {
    const formattedValue = formatEncounterData(dirtyValue);
    return updateEncounterHistory(patientId, formattedValue, dirtyValue.id);
  };

  const handleSmartNoteUpdate = (dirtyValue) => {
    const isReferral = dirtyValue.id.endsWith("-ref");
    const arrayKey = isReferral ? "referrals" : "procedures";

    delete dirtyValue.category;

    const updatedData = structuredClone(data); // Deep clone to avoid mutations
    const index = parseInt(dirtyValue.id.split("-")[0]);

    updatedData.smart_note_data[arrayKey].splice(index, 1);
    updatedData.smart_note_data[arrayKey].push(dirtyValue);

    return updateSmartNote(patientId, updatedData);
  };

  const formatEncounterData = (value) => ({
    ...value,
    description: value.action,
    short_note: value.note,
    date: moment(value.date).format("YYYY-MM-DD"),
  });

  // Effect to handle data transformations when either data source changes
  useEffect(() => {
    if (!data && !encounterHistoryData) return;

    // Access data from smart_note_data object
    const smartNoteData = data?.smart_note_data || {};

    // Combine procedures and referrals, marking referrals appropriately
    const procedures = (smartNoteData.procedures || []).map((item, idx) => ({
      ...item,
      status:
        item.status === "Completed With Docs"
          ? "Completed–Docs Avail"
          : item.status === "Completed No Docs"
            ? "Completed–No Docs"
            : item.status,
      external_referral: "off",
      date: item?.last_status_update,
      id: `${idx}-pre`,
      hideDeleteIcon: true,
    }));

    const referrals = (smartNoteData.referrals || []).map((item, idx) => ({
      ...item,
      status:
        item.status === "Completed With Docs"
          ? "Completed–Docs Avail"
          : item.status === "Completed No Docs"
            ? "Completed–No Docs"
            : item.status,
      external_referral: "off",
      date: item?.last_status_update,
      id: `${idx}-ref`,
      hideDeleteIcon: true,
    }));

    // Transform encounter history data to match the form structure
    const encounterHistoryDataFormatted = (encounterHistoryData || []).map(
      (item) => ({
        id: item.id,
        action: item.description,
        status: item.status,
        note: item.short_note || "",
        external_referral: item?.external_referral == "Referral" ? "on" : "off",
        last_status_update: item.date,
        hideDeleteIcon: false,
        category: item.category,
        date: item.date,
      }),
    );

    const combinedData = [
      ...procedures,
      ...referrals,
      ...encounterHistoryDataFormatted,
    ];

    setValues(combinedData);
    registerForm("medicalEncounters", handleSubmit, combinedData);
  }, [data, encounterHistoryData]);

  // Cleanup query on unmount
  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["latestSmartNote", patientId],
      });
    };
  }, [queryClient, patientId]);

  const inputForEncounters = [
    {
      label: "Category",
      key: "category",
      type: "select",
      isFieldDisabled: (id) => {
        return id?.endsWith("-pre") || id?.endsWith("-ref") ? true : false;
      },
      options: [
        { value: "Ambulatory Visit", label: "Ambulatory Visit" },
        { value: "Emergency Department", label: "Emergency Department" },
        { value: "Inpatient Hospital", label: "Inpatient Hospital" },
        {
          value: "Non-acute Institutional Stay",
          label: "Non-acute Institutional Stay",
        },
        { value: "Other Ambulatory Visit", label: "Other Ambulatory Visit" },
      ],
      cols: 4,
      freeSolo: true,
    },
    {
      label: "Description",
      key: "action",
      placeholder: "Description",
      isFieldDisabled: (id) => {
        return id?.endsWith("-pre") || id?.endsWith("-ref") ? true : false;
      },
      cols: 3,
    },
    {
      label: "Status",
      key: "status",
      type: "select",
      options: [
        { value: "Ordered", label: "Ordered" },
        { value: "Scheduled", label: "Scheduled" },
        { value: "Completed–Docs Avail", label: "Completed–Docs Avail" },
        { value: "Completed–No Docs", label: "Completed–No Docs" },
        { value: "Canceled", label: "Canceled" },
      ],
      cols: 3,
      validators: [Required],
    },
    {
      label: "Note",
      key: "note",
      placeholder: "Note",
      cols: 3,
    },
    {
      label: "Date",
      key: "date",
      type: "date",
      isFieldDisabled: (id) => {
        return id?.endsWith("-pre") || id?.endsWith("-ref") ? true : false;
      },
      maxDate: moment(),
      validators: [Required],
      getDynamicValidators: (formObjectName) => {
        return [
          (value) => {
            const enc = getFormValue(formObjectName);
            // Ensure value exists and check if it's in the future
            if (value && moment(value).isAfter(moment(), "day")) {
              return "Future dates are not allowed.";
            }
            return undefined;
          },
        ];
      },
      cols: 3,
    },
    {
      label: "Referral",
      key: "external_referral",
      customComponent: ({ key, name, id, value, onChange }) => {
        let val = value === "on";
        return (
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    disabled={
                      id?.endsWith("-pre") || id?.endsWith("-ref")
                        ? true
                        : false
                    }
                    key={key}
                    name={name}
                    id={name}
                    checked={val}
                    onChange={(e) => {
                      const syntheticEvent = {
                        target: {
                          name: name,
                          value: e.target.checked ? "on" : "off",
                        },
                      };
                      onChange?.(syntheticEvent);
                    }}
                  />
                }
                label="Referral"
              />
            </FormControl>
            <input
              type="hidden"
              key={key}
              name={name}
              value={val ? "on" : "off"}
            />
          </Box>
        );
      },
      cols: 1.5,
    },
  ];

  const handleSubmit = async (dirtyValues) => {
    const operations = dirtyValues.map((dirtyValue) => {
      if (dirtyValue.delete) {
        return mutation.mutateAsync(dirtyValue);
      }

      return mutation.mutateAsync(dirtyValue);
    });
    return Promise.allSettled(operations);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Text variant="h1">Encounters</Text>
        <span
          onClick={handleClick}
          aria-describedby={"simple-popover"}
          style={alignPatientIntakeHeadingIconStyle}>
          {anchorEl == null ? <HelpIcon /> : <HelpIconFilled />}
        </span>
      </Box>
      <CustomPopover
        id={open ? "simple-popover" : undefined}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        <Box sx={{ padding: 2, maxWidth: "380px" }}>
          <Box>
            {" "}
            <Text marginY={1} variant="h4" fontWeight={700} color={"grey"}>
              GUIDELINE
            </Text>
          </Box>
          <Box>
            <Text variant="h4" fontWeight={700} sx={{ whiteSpace: "unset" }}>
              Check for hospital stays, ER visits, and visits with new or
              existing providers.
            </Text>
          </Box>
          <ul style={{ paddingLeft: "16px" }}>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              ER or hospitalizations? Any recent visits to the ER, urgent care,
              or hospital admissions?
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              If this is a follow-up visit, confirm whether previous treatments
              or recommendations have helped.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Check for any unresolved action items from the last visit
              (medications, labs, referrals, etc.).
            </li>
          </ul>
        </Box>
      </CustomPopover>
      <SubForm
        dataLoading={isLoading && encounterHistoryLoading}
        formKey={"medicalEncounters"}
        subSections={inputForEncounters}
        data={values}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
