import { toast } from "react-toastify";

const position = "top-center";
function displayToast(message, emoji, options) {
  toast.dismiss();
  toast(`${emoji} ${message}`, { ...options, position: position });
}

export function error(message, emoji = "") {
  displayToast(message, emoji, { type: "error" });
}

export function success(message, emoji = "") {
  displayToast(message, emoji, { type: "success" });
}

export function warning(message, emoji = "") {
  displayToast(message, emoji, { type: "warning" });
}
export function info(message, emoji = "") {
  displayToast(message, emoji, { type: "info" });
}
