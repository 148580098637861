import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/common/Typography/Text";
import { useNotesHistoryModalStyles } from "styles/Modals/ViewNotesHistoryModalStyle";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { clearAddUpdateDeleteMedicationsData } from "redux/features/Medications/MedicationsSlice";
import { useParams } from "react-router-dom";
import Accordian from "./Accordian/MedicationsAccordian";
import { useQueryClient } from "@tanstack/react-query";
import CustomModal from "components/common/Modal/CustomModal";
import Button from "components/common/Button/Button";
import AddMedication from "./AddMedication";

const MedicationDetail = ({
  activeMedications,
  inActiveMedications,
  refusedMedications,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const classes = useNotesHistoryModalStyles();
  const { patientId } = useParams();
  const [open, setOpen] = useState(false);
  const { deletedMedicationSuccessfully, updatedMedicationSuccessfully } =
    useSelector((state) => state.medications);

  useEffect(() => {
    if (deletedMedicationSuccessfully) {
      toast.success("Medication deleted");
      dispatch(clearAddUpdateDeleteMedicationsData());
    }
  }, [deletedMedicationSuccessfully]);

  useEffect(() => {
    if (updatedMedicationSuccessfully) {
      queryClient.invalidateQueries({ queryKey: ["medications", patientId] });
      dispatch(clearAddUpdateDeleteMedicationsData());
    }
  }, [updatedMedicationSuccessfully]);

  return (
    <Box>
      <CustomModal
        fullWidth
        maxWidth="sm"
        setOpen={setOpen}
        open={open}
        title="Add Prescription">
        <AddMedication setIsOpenModal={setOpen} />
      </CustomModal>
      {activeMedications?.length > 0 ? (
        activeMedications?.map((ele, i) => (
          <Accordian
            key={i}
            data={ele}
            status="active"
            classes={classes}
            id={ele?.id}
          />
        ))
      ) : (
        <Box>No Data Found</Box>
      )}
      <Box>
        <Button
          sx={{ minWidth: "50px", width: "50px" }}
          variant="text"
          text={"+ Add"}
          onClick={() => setOpen(true)}
        />
      </Box>
      <Box marginTop={3}>
        <Text variant="h2" fontWeight="600">
          <span data-testid="inactive-medication-detail-heading">Inactive</span>
        </Text>
        {inActiveMedications?.length > 0 ? (
          inActiveMedications?.map((ele, i) => (
            <Accordian
              key={i}
              data={ele}
              classes={classes}
              id={ele?.id}
              status="stopped"
            />
          ))
        ) : (
          <div>No Data Found</div>
        )}
      </Box>
      <Box marginTop={3}>
        <Text variant="h2" fontWeight="600">
          <span data-testid="refused-medication-detail-heading">Refused</span>
        </Text>
        {refusedMedications?.length > 0 ? (
          refusedMedications?.map((ele, i) => (
            <Accordian
              key={i}
              data={ele}
              classes={classes}
              id={ele?.id}
              status="refused"
            />
          ))
        ) : (
          <div>No Data Found</div>
        )}
      </Box>
    </Box>
  );
};

export default MedicationDetail;
