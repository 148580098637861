import { LoadingButton } from "@mui/lab";
import React from "react";
const Button = ({
  id = "",
  ref,
  onClick,
  text,
  isLoading = false,
  disabled,
  leftSide = false,
  rightSide = false,
  sx = {},
  iconDetails = {
    allowIcon: false,
    icon: null,
    iconSx: { padding: "0px 5px 0px 5px", height: "28px", width: "28px" },
  },
  variant = "contained",
  type = "button",
  color = "primary",
}) => {
  let { allowIcon, icon, iconSx } = iconDetails;
  const isReactComponent = typeof icon === "function";
  return (
    <LoadingButton
      ref={ref}
      data-testid={id}
      sx={{
        ...sx,
      }}
      title={text}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled || isLoading}
      color={color}
      type={type}
      variant={variant}>
      {leftSide && allowIcon ? (
        isReactComponent ? (
          // Render the React component directly
          React.createElement(icon, {
            style: { marginRight: "5px", ...iconSx },
          })
        ) : (
          <img
            alt="icon"
            src={icon}
            style={{ marginRight: "5px", ...iconSx }}
          />
        )
      ) : (
        ""
      )}
      {text}
      {rightSide && allowIcon ? (
        isReactComponent ? (
          // Render the React component directly
          React.createElement(icon, { style: { marginLeft: "5px", ...iconSx } })
        ) : (
          <img alt="icon" src={icon} style={{ marginLeft: "5px", ...iconSx }} />
        )
      ) : (
        ""
      )}
    </LoadingButton>
  );
};
export default Button;
