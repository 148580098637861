import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addMedication,
  deleteMedication,
  fetchAllMedications,
  updateMedication,
} from "apiClients/medications";
import moment from "moment";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { SubForm } from "../common/SubForm";
import { MedicationSelectInput } from "components/common/FormComponents/MedicationSelectInput";
import { Max, Min, Required } from "validations/validators";
import { FormContext } from "components/common/FormComponents/Form";
import { dateFormat } from "utils/date";
import { formatErrorsToAlerts } from "../common/utils";
import { Box, FormControl, FormControlLabel, Switch } from "@mui/material";
import { LogoIcon } from "assets/svg/Logo";
import CustomPopover from "../common/popover";
import HelpIcon from "assets/svg/light/helpIcon";
import HelpIconFilled from "assets/svg/colored/helpIconFilled";
import { alignPatientIntakeHeadingIconStyle } from "styles";
import {
  amountOptions,
  dosageMapping,
  frequencyOptions,
} from "assets/static/testData";

export const MedicationsReview = memo(({ errors }) => {
  const { getFormValue } = useContext(FormContext);
  const queryClient = useQueryClient();
  const [mappedResults, setMappedResults] = useState([]);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const medicationsInputs = [
    {
      label: "Name",
      key: "drug_name",
      customComponent: ({ key, disabled, isEtl, ...rest }) => (
        <>
          <MedicationSelectInput key={key} {...rest} disabled={disabled} />
          {!isEtl && (
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <span style={{ marginBottom: 1 }}>
                <LogoIcon />
              </span>
            </Box>
          )}
          {disabled && <input type="hidden" key={key} {...rest} />}
        </>
      ),
    },
    {
      label: "Amount",
      type: "select",
      key: "dosage_quantity_value",
      options: amountOptions,
      placeholder: "Amount",
      cols: 3,
    },
    {
      label: "Frequency",
      type: "select",
      key: "dosage_period",
      placeholder: "Frequency",
      options: frequencyOptions,
      cols: 3,
    },
    {
      label: "Add End Date",
      key: "show_end_date",
      customComponent: ({ key, name, value, onChange }) => (
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  key={key}
                  name={name}
                  checked={value === "on"}
                  onChange={(e) => {
                    const syntheticEvent = {
                      target: {
                        name: name,
                        value: e.target.checked ? "on" : "off",
                      },
                    };
                    onChange?.(syntheticEvent);
                  }}
                />
              }
              label="Add End Date"
            />
          </FormControl>
        </Box>
      ),
      cols: 2,
    },
    {
      label: "End Date",
      key: "end_date",
      type: "date",
      cols: 2,
      renderOnChanged: true,
      shouldRender: (formObjectName) => {
        const medication = getFormValue(formObjectName);
        return medication?.show_end_date === "on" ? true : false;
      },
      getDynamicValidators: (formObjectName) => {
        return [
          (value) => {
            const medication = getFormValue(formObjectName);
            if (!!medication.start_date) {
              return Min(medication.start_date)(value);
            }
            return undefined;
          },
        ];
      },
    },
    {
      label: "Status",
      key: "status",
      type: "select",
      options: [
        { value: "active", label: "active" },
        { value: "In-Active", label: "In-Active" },
        { value: "refused", label: "refused" },
      ],
      cols: 3,
      validators: [Required],
    },
    {
      label: "Use Only",
      key: "useOnly",
      type: "checkbox",
      cols: 2,
    },
    {
      label: "Instructions",
      key: "instructions",
      placeholder: "Enter Instructions",
      helperText: "Check if instructions should override amount and frequency",
      cols: 4,
    },
    {
      label: "Notes",
      key: "short_note",
      placeholder: "Enter Notes",
      cols: 4,
    },
  ];

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medication.
          return deleteMedication(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medication since it is dirty (changed).
          return updateMedication(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medication and we need to add it.
        return addMedication(patientId, dirtyValue);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["medications", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.resetQueries({
        queryKey: ["medications", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data, isLoading } = useQuery({
    queryKey: ["medications", patientId],
    queryFn: async () => await fetchAllMedications(patientId),
    staleTime: 600000, // 10 minutes in milliseconds
  });

  const getSelectedFrequency = (medicationData) => {
    if (medicationData?.dosage_period && medicationData?.dosage_period_unit) {
      // Reverse map to find the label
      const dosageKey = Object.keys(dosageMapping).find((key) => {
        const { dosage_period, dosage_period_unit } = dosageMapping[key];
        return (
          dosage_period === medicationData.dosage_period &&
          dosage_period_unit === medicationData.dosage_period_unit
        );
      });

      // Find the corresponding option in frequencyOptions
      return frequencyOptions.find((option) => option.label === dosageKey);
    }
    return null;
  };

  const mapResults = (results = []) => {
    const getDate = (date) => (date ? moment(date).format(dateFormat) : "");
    return results.map((result) => {
      const useOnly =
        result &&
        result.dosage_period == null &&
        result.dosage_period_unit == null &&
        result.dosage_quantity_value == null;

      return {
        ...result,
        useOnly: useOnly,
        dosage_period: getSelectedFrequency(result)?.label,
        start_date: getDate(result.start_date),
        show_end_date: getDate(result.end_date) ? "on" : "off",
        end_date: getDate(result.end_date),
        status: result.status === "stopped" ? "In-Active" : result.status,
        instructions: result?.instructions,
      };
    });
  };

  const mapDosagePeriod = (value) => {
    return dosageMapping[value];
  };

  const handleSubmit = async (dirtyValues) => {
    const operations = dirtyValues.map((dirtyValue) => {
      const dosagePeriodUnit = mapDosagePeriod(dirtyValue.dosage_period);
      // Transform status back to "stopped" if it is "In-Active"
      const formattedValue = {
        ...dirtyValue,
        dosage_quantity_value: dirtyValue?.useOnly
          ? null
          : dirtyValue.dosage_quantity_value == ""
            ? null
            : dirtyValue.dosage_quantity_value,
        dosage_period: dirtyValue?.useOnly
          ? null
          : dosagePeriodUnit?.dosage_period,
        dosage_period_unit: dirtyValue?.useOnly
          ? null
          : dosagePeriodUnit?.dosage_period_unit,
        start_date: dirtyValue.start_date
          ? moment(dirtyValue.start_date).format("YYYY-MM-DD")
          : null,
        end_date:
          dirtyValue.status === "active"
            ? null
            : dirtyValue.end_date
              ? moment(dirtyValue.end_date).format("YYYY-MM-DD")
              : null,
        status:
          dirtyValue.status === "In-Active" ? "stopped" : dirtyValue.status, // Transform status
      };

      if (formattedValue.delete) {
        return mutation.mutateAsync(dirtyValue);
      }

      return mutation.mutateAsync(formattedValue);
    });
    return Promise.allSettled(operations);
  };

  useEffect(() => {
    const val = mapResults(data?.results).sort((a, b) => {
      return a.start_date.localeCompare(b.start_date);
    });
    registerForm("medications", handleSubmit, val);
    setMappedResults(val);
  }, [data]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Text variant="h1">Medications</Text>
        <span
          onClick={handleClick}
          aria-describedby={"simple-popover"}
          style={alignPatientIntakeHeadingIconStyle}>
          {anchorEl == null ? <HelpIcon /> : <HelpIconFilled />}
        </span>
      </Box>

      <CustomPopover
        id={open ? "simple-popover" : undefined}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        <Box sx={{ padding: 2, maxWidth: "380px" }}>
          <Box>
            {" "}
            <Text marginY={1} variant="h4" fontWeight={700} color={"grey"}>
              GUIDELINE
            </Text>
          </Box>
          <Box>
            <Text variant="h4" fontWeight={700} sx={{ whiteSpace: "unset" }}>
              Verify medication list, changes, adherence & outside prescriptions
              visits with new or existing providers.
            </Text>
          </Box>
          <ul style={{ paddingLeft: "16px" }}>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Review the auto-populated medication list and confirm if it’s
              accurate and up to date.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              New medications? Ask if any other providers have prescribed new
              medications.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Stopped or changed meds? Verify if prior medication changes from
              the last visit were implemented.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Adherence check: “Are you taking your medications as prescribed?”
              If not, why?
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              OTC & supplements: Ask about vitamins, herbal supplements, or
              other non-prescription meds.
            </li>
          </ul>
        </Box>
      </CustomPopover>
      <SubForm
        trashMargin={"0px 0px 28px 0px"}
        subSections={medicationsInputs}
        formKey="medications"
        dataLoading={isLoading}
        data={mappedResults}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
