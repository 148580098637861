import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { formatErrorsToAlerts } from "../common/utils";
import InputField from "components/common/FormComponents/InputField";
import { fetchPatient, putPatientData } from "apiClients/patients";
import HelpIcon from "assets/svg/light/helpIcon";
import CustomPopover from "../common/popover";
import { Box } from "@mui/material";
import HelpIconFilled from "assets/svg/colored/helpIconFilled";
import { alignPatientIntakeHeadingIconStyle } from "styles";

export const ReasonForVisit = memo(({ errors }) => {
  const queryClient = useQueryClient();
  const [value, setValue] = useState([]);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { data, isLoading } = useQuery({
    queryKey: ["patientData", patientId],
    queryFn: () => fetchPatient(patientId),
  });

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      return putPatientData(patientId, {
        ...data,
        fhir_resource_id: data?.fhir_resource_id || null,
        smart_summary_data: {
          ...data?.smart_summary_data,
          reason_for_visit: dirtyValue,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["patientData", patientId],
      });
    },
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({
        queryKey: ["patientData", patientId],
      });
    };
  }, [queryClient, patientId]);

  const handleSubmit = (dirtyValues) => {
    const operations = [mutation.mutateAsync(dirtyValues)];
    return Promise.allSettled(operations);
  };

  useEffect(() => {
    if (data) {
      setValue(data);
      registerForm(
        "reasonForVisit",
        handleSubmit,
        data?.smart_summary_data?.reason_for_visit,
      );
    }
  }, [data]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Text variant="h1">Reason For Visit</Text>
        <span
          onClick={handleClick}
          aria-describedby={"simple-popover"}
          style={alignPatientIntakeHeadingIconStyle}>
          {anchorEl == null ? <HelpIcon /> : <HelpIconFilled />}
        </span>
      </Box>
      <CustomPopover
        id={open ? "simple-popover" : undefined}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        <Box sx={{ padding: 2, maxWidth: "380px" }}>
          <Box>
            {" "}
            <Text marginY={1} variant="h4" fontWeight={700} color={"grey"}>
              GUIDELINE
            </Text>
          </Box>
          <Box>
            <Text variant="h4" fontWeight={700} sx={{ whiteSpace: "unset" }}>
              Confirm today's visit reason & any new symptoms or concerns
            </Text>
          </Box>
          <ul style={{ paddingLeft: "16px" }}>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Ask the patient why they are here today and if there are any new
              or worsening symptoms.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              If this is a follow-up visit, confirm whether previous treatments
              or recommendations have helped.
            </li>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Check for any unresolved action items from the last visit
              (medications, labs, referrals, etc.).
            </li>
          </ul>
        </Box>
      </CustomPopover>
      <InputField
        value={value?.smart_summary_data?.reason_for_visit}
        onChange={(e) =>
          setValue((prev) => ({
            ...prev,
            smart_summary_data: {
              ...prev.smart_summary_data,
              reason_for_visit: e.target.value,
            },
          }))
        }
        name={"reasonForVisit"}
        key={"reasonForVisit"}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
