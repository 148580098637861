import CustomModal from "components/common/Modal/CustomModal";
import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";

const DocumentSummaryModal = ({ isOpen, onClose, documentSummary }) => {
  const handleClose = () => {
    onClose(false);
  };

  const formatLabel = (key) => {
    return (
      key
        .split("_")
        .map((word, index) =>
          index === 0
            ? word.charAt(0).toUpperCase() + word.slice(1)
            : word.charAt(0).toUpperCase() + word.slice(1),
        )
        .join(" ") + ":"
    );
  };

  let parsedSummary;
  if (documentSummary) {
    try {
      // Parse if string
      const parsed =
        typeof documentSummary === "string"
          ? JSON.parse(documentSummary)
          : documentSummary;

      // Handle array format by taking first item
      parsedSummary = Array.isArray(parsed) ? parsed[0] : parsed;
    } catch (error) {
      parsedSummary = null;
    }
  }

  return (
    <CustomModal
      setOpen={handleClose}
      open={isOpen}
      title="Document Summary"
      maxWidth="sm">
      <Box mt={2} display="flex" flexDirection="column" gap={2}>
        {parsedSummary &&
          Object.entries(parsedSummary)
            .filter(([_, value]) => value)
            .sort(([key1], [key2]) => {
              if (key1 === "title") return -1;
              if (key2 === "title") return 1;
              if (key1 === "summary") return 1;
              if (key2 === "summary") return -1;
              return 0;
            })
            .map(([key, value]) => (
              <Box display="flex" flexDirection="column" key={key}>
                <Text variant="bodyS" fontWeight={700}>
                  {formatLabel(key)}
                </Text>
                <Text
                  color="purple"
                  variant="bodyS"
                  sx={{ whiteSpace: "normal", marginTop: "6px" }}>
                  {value}
                </Text>
              </Box>
            ))}
      </Box>
    </CustomModal>
  );
};

export default DocumentSummaryModal;
