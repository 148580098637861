import Login from "pages/Auth/Login";
import CreateNewPassword from "pages/Auth/Login/components/CreateNewPassword";
import { AppointmentsDashboard } from "pages/Dashboard/AppointmentsDashbaord";
import PatientDetail from "pages/Dashboard/PatientDetail";
import SettingsWrapper from "pages/Dashboard/Settings/Wrapper";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "pages/Auth/Login/components/ForgotPassword";
import { PatientDashboard } from "pages/Dashboard/PatientDashboard";
import { OpenEncountersDashboard } from "pages/Dashboard/OpenEncountersDashboard";

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/createNewPassword/:hiddenToken"
        element={<CreateNewPassword />}
      />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route
        path="/createNewPassword/:hiddenToken/:email"
        element={<CreateNewPassword />}
      />
      <Route element={<PrivateRoute />}>
        {/* redirect / to patients */}
        <Route path="/" element={<Navigate to="/appointments" />} />
        <Route path="/appointments" element={<AppointmentsDashboard />} />
        <Route path="/patients" element={<PatientDashboard />} />
        <Route path="/encounters" element={<OpenEncountersDashboard />} />
        <Route
          path="/schedule/patient/:patientId/appointment/:appointmentId"
          element={<PatientDetail />}
        />
        <Route path="/patients/:patientId" element={<PatientDetail />} />
        <Route path="/settings" element={<SettingsWrapper />} />
        <Route path="/settings?tab=:tab" element={<SettingsWrapper />} />
      </Route>
    </Routes>
  );
};

export default Router;
