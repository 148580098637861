import { memo, useContext, useEffect, useState } from "react";
import Text from "components/common/Typography/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IntakeFormContext } from "../common/IntakeFormProvider";
import { SubForm } from "../common/SubForm";
import { Required } from "validations/validators";
import { formatErrorsToAlerts } from "../common/utils";
import { Box } from "@mui/material";
import CustomPopover from "../common/popover";
import HelpIcon from "assets/svg/light/helpIcon";
import HelpIconFilled from "assets/svg/colored/helpIconFilled";
import { alignPatientIntakeHeadingIconStyle } from "styles";
import {
  addPifDocs,
  deletePifDocs,
  fetchPifDocs,
  updatePifDocs,
} from "apiClients/documents.api";

export const DocsReview = memo(({ errors }) => {
  const queryClient = useQueryClient();
  const [mappedResults, setMappedResults] = useState([]);
  const { registerForm, patientId } = useContext(IntakeFormContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const docsInputs = [
    {
      label: "Category",
      type: "select",
      key: "external_document_category",
      options: [
        { label: "ADMINISTRATIVE", value: "ADMINISTRATIVE" },
        { label: "FINANCIAL", value: "FINANCIAL" },
        { label: "PATIENT-GENERATED", value: "PATIENT-GENERATED" },
        { label: "EXTERNAL / LEGACY", value: "EXTERNAL / LEGACY" },
      ],
      validators: [Required],
      placeholder: "Category",
      cols: 4,
    },
    {
      label: "Label",
      key: "external_document_label",
      placeholder: "Label",
      cols: 2,
    },
    {
      label: "Notes",
      key: "external_document_note",
      placeholder: "Note",
      cols: 3,
    },
    {
      label: "Status",
      key: "external_document_status",
      type: "select",
      options: [
        { label: "ORDERED", value: "ORDERED" },
        { label: "SCHEDULED", value: "SCHEDULED" },
        {
          label: "COMPLETED - DOC AVAILABLE",
          value: "COMPLETED - DOC AVAILABLE",
        },
        { label: "COMPLETED - NO DOC", value: "COMPLETED - NO DOC" },
        { label: "CANCELED", value: "CANCELED" },
      ],
      cols: 3,
      validators: [Required],
    },
  ];

  const mutation = useMutation({
    mutationFn: async (dirtyValue) => {
      if (dirtyValue.id) {
        if (dirtyValue.delete) {
          // If delete is set we need to delete the medication.
          return deletePifDocs(patientId, dirtyValue.id);
        } else {
          // If no delete is set, we need to update the medication since it is dirty (changed).
          return updatePifDocs(patientId, dirtyValue.id, dirtyValue);
        }
      } else {
        // If no ID is present, it is a new medication and we need to add it.
        return addPifDocs(patientId, dirtyValue);
      }
    },
  });

  useEffect(() => {
    return () => {
      queryClient.resetQueries({
        queryKey: ["pifDocuments", patientId],
      });
    };
  }, [queryClient, patientId]);

  const { data, isLoading } = useQuery({
    queryKey: ["pifDocuments", patientId],
    queryFn: async () => await fetchPifDocs(patientId),
  });

  const handleSubmit = async (dirtyValues) => {
    const operations = dirtyValues.map((dirtyValue) => {
      return mutation.mutateAsync({
        ...dirtyValue,
        patient_id: patientId,
        external_internal: "EXTERNAL",
      });
    });
    return Promise.allSettled(operations);
  };

  useEffect(() => {
    registerForm("docsReview", handleSubmit, data);
    setMappedResults(data);
  }, [data]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Text variant="h1">Documents</Text>
        <span
          onClick={handleClick}
          aria-describedby={"simple-popover"}
          style={alignPatientIntakeHeadingIconStyle}>
          {anchorEl == null ? <HelpIcon /> : <HelpIconFilled />}
        </span>
      </Box>

      <CustomPopover
        id={open ? "simple-popover" : undefined}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}>
        <Box sx={{ padding: 2, maxWidth: "380px" }}>
          <Box>
            {" "}
            <Text marginY={1} variant="h4" fontWeight={700} color={"grey"}>
              GUIDELINE
            </Text>
          </Box>
          <Box>
            <Text variant="h4" fontWeight={700} sx={{ whiteSpace: "unset" }}>
              Upload any patients documents
            </Text>
          </Box>
          <ul style={{ paddingLeft: "16px" }}>
            <li style={{ fontSize: "13px", fontWeight: 500 }}>
              {" "}
              Upload any documents Patient has brought like BP diary, etc.
            </li>
          </ul>
        </Box>
      </CustomPopover>
      <SubForm
        subSections={docsInputs}
        formKey="docsReview"
        dataLoading={isLoading}
        data={mappedResults}
      />
      {formatErrorsToAlerts(errors)}
    </>
  );
});
