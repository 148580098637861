import { apiDelete, apiGet, apiPost, apiPatch, apiPut } from "./base";

export const fetchLatestEncounterHistory = async (patientId) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/latest_encounter_history/`;
  const response = await apiGet(url);
  return response.data;
};

export const addEncounterHistory = async (patientId, data) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updateEncounterHistory = async (patientId, data, id) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/${id}/`;
  const response = await apiPatch(url, data);
  return response.data;
};

export const deleteEncounterHistory = async (patientId, id) => {
  const url = `/api/patients/${patientId}/medicalencounterhistory/${id}/`;
  const response = await apiDelete(url);
  return response.data;
};
