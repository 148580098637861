import { Box, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text";
import Button from "components/common/Button/Button";

export const EndVisitSuccessModal = ({
  open,
  onClose,
  printSummary,
  isVisitSummaryLoading = false,
  title = "End Visit",
}) => {
  const theme = useTheme();
  return (
    <CustomModal
      open={open}
      setOpen={onClose}
      title={title}
      maxWidth={"sm"}
      fullWidth={true}>
      <Box sx={{ padding: "24px 0" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}>
          <CheckCircleIcon
            sx={{
              color: theme.palette.common.green,
              fontSize: 28,
              marginRight: "6px",
            }}
          />
          <Text variant="h1" color={"green"}>
            End Note Signed!
          </Text>
        </Box>
        <Text sx={{ whiteSpace: "normal" }} marginY={2} variant="body">
          {" "}
          The Patient End Note is available for review in the{" "}
          <b>“Past Visits”</b> section of the patient dashboard.
        </Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}>
        <Button
          variant="outlined"
          isLoading={isVisitSummaryLoading}
          text="Print Patient Summary"
          onClick={printSummary}
          sx={{ marginRight: 2 }}
        />
        <Button
          variant="outlined"
          text="End Visit"
          onClick={onClose}
          sx={{ marginRight: 2 }}
        />
      </Box>
    </CustomModal>
  );
};
