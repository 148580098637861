import { Box, debounce, Grid, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import Text from "components/common/Typography/Text";
import { IconButton } from "components/common/Button/IconButton";
import { Trash } from "components/common/SVG/Trash";
import CheckIcon from "@mui/icons-material/Check";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import { Form } from "components/common/FormComponents/Form";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  clearMedicinesListData,
  getMedicinesList,
} from "redux/features/Medications/MedicinesSlice";
import { Required } from "validations/validators";
import {
  addPatientMedications,
  clearAddUpdateDeleteMedicationsData,
  updatePatientMedications,
} from "redux/features/Medications/MedicationsSlice";
import {
  amountOptions,
  dosageMapping,
  frequencyOptions,
} from "assets/static/testData";
import CheckBoxField from "components/common/FormComponents/CheckBoxField";
import { addMedication, updateMedication } from "apiClients/medications";

const isValid = (medication, useOnly) => {
  if (useOnly) {
    if (medication.drug_name && medication.instructions) {
      return true;
    }
  }

  if (
    medication?.dosage_quantity_value &&
    medication?.drug_name &&
    medication?.dosage_period &&
    medication?.dosage_period_unit
  ) {
    return true;
  }

  return false;
};

const AddMedication = ({
  setIsOpenModal = () => {},
  medicationData,
  forceStatus,
  interimPageView = false,
  onSubmit = () => {},
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const theme = useTheme();
  const medicationsAbortControllerRef = useRef(null);
  const [selectedMed, setSelectedMed] = useState(
    medicationData?.drug_name
      ? { label: medicationData?.drug_name, value: medicationData?.drug_name }
      : null,
  );
  const { account_id } = useSelector((state) => state.auth);

  const { medicinesData, medicinesIsLoading } = useSelector(
    (state) => state.medicines,
  );
  const {
    addingMedicationInProgress,
    addedMedicationSuccessfully,
    updatedMedicationSuccessfully,
    updatingMedicationInProgress,
  } = useSelector((state) => state.medications);
  const [useOnly, setUseOnly] = useState(() => {
    if (!medicationData) return false;
    return (
      medicationData.dosage_period === "" &&
      medicationData.dosage_period_unit === "" &&
      medicationData.dosage_quantity_value === ""
    );
  });

  const mapDosagePeriod = (value) => {
    return dosageMapping[value];
  };

  const initialFormValues = {
    ...medicationData,
    drug_name: medicationData?.drug_name ?? "",
    instructions: medicationData?.instructions ?? "",
    dosage_period: medicationData?.dosage_period ?? "",
    dosage_period_unit: medicationData?.dosage_period_unit ?? "",
    dosage_quantity_value: medicationData?.dosage_quantity_value ?? "",
    short_note: medicationData?.short_note ?? "",
    end_date: medicationData?.end_date ?? null,
    account_id: medicationData?.account_id ?? account_id,
    start_date: medicationData?.start_date ?? new Date(),
    patient_id: medicationData?.patientId ?? patientId,
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  useEffect(() => {
    if (selectedMed) {
      setFormValues((prev) => ({
        ...prev,
        drug_name: selectedMed.label,
      }));
    }
  }, [selectedMed]);

  const showSubmitButton =
    interimPageView &&
    isValid(
      {
        drug_name: formValues?.drug_name || selectedMed?.label,
        instructions: formValues?.instructions,
        dosage_quantity_value: formValues?.dosage_quantity_value,
        dosage_period: formValues?.dosage_period,
        dosage_period_unit: formValues?.dosage_period_unit,
      },
      useOnly,
    );

  const abortMedicationsCurrentRequest = () => {
    if (medicationsAbortControllerRef.current) {
      medicationsAbortControllerRef.current.abort();
    }
  };
  const getMedicines = debounce((value) => {
    if (value?.length > 2) {
      abortMedicationsCurrentRequest();
      const medicationsAbortController = new AbortController();
      medicationsAbortControllerRef.current = medicationsAbortController;
      dispatch(
        getMedicinesList(value, patientId, medicationsAbortController.signal),
      );
    }
  }, 400);

  const handleSubmit = async (data) => {
    const status = forceStatus
      ? forceStatus
      : data.status
        ? data.status
        : "active";
    const dosagePeriodUnit = mapDosagePeriod(data.dosage_period);
    const payload = {
      drug_name: selectedMed.label,
      dosage: data.dosage,
      frequency: data.frequency?.value,
      instructions: data.instructions,
      dosage_quantity_value: data?.useOnly
        ? null
        : data.dosage_quantity_value == ""
          ? null
          : data?.dosage_quantity_value,
      dosage_period: data?.useOnly ? null : dosagePeriodUnit?.dosage_period,
      dosage_period_unit: data?.useOnly
        ? null
        : dosagePeriodUnit?.dosage_period_unit,
      // If forceStatus is provided, override the status with that value.
      status: status,
      end_date:
        status == "active"
          ? null
          : status == "In-Active"
            ? moment().format("YYYY-MM-DD")
            : data?.end_date,
      patient_id: patientId,
      account_id: account_id,
      short_note: data.short_note,
    };

    // There are no redux state dependencies for medications via interim page view
    if (interimPageView) {
      if (!isValid(payload, useOnly)) return; // Mv this up in the call stack.

      if (medicationData?.id) {
        const response = await updateMedication(
          patientId,
          medicationData.id,
          payload,
        );
        onSubmit(response);
      } else {
        const response = await addMedication(patientId, payload);
        onSubmit(response);
      }
    } else {
      // Non-interim page view.
      if (medicationData?.id) {
        dispatch(
          updatePatientMedications(patientId, medicationData.id, payload),
        );
      } else {
        dispatch(addPatientMedications(patientId, payload));
      }
    }
  };

  useEffect(() => {
    if (addedMedicationSuccessfully || updatedMedicationSuccessfully) {
      queryClient.invalidateQueries({ queryKey: ["medications", patientId] });
      dispatch(clearAddUpdateDeleteMedicationsData());
      setIsOpenModal(false);
    }
  }, [
    addedMedicationSuccessfully,
    updatedMedicationSuccessfully,
    queryClient,
    dispatch,
    patientId,
    setIsOpenModal,
  ]);

  useEffect(() => {
    return () => {
      abortMedicationsCurrentRequest();
    };
  }, []);

  const getSelectedFrequency = () => {
    if (medicationData?.dosage_period && medicationData?.dosage_period_unit) {
      // Reverse map to find the label
      const dosageKey = Object.keys(dosageMapping).find((key) => {
        const { dosage_period, dosage_period_unit } = dosageMapping[key];
        return (
          dosage_period === medicationData.dosage_period &&
          dosage_period_unit === medicationData.dosage_period_unit
        );
      });

      // Find the corresponding option in frequencyOptions
      return frequencyOptions.find((option) => option.label === dosageKey);
    }
    return null;
  };

  return (
    <Box padding={2}>
      <Form onSubmit={handleSubmit} initialValues={initialFormValues}>
        <Grid container spacing={2}>
          <SelectField
            inputLabel="Search Medication"
            cols={interimPageView ? 10 : 12}
            name="drug_name"
            disabled={medicationData?.etl_last_updated == null ? false : true}
            border={`1px solid ${theme.palette.common.black}`}
            onSearch={getMedicines}
            extendedSearchOptions={["description"]}
            showMoreInLabel={{ show: true, value: "description" }}
            loading={medicinesIsLoading}
            options={medicinesData}
            openOnFocus={false}
            onClear={() =>
              medicinesData?.length ? dispatch(clearMedicinesListData()) : null
            }
            validators={[Required]}
            value={selectedMed}
            onChange={(_, v) => {
              setSelectedMed(v);
              setFormValues((prev) => ({
                ...prev,
                drug_name: v?.label || "",
              }));
            }}
          />
          {interimPageView && (
            <Box sx={{ marginLeft: "8px", marginTop: "8px" }} cols={2}>
              {showSubmitButton && (
                <IconButton
                  height="40px"
                  type="submit"
                  sx={{ minWidth: "40px" }}>
                  <CheckIcon sx={{ height: "20px", width: "20px" }} />
                </IconButton>
              )}
              <IconButton
                onClick={() => setIsOpenModal(false)}
                sx={{
                  marginLeft: "8px",
                  height: "40px",
                  width: "40px",
                }}>
                <Trash />
              </IconButton>
            </Box>
          )}
          <SelectField
            name="dosage_quantity_value"
            options={amountOptions}
            inputLabel="Amount"
            value={amountOptions?.find(
              (amt) => Number(amt.value) === formValues.dosage_quantity_value,
            )}
            onChange={(_, v) => {
              setFormValues((prev) => ({
                ...prev,
                dosage_quantity_value: v?.value || "",
              }));
            }}
            sm={6}
          />
          <SelectField
            name="dosage_period"
            inputLabel="Frequency"
            options={frequencyOptions}
            value={getSelectedFrequency()}
            onChange={(_, v) => {
              const dosage = mapDosagePeriod(v?.value);
              setFormValues((prev) => ({
                ...prev,
                dosage_period: dosage?.dosage_period || "",
                dosage_period_unit: dosage?.dosage_period_unit || "",
              }));
            }}
            sm={6}
          />
          <InputField
            name="instructions"
            inputLabel="Instructions"
            helperText="Check to use only the instructions in the prescription."
            value={formValues.instructions}
            onChange={(e) => {
              setFormValues((prev) => ({
                ...prev,
                instructions: e.target.value,
              }));
            }}
            cols={9}
          />
          <CheckBoxField
            cols={3}
            name="useOnly"
            checkLabel="Use Only"
            checked={useOnly}
            onChange={(e) => setUseOnly(e.target.checked)}
            labelPlacement="end"
            margin="0px 0px 10px 10px"
          />
          {!interimPageView && (
            <InputField
              name="short_note"
              label="Notes"
              placeholder="Enter notes"
              value={initialFormValues.short_note}
              cols={12}
            />
          )}
        </Grid>
        {!interimPageView && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              marginTop: "20px",
            }}>
            <Button
              variant="outlined"
              color="tertiary"
              text="Back"
              onClick={() => setIsOpenModal(false)}
            />
            <Button
              text={medicationData ? "Update" : "Save"}
              isLoading={
                addingMedicationInProgress || updatingMedicationInProgress
              }
              type="submit"
            />
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default AddMedication;
