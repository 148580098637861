import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { forwardRef, useEffect } from "react";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import EditorTheme from "./EditorTheme";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import ConversionsPlugin from "./plugins/ConversionsPlugin";
import LexicalAutoLinkPlugin from "./plugins/AutoLinkPlugins";
import { ImageNode } from "./nodes/ImageNode";
import { CustomDivNode } from "./nodes/CustomDivNode";
import { ParagraphNode } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import EditorContextPlugin from "./plugins/EditorContextPlugin";

const editorConfig = {
  namespace: "React.js Demo",
  nodes: [
    HeadingNode,
    CodeHighlightNode,
    CodeNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    ListNode,
    ListItemNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
    CustomDivNode,
    {
      replace: CodeNode,
      with: (node) => {
        return new CustomDivNode();
      },
    },
  ],
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // The editor theme
  theme: EditorTheme,
};

function Placeholder() {
  return <div className="editor-placeholder">No data</div>;
}

const Editor = forwardRef(
  (
    {
      data = "",
      mainSx = {},
      editable = true,
      contentBoxSx = {},
      ShouldConvertFromMarkdownString = true,
    },
    ref,
  ) => {
    return (
      <LexicalComposer initialConfig={{ ...editorConfig, editable }}>
        <div className="editor-container" style={mainSx}>
          {editable ? <ToolbarPlugin /> : null}
          <EditorContextPlugin />
          <ConversionsPlugin
            ref={ref}
            data={data}
            ShouldConvertFromMarkdownString={ShouldConvertFromMarkdownString}
          />
          <div
            className="editor-inner"
            style={{ padding: "10px", ...contentBoxSx }}>
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <AutoFocusPlugin />
            <TablePlugin />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <LexicalAutoLinkPlugin />
          </div>
        </div>
      </LexicalComposer>
    );
  },
);

export default Editor;
