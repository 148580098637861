import CustomModal from "components/common/Modal/CustomModal";
import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";
import Button from "components/common/Button/Button";
import { memo } from "react";
import WarningIcon from "assets/svg/colored/warning";

export const SaveConfirmationModal = memo(
  ({
    open,
    onClose,
    onConfirm,
    title = "Leave Patient Intake?",
    message = "If you leave now, your progress will be lost.",
    children,
  }) => {
    return (
      <CustomModal
        open={open}
        setOpen={onClose}
        title={title}
        aria-labelledby="save-confirmation-modal-title"
        aria-describedby="save-confirmation-modal-description">
        <Box sx={{ padding: "24px 0" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <WarningIcon />
            <Text fontWeight={700} color={"warning"} variant="bodyS">
              You have unsaved changes
            </Text>
          </Box>
          <Text marginY={1} variant="bodyS">
            {children ? children : message}
          </Text>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}>
          <Button
            variant="outlined"
            text="Cancel"
            onClick={() => onClose(false)}
          />
          <Button text="OK" onClick={onConfirm} color="primary" />
        </Box>
      </CustomModal>
    );
  },
);
