import { useContext, useEffect, useState } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

export const useNavigationBlocker = ({ when, message }) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;
  const [showComponent, setShowComponent] = useState(false);
  const [navigationArgs, setNavigationArgs] = useState(null);

  // Route Change Confirmation
  useEffect(() => {
    const originalPush = navigator.push;
    const originalReplace = navigator.replace;

    const confirmNavigation = (method, ...args) => {
      const shouldBlockNavigation = typeof when === "function" ? when() : when;
      if (shouldBlockNavigation) {
        if (message) {
          // Use browser's built-in confirmation dialog
          const confirmLeave = window.confirm(message);
          if (confirmLeave) {
            method.apply(navigator, args);
          }
        } else {
          // Use custom modal
          setShowComponent(true);
          setNavigationArgs([method, ...args]);
        }
      } else {
        method.apply(navigator, args);
      }
    };

    navigator.push = (...args) => confirmNavigation(originalPush, ...args);
    navigator.replace = (...args) =>
      confirmNavigation(originalReplace, ...args);

    return () => {
      navigator.push = originalPush;
      navigator.replace = originalReplace;
    };
  }, [when, navigator, message]);

  // Tab Close / Refresh Confirmation
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldBlockNavigation = typeof when === "function" ? when() : when;
      if (shouldBlockNavigation) {
        event.preventDefault();
        event.returnValue = message || "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [when, message]);

  const confirmNavigation = () => {
    setShowComponent(false);
    if (navigationArgs) {
      const [method, ...args] = navigationArgs;
      method.apply(navigator, args);
    }
  };

  const cancelNavigation = () => {
    setShowComponent(false);
  };

  return { showComponent, confirmNavigation, cancelNavigation };
};
