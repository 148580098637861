export let getSpecificPatientStaticData = {
  id: 374,
  first_name: "Ger",
  last_name: "Han",
  gender: "Female",
  birthdate: "01-16-1958",
  address: null,
  city: "LAVA HOT SPRINGS",
  state: "ID",
  zipcode: "83246",
  patient_status: null,
  primary_insurance: null,
  fhir_resource_id: "8da27e51-da33-4eb0-847a-76a68aa21461",
  account_id: 1,
  url: "/api/patients/374/",
  medications: [],
  smart_summary_text: "### Lark Mint",
  photo: "https://nephro-media.s3.us-west-2.amazonaws.com/patients/374.jpg",
  phone_number: "208 904 5555",
};

export let healthSummaryStaticData = [
  {
    id: 260,
    optimal_start: "Yes",
    last_hospitalization: "2023-11-01",
    ros: "Swelling in ankles maybe",
    assessment_severity: 9,
    reason:
      "increased fatigue, swelling in my extremities, and changes in urination patterns.",
    ckd_status: "CKD-2",
    yes_only_fields: ["DM", "PVD", "CIRRHOSIS"],
    "yes-field-keys": ["DM", "PVD", "CIRRHOSIS"],
  },
];

export let staticAppointmentData = {
  id: 4721994,
  patient_id: 53723,
  user_id: null,
  visit_reason: "Medical; Fol min -",
  last_visit: "2024-10-04T17:58:09.816960Z",
  location_id: 68,
  practitioner_name: null,
  checkin_status: "booked",
  patient_type: null,
  datetime: "2024-10-31T21:30:00.000000Z",
  url: "http://127.0.0.1:8000/api/appointments/abc/",
  account_id: 1,
  smart_summary_text: "### Lark Mint",
  smart_summary_data: null,
  smart_checklist_data: null,
  etl_last_updated: "2024-09-19T18:25:51.903000Z",
};

export let labsDataStatic = [
  { label: "pill", valud: 1, instructions: "one at a time" },
];

export let medicineDataStatic = [
  { label: "pill", valud: 1, instructions: "one at a time" },
];

export let labResultsStaticData = [
  {
    id: 2654,
    date_time: "2024-01-20T16:48:11.272062Z",
    lab_provider: null,
    original_file_url:
      "https://nephro-media.s3.us-west-2.amazonaws.com/patients/complete-blood-count.pdf",
    practitioner_id: 3,
    patient_id: 49,
    observations: [
      {
        test_name: "Na+",
        test_code: "Na+",
        test_description: "Sodium",
        value: 141.0,
        unit: "mmol/L",
        normal_range: "135-145",
        gender: "M",
      },
      {
        test_name: "Hgb",
        test_code: "Hb",
        test_description: "Hemoglobin",
        value: 8.8,
        unit: "g/L",
        normal_range: "6-14",
        gender: "M",
      },
      {
        test_name: "BUN",
        test_code: "BUN",
        test_description: "Blood Urea Nitrogen",
        value: 15.5,
        unit: "mg/dL",
        normal_range: "7-17",
        gender: "M",
      },
      {
        test_name: "Chol",
        test_code: "Chol",
        test_description: "Cholestrol",
        value: 230.0,
        unit: "mg/dL",
        normal_range: "150-240",
        gender: "M",
      },
      {
        test_name: "UACR",
        test_code: "UACR",
        test_description: "urine albumin-creatinine ratio",
        value: 25.0,
        unit: "mg/g",
        normal_range: "10-30",
        gender: "M",
      },
      {
        test_name: "K+",
        test_code: "K+",
        test_description: "Potassium",
        value: 4.8,
        unit: "mmol/L",
        normal_range: "3.5-5.2",
        gender: "M",
      },
      {
        test_name: "Cr",
        test_code: "Cr",
        test_description: "Creatinine",
        value: 1.2,
        unit: "mg/dL",
        normal_range: "0.66-1.25",
        gender: "M",
      },
      {
        test_name: "LDL",
        test_code: "LDL",
        test_description: "Low Density Lipid",
        value: 180.0,
        unit: "mg/dL",
        normal_range: "80-200",
        gender: "M",
      },
      {
        test_name: "WBC",
        test_code: "WBC",
        test_description: "White Blood Cells",
        value: 9.2,
        unit: "x10^9/L",
        normal_range: "4.5-11",
        gender: "M",
      },
      {
        test_name: "eGFR",
        test_code: "eGFR",
        test_description: "estimated Glomerular Giltration Rate",
        value: 105.0,
        unit: "mL/min",
        normal_range: "0-120",
        gender: "M",
      },
      {
        test_name: "HPO4",
        test_code: "HPO4",
        test_description: "Phosphorus",
        value: 10.1,
        unit: "mmol/L",
        normal_range: "8.5-10.2",
        gender: "M",
      },
      {
        test_name: "RBC",
        test_code: "RBC",
        test_description: "Red Blood Cells",
        value: 3.92,
        unit: "x10^9/L",
        normal_range: "4.3-5.9",
        gender: "M",
      },
      {
        test_name: "Plt",
        test_code: "plt",
        test_description: "platelet Cells",
        value: 264.0,
        unit: "x10^9/L",
        normal_range: "150-400",
        gender: "M",
      },
    ],
  },
  {
    id: 2567,
    date_time: "2023-12-27T13:43:09.175956Z",
    lab_provider: null,
    original_file_url:
      "https://nephro-media.s3.us-west-2.amazonaws.com/patients/complete-blood-count.pdf",
    practitioner_id: 3,
    patient_id: 49,
    observations: [
      {
        test_name: "Na+",
        test_code: "Na+",
        test_description: "Sodium",
        value: 141.0,
        unit: "mmol/L",
        normal_range: "135-145",
        gender: "M",
      },
      {
        test_name: "Hgb",
        test_code: "Hb",
        test_description: "Hemoglobin",
        value: 8.8,
        unit: "g/L",
        normal_range: "6-14",
        gender: "M",
      },
      {
        test_name: "BUN",
        test_code: "BUN",
        test_description: "Blood Urea Nitrogen",
        value: 15.5,
        unit: "mg/dL",
        normal_range: "7-17",
        gender: "M",
      },
      {
        test_name: "Albumin",
        test_code: "Albumin",
        test_description: "Albumin",
        value: 7.3,
        unit: "g/dl",
        normal_range: "3.4-5.4",
        gender: "F",
      },
      {
        test_name: "Chol",
        test_code: "Chol",
        test_description: "Cholestrol",
        value: 230.0,
        unit: "mg/dL",
        normal_range: "150-240",
        gender: "M",
      },
      {
        test_name: "UACR",
        test_code: "UACR",
        test_description: "urine albumin-creatinine ratio",
        value: 25.0,
        unit: "mg/g",
        normal_range: "10-30",
        gender: "M",
      },
      {
        test_name: "K+",
        test_code: "K+",
        test_description: "Potassium",
        value: 4.8,
        unit: "mmol/L",
        normal_range: "3.5-5.2",
        gender: "M",
      },
      {
        test_name: "HcT",
        test_code: "HcT",
        test_description: "HcT",
        value: 34.3,
        unit: "%",
        normal_range: "35-50",
        gender: "F",
      },
      {
        test_name: "Cr",
        test_code: "Cr",
        test_description: "Creatinine",
        value: 0.96,
        unit: "mg/dL",
        normal_range: "0.66-1.25",
        gender: "M",
      },
      {
        test_name: "Glucose (Random)",
        test_code: "Glucose (Random)",
        test_description: "Glucose (Random)",
        value: 230.0,
        unit: "mg/dl",
        normal_range: "100-200",
        gender: "F",
      },
      {
        test_name: "LDL",
        test_code: "LDL",
        test_description: "Low Density Lipid",
        value: 180.0,
        unit: "mg/dL",
        normal_range: "80-200",
        gender: "M",
      },
      {
        test_name: "HCO3",
        test_code: "HCO3",
        test_description: "HCO3",
        value: 30.0,
        unit: "mmol/L",
        normal_range: "22-29",
        gender: "F",
      },
      {
        test_name: "WBC",
        test_code: "WBC",
        test_description: "White Blood Cells",
        value: 9.2,
        unit: "x10^9/L",
        normal_range: "4.5-11",
        gender: "M",
      },
      {
        test_name: "eGFR",
        test_code: "eGFR",
        test_description: "estimated Glomerular Giltration Rate",
        value: 105.0,
        unit: "mL/min",
        normal_range: "0-120",
        gender: "M",
      },
      {
        test_name: "HPO4",
        test_code: "HPO4",
        test_description: "Phosphorus",
        value: 10.1,
        unit: "mmol/L",
        normal_range: "8.5-10.2",
        gender: "M",
      },
      {
        test_name: "RBC",
        test_code: "RBC",
        test_description: "Red Blood Cells",
        value: 3.14,
        unit: "x10^9/L",
        normal_range: "4.3-5.9",
        gender: "M",
      },
      {
        test_name: "Plt",
        test_code: "plt",
        test_description: "platelet Cells",
        value: 264.0,
        unit: "x10^9/L",
        normal_range: "150-400",
        gender: "M",
      },
    ],
  },
];

export let labTrendsStaticData = [
  {
    id: 2468,
    date_time: "2023-12-27T13:43:09.175956Z",
    testing_lab_name: "Expresslab",
    practitioner_id: 3,
    patient_id: 524,
    observations: [
      {
        test_name: "LDL",
        test_code: "LDL",
        test_description: "Low Density Lipid",
        value: 180,
        unit: "mg/dL",
        normal_range: "80-200",
        gender: "F",
      },
      {
        test_name: "Chol",
        test_code: "Chol",
        test_description: "Cholestrol",
        value: 230,
        unit: "mg/dL",
        normal_range: "150-240",
        gender: "F",
      },
      {
        test_name: "Ca+",
        test_code: "Ca+",
        test_description: "Calcium",
        value: 10.1,
        unit: "mmol/L",
        normal_range: "8.5-10.2",
        gender: "F",
      },
      {
        test_name: "K+",
        test_code: "K+",
        test_description: "Potassium",
        value: 4.8,
        unit: "mmol/L",
        normal_range: "3.5-5.2",
        gender: "F",
      },
      {
        test_name: "Na+",
        test_code: "Na+",
        test_description: "Sodium",
        value: 141,
        unit: "mmol/L",
        normal_range: "135-145",
        gender: "F",
      },
      {
        test_name: "UACR",
        test_code: "UACR",
        test_description: "urine albumin-creatinine ratio",
        value: 25,
        unit: "mg/g",
        normal_range: "10-30",
        gender: "F",
      },
      {
        test_name: "eGFR",
        test_code: "eGFR",
        test_description: "estimated Glomerular Giltration Rate",
        value: 105,
        unit: "mL/min",
        normal_range: "0-120",
        gender: "F",
      },
      {
        test_name: "BUN",
        test_code: "BUN",
        test_description: "Blood Urea Nitrogen",
        value: 15.5,
        unit: "mg/dL",
        normal_range: "7-17",
        gender: "F",
      },
      {
        test_name: "Cr",
        test_code: "Cr",
        test_description: "Creatinine",
        value: 1.2,
        unit: "mg/dL",
        normal_range: "0.66-1.25",
        gender: "F",
      },
      {
        test_name: "Plt",
        test_code: "plt",
        test_description: "platelet Cells",
        value: 330,
        unit: "x10^9/L",
        normal_range: "150-400",
        gender: "F",
      },
      {
        test_name: "WBC",
        test_code: "WBC",
        test_description: "White Blood Cells",
        value: 9.2,
        unit: "x10^9/L",
        normal_range: "4.5-11",
        gender: "F",
      },
      {
        test_name: "RBC",
        test_code: "RBC",
        test_description: "Red Blood Cells",
        value: 4.9,
        unit: "x10^9/L",
        normal_range: "4.3-5.9",
        gender: "F",
      },
      {
        test_name: "Hgb",
        test_code: "Hb",
        test_description: "Hemoglobin",
        value: 8.8,
        unit: "g/L",
        normal_range: "6-14",
        gender: "F",
      },
    ],
  },
];

export let medicationsStaticData = [
  {
    patient_id: 314,
    prescription_id: 12,
    prescribed_on: "2021-09-24T19:59:00.000000Z",
    discontinued_on: "1988-10-04T20:06:00.000000Z",
    drug_name: "Axel Barton",
    status_ind: "0",
    instructions: "Take 3 times a day",
    account_id: 1,
  },
  {
    patient_id: 314,
    prescription_id: 13,
    prescribed_on: "2021-09-24T19:59:00.000000Z",
    discontinued_on: "1988-10-04T20:06:00.000000Z",
    drug_name: "Axel Barton",
    status_ind: "0",
    instructions: "Sunt non natus paria",
    account_id: 1,
  },
  {
    patient_id: 314,
    prescription_id: 14,
    prescribed_on: "2021-09-24T19:59:00.000000Z",
    discontinued_on: "1988-10-04T20:06:00.000000Z",
    drug_name: "Axel Barton",
    status_ind: "0",
    instructions: "Sunt non natus paria",
    account_id: 1,
  },
  {
    patient_id: 314,
    prescription_id: 15,
    prescribed_on: "1994-05-03T11:43:00.000000Z",
    discontinued_on: "1973-09-28T05:56:00.000000Z",
    drug_name: "Brendan Walton",
    status_ind: "0",
    instructions: "Ut voluptate non qui",
    account_id: 1,
  },
];

export let staticMedicineData = [
  {
    id: 10,
    drug_name: "Antiplatelet Agents",
    purpose:
      "stop cells in the blood (platelets) from sticking together and forming a clot",
    account_id: 1,
  },
  {
    id: 9,
    drug_name: "Insulin or Oral Antidiabetic Medications",
    purpose:
      "To help lower blood glucose levels (and your A1c) to a healthy range",
    account_id: 1,
  },
  {
    id: 8,
    drug_name: "Antihypertensive Medications",
    purpose: "Used to treat hypertension (high blood pressure)",
    account_id: 1,
  },
  {
    id: 7,
    drug_name: "Statins",
    purpose: "lower cholesterol",
    account_id: 1,
  },
  {
    id: 6,
    drug_name: "Calcitriol or Vitamin D Analogues",
    purpose:
      "hyperproliferative diseases, immune dysfunction, endocrine disorders, and metabolic bone diseases",
    account_id: 1,
  },
  {
    id: 5,
    drug_name: "Phosphate Binders",
    purpose:
      "Used to reduce positive phosphate balance and to lower serum phosphate levels for people with chronic kidney disease (CKD)",
    account_id: 1,
  },
  {
    id: 4,
    drug_name: "Erythropoiesis-Stimulating Agents (ESAs)",
    purpose: "Stimulate the bone marrow to make red blood cells",
    account_id: 1,
  },
  {
    id: 2,
    drug_name: "Angiotensin II Receptor Blockers (ARBs)",
    purpose: "Used to treat high blood pressure and heart failure.",
    account_id: 1,
  },
  {
    id: 1,
    drug_name: "Angiotensin-Converting Enzyme Inhibitors (ACE Inhibitors)",
    purpose: "Used to treat and manage hypertension",
    account_id: 1,
  },
];

export let labsByEncounterDataStatic = [
  {
    id: 4539,
    datetime: "2024-02-29T13:09:56.686084Z",
    instructions: null,
    patient_id: 647,
    practitioner_id: 143,
    lab_location_id: 4,
    lab_provider_id: 1,
    test_id: 23,
    encounter_id: 967,
    url: "http://127.0.0.1:8000/api/laborders/4539/",
    account_id: 1,
    lab_location: {
      id: 4,
      lab_provider_id: 1,
      lab_location_name: "Pocatello",
      address: "444 Hospital Way, Building 100, Suite 224 Pocatello, Idaho",
      phone: "208-529-8330",
      account_id: 1,
    },
    test: {
      id: 23,
      group_test_name: "Lipid profile",
      test_name: "Lipid profile",
      test_code: "Lipid profile",
      test_description: "Lipid profile",
      group_test_order: 5,
      test_name_order: 1,
      account: "http://127.0.0.1:8000/api/accounts/1/",
      account_id: 1,
    },
    lab_provider: {
      id: 1,
      lab_provider_name: "Express Lab",
      address: "3910 Washington Parkway, Idaho Falls, Idaho",
      phone: "208-529-8330",
      url: "http://127.0.0.1:8000/api/labproviders/1/",
      account_id: 1,
    },
  },
];

export let medicinesEncounterDataOptionsStatic = {
  id: 8279,
  patient_id: 647,
  prescribed_on: "2024-03-22T17:30:00.000000Z",
  discontinued_on: null,
  medicine_id: 26,
  drug_name: "Renvela 800mg",
  status_ind: "1",
  instructions: "3 times with each meal",
  dosage: "800mg after meal",
  dispense: "10",
  encounter_id: 967,
  account_id: 1,
  pharmacy_id: null,
  external_ids: null,
};

export const staticPrescriptionsData = [
  {
    id: "2db41880-e02f-4f95-acce-8fac26b008b0",
    patient_id: 2142,
    prescribed_on: "2024-09-27T12:30:37.461000Z",
    discontinued_on: null,
    drug_name: "Abacavir Sulfate Oral Solution 20 MG/ML",
    dosage: "",
    dispensable_drug_id: 38548,
    dispense_unit_id: 4,
    instructions: "dsad",
    encounter_id: 1530,
    pharmacy_id: 5,
    sent_to_pharmacy: false,
    order_error_message: "",
    account_id: 1,
  },
];

export const amountOptions = [
  { label: "0.25", value: "0.25" },
  { label: "0.5", value: "0.5" },
  { label: "1", value: "1" },
  { label: "1.5", value: "1.5" },
  { label: "2", value: "2" },
  { label: "2.5", value: "2.5" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];

export const frequencyOptions = [
  { label: "Once daily", value: "Once daily" },
  { label: "Twice daily", value: "Twice daily" },
  { label: "Three times daily", value: "Three times daily" },
  { label: "Four times daily", value: "Four times daily" },
  { label: "Every hour", value: "Every hour" },
  { label: "Every two hours", value: "Every two hours" },
  { label: "Every three hours", value: "Every three hours" },
  { label: "Every four hours", value: "Every four hours" },
  { label: "Every six hours", value: "Every six hours" },
  { label: "Every eight hours", value: "Every eight hours" },
  { label: "Every twelve hours", value: "Every twelve hours" },
  {
    label: "Four times daily, as needed",
    value: "Four times daily, as needed",
  },
  { label: "Two times a week", value: "Two times a week" },
  { label: "Three times a week", value: "Three times a week" },
  { label: "Once a week", value: "Once a week" },
];

export const dosageMapping = {
  "Once daily": { dosage_period: 1, dosage_period_unit: "d" },
  "Twice daily": { dosage_period: 2, dosage_period_unit: "d" },
  "Three times daily": { dosage_period: 3, dosage_period_unit: "d" },
  "Four times daily": { dosage_period: 4, dosage_period_unit: "d" },
  "Every hour": { dosage_period: 24, dosage_period_unit: "d" },
  "Every two hours": { dosage_period: 12, dosage_period_unit: "d" },
  "Every three hours": { dosage_period: 8, dosage_period_unit: "d" },
  "Every four hours": { dosage_period: 6, dosage_period_unit: "d" },
  "Every six hours": { dosage_period: 4, dosage_period_unit: "d" },
  "Every eight hours": { dosage_period: 3, dosage_period_unit: "d" },
  "Every twelve hours": { dosage_period: 2, dosage_period_unit: "d" },
  "Four times daily, as needed": { dosage_period: 4, dosage_period_unit: "d" },
  "Two times a week": { dosage_period: 2, dosage_period_unit: "w" },
  "Three times a week": { dosage_period: 3, dosage_period_unit: "w" },
  "Once a week": { dosage_period: 1, dosage_period_unit: "w" },
};
