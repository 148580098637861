import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from "./base";

export const fetchPatientLabs = async (patientId, { pageParam }) => {
  const url = `/api/patients/${patientId}/labtestrequest/`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const fetchPifLabs = async (patientId) => {
  const url = `/api/patients/${patientId}/labrequest/external/`;
  const response = await apiGet(url);
  return response.data;
};

export const addPifLab = async (patientId, data) => {
  const url = `/api/patients/${patientId}/labtestrequest/external/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updatePifLab = async (
  patientId,
  labtestId,
  data,
  isLabTestReq = false,
) => {
  const url = isLabTestReq
    ? `/api/patients/${patientId}/labtestrequest/${labtestId}/`
    : `/api/patients/${patientId}/labrequest/${labtestId}/`;
  const response = await apiPatch(url, data);
  return response.data;
};

export const deletePifLab = async (patientId, labtestId) => {
  const url = `/api/patients/${patientId}/labrequest/${labtestId}/`;
  const response = await apiDelete(url);
  return response.data;
};

export const addLabTest = async (patientId, data) => {
  const url = `/api/patients/${patientId}/labtestrequest/`;
  const response = await apiPost(url, data);
  return response.data;
};

export const updateLabTest = async (patientId, labtestId, data) => {
  const url = `/api/patients/${patientId}/labtestrequest/${labtestId}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const deleteLabTest = async (patientId, labtestId) => {
  const url = `/api/patients/${patientId}/labtestrequest/${labtestId}/`;
  const response = await apiDelete(url);
  return response.data;
};
