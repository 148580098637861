export const sortWeights = {
  "eGFR non-Afr. American": 0,
  "eGFR African American": 1,
  "Urea Nitrogen (BUN)": 2,
  "BUN/Creatinine Ratio": 3,
  Creatinine: 4,
  Sodium: 5,
  Potassium: 6,
  Hgb: 7,
  Hct: 8,
  PLT: 9,
  HCO3: 10,
};

const labResultsIndexes = {
  creatinine: 0,
  eGFR: 1,
  tacrolimus: 2,
  everolimus: 3,
  cyclosporine: 4,
  BUN: 5,
  UACR: 6,
  UPCR: 7,
  Na: 8,
  K: 9,
  bicarb: 10,
  bicarbCO2: 11,
  bicarbHCO3: 12,
  CO2: 13,
  HCO3: 14,
  magnesium: 15,
  albumin: 16,
  calcium: 17,
  HPO4: 18,
  PTH: 19,
  vitaminD: 20,
  WBC: 21,
  HGB: 22,
  platelets: 23,
  Tsat: 24,
  ferritin: 25,
  iron: 26,
  chol: 27,
  LDL: 28,
};

const labResultsKeys = [
  "Creatinine",
  "eGFR (5.1)",
  "Tacrolimus",
  "Everolimus",
  "Cyclosporine",
  "BUN",
  "UACR",
  "UPCR",
  "Na",
  "Sodium",
  "Sodium (Na+)",
  "K",
  "Potassium",
  "Potassium (K)",
  "Bicarb",
  "CO2",
  "Bicarb (CO2)",
  "HCO3",
  "Bicarb (HCO3)",
  "Carbon Dioxide",
  "Carbon Dioxide (CO2)",
  "Mg+",
  "Magnesium",
  "Magnesium (Mg+)",
  "Albumin",
  "Ca",
  "Calcium",
  "Calcium (Ca)",
  "HPO4",
  "PTH",
  "Vitamin D",
  "WBC",
  "HGB",
  "PLT",
  "Platelets",
  "Platelets (PLT)",
  "Tsat",
  "Ferritin",
  "Fe",
  "Iron",
  "Iron (Fe)",
  "Chol",
  "LDL",
];

function checkReferenceRange(range, value) {
  if (!range?.includes("-")) {
    return true;
  }

  const cleanRange = range.replace(/[\[\]]/g, "");

  const parts = cleanRange.split("-").map(Number);
  const upperLimit = parts[1];

  return Number(value) > upperLimit;
}

export const getSortedFormattedLabs = (data) => {
  let count = 0;
  const allLabObservations = [];
  let scannedLabsArePresent = false;

  data?.lab_tests?.forEach((labTest) => {
    labTest?.lab_observations.forEach((details) => {
      const value = details?.value;
      //added this check to avoid the values which are empty
      if (value?.[0] !== "") {
        let labValue = `${details?.value}${details?.unit ? ` ${details.unit}` : ""}`;
        let labRange = `${details?.reference_range}${details?.unit ? ` ${details.unit}` : ""}`;

        let lab = {
          val: labValue,
          range: labRange,
          code: details?.name,
          at_risk: details?.in_range,
          documentId: details?.document_id,
        };

        if (details?.document_id && !scannedLabsArePresent) {
          scannedLabsArePresent = true;
        }

        if (details?.in_range === "at_risk") {
          count++;
        }
        allLabObservations.push(lab);
      }
    });
  });

  let fixedLabs = [
    {
      val: "",
      range: null,
      at_risk: null,
      code: "Creatinine",
    },
    {
      val: "",
      range: null,
      at_risk: null,
      code: "eGFR (5.1)",
    },
  ];

  fixedLabs.forEach((lab) => {
    if (!allLabObservations.some((l) => l.code === lab.code)) {
      allLabObservations.push(lab);
    }
  });

  let sortedLabsObservations = allLabObservations.sort((a, b) => {
    const indexA = labResultsKeys.indexOf(a.code);
    const indexB = labResultsKeys.indexOf(b.code);
    return (
      (indexA === -1 ? labResultsKeys.length : indexA) -
      (indexB === -1 ? labResultsKeys.length : indexB)
    );
  });

  return {
    count,
    sortedLabsObservations,
    scannedLabsArePresent,
  };
};
