import { apiGet, apiPost, apiPut, parseQueryOptions } from "./base";

export const fetchPatient = async (patientId) => {
  const url = `/api/patients/${patientId}/`;
  const response = await apiGet(url);
  return response.data;
};

export const putPatientData = async (patientId, data) => {
  const url = `/api/patients/${patientId}/`;
  const response = await apiPut(url, data);
  return response.data;
};

export const fetchPatients = async (queryOptions, { pageParam }) => {
  let url;
  if (queryOptions?.filter?.field === "name") {
    url = `/api/patients/search?name=${queryOptions?.filter?.value}&sort_by=${queryOptions?.sort?.field}&sort_order=${queryOptions?.sort?.order}${queryOptions?.page ? `&page=${queryOptions?.page}` : ""}`;
  } else {
    url = `/api/patients/?${parseQueryOptions(queryOptions)}`;
  }
  const response = await apiGet(pageParam ?? url);
  return response.data;
};
export const fetchPatientsLastEncounter = async (date, { pageParam }) => {
  let url = `/api/patients/search/?last_encounter_date=${date}`;
  const response = await apiGet(pageParam ?? url);
  return response.data;
};

export const refreshSmartSummary = async (patientId) => {
  const url = `/api/patients/refresh_smart_summary/?patient_id=${patientId}`;
  const response = await apiPost(url);
  return response.data;
};
