import { Box, List, ListItem, ListItemIcon, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "styles/index.css";
import { Person } from "components/common/SVG/Person";
import CustomTooltip from "components/common/Tooltips/CustomTooltip";
import PendingIcon from "assets/svg/bold/pending";
import ScheduleIcon from "components/common/SVG/schedule";
import { useDispatch, useSelector } from "react-redux";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import { fetchOpenEncounters } from "apiClients/encounters";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { setEncounterOpenCountDetails } from "redux/features/Encounter/EncounterSlice";

const SidebarRoot = styled(Box)(() => ({
  height: "100%",
  "& .MuiButtonBase-root.MuiListItem-root": {
    paddingLeft: "10px !important",
  },
  "& .MuiListItemIcon-root": {
    width: "50px",
    height: "100%",
    "& .MuiSvgIcon-root": {
      width: "100%",
      height: "35px",
    },
  },
}));

const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  fill: isActive ? theme.palette.themePrimary.tertiary : "inherit",
}));

function Sidebar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routerLocation = useLocation();

  const [active, setActive] = useState(() => {
    return localStorage.getItem("active") || routerLocation.pathname;
  });

  const { openEncountersCount, openEncountersProcessing } = useSelector(
    (state) => state.encounter,
  );

  const { loggedIn } = useSelector((state) => state.auth);

  const { data: openEncounterData, isFetching: isFetchingOpenEncounter } =
    useQuery({
      enabled: loggedIn,
      queryKey: ["openEncountersOnce"], // Same key to use cached data
      queryFn: async () => fetchOpenEncounters(0),
      staleTime: 10 * 60 * 1000, // Cache for 10 minutes
      refetchInterval: 2 * 60 * 1000, // Refetch every 10 minutes
    });

  useEffect(() => {
    if (!openEncountersCount) {
      dispatch(
        setEncounterOpenCountDetails({
          count: openEncounterData?.count,
          isLoading: isFetchingOpenEncounter,
        }),
      );
    }
  }, [openEncounterData]);

  let countBoxStyling = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "15px",
    left: "11px",
    fontSize: "11px",
    height: "20px",
    width: "30px",
  };
  const upperArray = [
    {
      id: "encounters",
      IconComponent: () => (
        <Box sx={{ position: "relative" }}>
          <PendingIcon width="30" height="30" />
          {active !== "/encounters" && (
            <SkeletonWrapper
              condition={!openEncountersProcessing}
              props={[SkeletonPropsStyleGenerator("100%", "30px")]}
              sx={countBoxStyling}>
              <Box
                sx={{
                  ...countBoxStyling,
                  borderRadius: "50px",
                  background: theme.palette.primary.main,
                  color: theme.palette.background.paper,
                }}>
                {openEncountersCount}
              </Box>
            </SkeletonWrapper>
          )}
        </Box>
      ),
      path: "/encounters",
      title: "Open Encounters",
    },
    {
      id: "appointments",
      IconComponent: ScheduleIcon,
      path: "/appointments",
      title: "Appointments",
    },
    {
      id: "patients",
      IconComponent: Person,
      path: "/patients",
      title: "Patients",
    },
  ];

  useEffect(() => {
    setActive(routerLocation.pathname);
  }, [routerLocation.pathname]);

  useEffect(() => {
    localStorage.setItem("active", active);
  }, [active]);

  const handlePaths = (path) => {
    navigate(path);
    setActive(path);
  };

  return (
    <SidebarRoot>
      <List sx={{ alignContent: "center" }}>
        {upperArray.map(({ IconComponent, title, path, id }, index) => {
          const isActive = active === path;
          return (
            <ListItem
              key={index}
              data-testid={`sidebar-icon-${id}-id`}
              onClick={() => handlePaths(path)}
              sx={{
                position: "relative",
                marginTop: "10px",
                cursor: "pointer",
              }}>
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <CustomTooltip tooltipText={title} placement="right">
                  <IconWrapper isActive={isActive} data-testid={id}>
                    <IconComponent />
                  </IconWrapper>
                </CustomTooltip>
              </ListItemIcon>
              <Box
                sx={{
                  position: "absolute",
                  right: "0px",
                  top: "4px",
                  height: "40px",
                  border: isActive
                    ? `3px solid ${theme.palette.themePrimary.tertiary}`
                    : "",
                  borderRadius: "20px",
                }}
                data-testid={`${id}-active-bar`}
              />
            </ListItem>
          );
        })}
      </List>
    </SidebarRoot>
  );
}

export default Sidebar;
