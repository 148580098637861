import { memo } from "react";

export const Favorite = memo(({ fill = "#2D3748" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none">
      <mask
        id="mask0_10424_31233"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_10424_31233)">
        <path
          d="M7.37485 14.0209L9.99985 12.4375L12.6248 14.0417L11.9373 11.0417L14.2498 9.04169L11.2082 8.77085L9.99985 5.93752L8.79152 8.75002L5.74985 9.02085L8.06235 11.0417L7.37485 14.0209ZM9.99985 14.3959L6.54152 16.4792C6.38874 16.5764 6.22902 16.6181 6.06235 16.6042C5.89568 16.5903 5.74985 16.5347 5.62485 16.4375C5.49985 16.3403 5.40263 16.2188 5.33318 16.0729C5.26374 15.9271 5.24985 15.7639 5.29152 15.5834L6.20818 11.6459L3.14568 9.00002C3.00679 8.87502 2.91999 8.73266 2.88527 8.57294C2.85054 8.41321 2.86096 8.25696 2.91652 8.10419C2.97207 7.95141 3.05541 7.82641 3.16652 7.72919C3.27763 7.63196 3.43041 7.56946 3.62485 7.54169L7.66652 7.18752L9.22902 3.47919C9.29846 3.31252 9.4061 3.18752 9.55193 3.10419C9.69777 3.02085 9.84707 2.97919 9.99985 2.97919C10.1526 2.97919 10.3019 3.02085 10.4478 3.10419C10.5936 3.18752 10.7012 3.31252 10.7707 3.47919L12.3332 7.18752L16.3748 7.54169C16.5693 7.56946 16.7221 7.63196 16.8332 7.72919C16.9443 7.82641 17.0276 7.95141 17.0832 8.10419C17.1387 8.25696 17.1492 8.41321 17.1144 8.57294C17.0797 8.73266 16.9929 8.87502 16.854 9.00002L13.7915 11.6459L14.7082 15.5834C14.7498 15.7639 14.736 15.9271 14.6665 16.0729C14.5971 16.2188 14.4998 16.3403 14.3748 16.4375C14.2498 16.5347 14.104 16.5903 13.9373 16.6042C13.7707 16.6181 13.611 16.5764 13.4582 16.4792L9.99985 14.3959Z"
          fill={fill}
        />
      </g>
    </svg>
  );
});
