import { memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Box,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Text from "components/common/Typography/Text";
import Button from "../Button/Button";
import { cardButtonStyling } from "styles/Dashboard/SchedulePatientsStyle";
import { dateFormat } from "utils/date";
import AvatarPic from "../../../assets/images/profileAvatar.png";
import { checkNullOrUndefinedString } from "utils/helper";

const ListViewSchedules = ({
  schedules, // Array of schedule objects
  setPatientId,
  visitSummaryLoading,
  patientId,
  activeRowIndex, // For styling active row
}) => {
  const navigate = useNavigate();
  const customTheme = useTheme();

  return (
    <TableContainer
      sx={{ display: "flex", justifyContent: "center", padding: "2px" }}>
      <Table sx={{ width: "99%" }}>
        <colgroup>
          <col style={{ width: "10%" }} />
          <col style={{ width: "20%", maxWidth: "30%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "15%" }} />
        </colgroup>
        <TableHead
          sx={{
            background: "#eff4fc",
            borderRadius: "10px",
            borderBottom: "2px solid #e8e8e8",
          }}>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: "700" }}>
              Time
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "700" }}>
              Name
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "700" }}>
              Patient ID
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "700" }}>
              Last Visit
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "700" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules?.map((el, index) => (
            <TableRow
              key={index}
              hover
              sx={{
                cursor: "pointer",
                borderRadius: "10px",
                ...(activeRowIndex === index
                  ? { outline: "1.5px solid #1344f1" }
                  : {}),
              }}>
              {/* Time Column */}
              <TableCell align="center">
                <Text variant="bodyS">
                  {moment(el?.datetime).format("h:mm A")}
                </Text>
              </TableCell>

              {/* Name Column */}
              <TableCell align="center" sx={{ width: "200px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <Avatar
                    alt={checkNullOrUndefinedString(
                      el?.patient?.first_name || "Patient",
                    )}
                    src={el?.patient?.photo || AvatarPic}
                    sx={{
                      marginLeft: "60px",
                      marginRight: "0px",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      width: "100%",
                      marginLeft: "10px",
                    }}>
                    <Link
                      to={`/schedule/patient/${el?.patient_id}/appointment/${el?.id}`}
                      style={{
                        color: customTheme.palette.common.blue,
                        textDecoration: "none",
                      }}
                      onClick={(e) => e.stopPropagation()}>
                      <Text variant="h3" color={"blue"}>
                        {checkNullOrUndefinedString(
                          el?.patient?.first_name +
                            " " +
                            el?.patient?.last_name,
                        )}
                      </Text>
                    </Link>
                  </Box>
                </Box>
              </TableCell>

              {/* Patient ID Column */}
              <TableCell align="center">
                <Text variant="bodyS">{el?.patient?.emr_id}</Text>
              </TableCell>

              {/* Last Visit Column */}
              <TableCell align="center">
                <Text variant="bodyS">
                  {el?.last_visit
                    ? moment(el?.last_visit).format(dateFormat)
                    : "N/A"}
                </Text>
              </TableCell>

              {/* Actions Column */}
              <TableCell align="center">
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "center",
                  }}>
                  <Button
                    disabled={
                      el?.patient?.has_patient_visit_summary ? false : true
                    }
                    isLoading={
                      el?.patient_id === patientId && visitSummaryLoading
                    }
                    variant="outlined"
                    styleType={
                      activeRowIndex === index ? "primary" : "inactive"
                    }
                    sx={cardButtonStyling()}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      setPatientId(el?.patient_id);
                    }}
                    text={"Visit Summary"}
                  />
                  <Button
                    id="start-patient-intake"
                    styleType={
                      activeRowIndex === index ? "primary" : "inactive"
                    }
                    sx={cardButtonStyling()}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      navigate(`/patients/${el?.patient_id}`, {
                        state: { from: "/appointments", intakeFlag: true },
                      });
                    }}
                    text={"Patient Intake"}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(ListViewSchedules);
