import { useState } from "react";
import { Checkbox, FormControlLabel, Grid, useTheme } from "@mui/material";
import Text from "../Typography/Text";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      width: "24px",
      height: "24px",
    },
  },
}));

const CheckBoxField = ({
  label,
  checkLabel,
  name,
  checked,
  onChange,
  labelPlacement = "start",
  cols = 6,
  required = false,
  formik,
  margin = "20px 0 0 0",
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [internalChecked, setInternalChecked] = useState(checked ?? false);

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setInternalChecked(newValue);

    if (onChange) {
      onChange(event);
    } else if (formik) {
      formik.setFieldValue(name, newValue);
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flex: "none !important",
        alignItems: "center",
        justifyContent: "flex-start",
        margin,
      }}
      item
      xs={12}
      sm={cols}>
      {label && <Text variant="body2">{label}</Text>}
      <FormControlLabel
        sx={{
          gap: 1,
          "&.MuiFormControlLabel-label": {
            fontSize: "15px",
            width: "auto",
          },
        }}
        control={
          <Checkbox
            className={classes.root}
            id={name}
            name={name}
            size="large"
            checked={internalChecked}
            value={internalChecked}
            onChange={handleChange}
          />
        }
        label={checkLabel ? `${checkLabel} ${required ? "*" : ""}` : ""}
        labelPlacement={labelPlacement}
      />
    </Grid>
  );
};

export default CheckBoxField;
