import { Box, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useNavigate } from "react-router-dom";
import { flexColumn, justifyContentCenter } from "styles";
import {
  cardButtonStyling,
  cardDateTimeStatusStyling,
  cardNameDiseaseImageBoxStyling,
  cardReasonWrapperStyling,
  cardResponsiveStyling,
  cardVisitReasonBoxStyling,
} from "styles/Dashboard/SchedulePatientsStyle";
import { checkNullOrUndefinedString } from "utils/helper";
import AvatarPic from "assets/images/profileAvatar.png";
import { useSwiper } from "swiper/react";
import { dateFormat } from "utils/date";

const Cards = ({
  id,
  time,
  status,
  name,
  hasPatientSummary,
  disease,
  description,
  lastVisitDate,
  emrId,
  visitSummaryLoading,
  isActive,
  patientId,
  summaryVisitId,
  setPatientId,
  appointmentId,
  index,
  photo,
}) => {
  let theme = useTheme();
  let swiper = useSwiper();
  const navigate = useNavigate();
  const [_, setFontSize] = useState(16);

  useEffect(() => {
    const handleResize = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      );
      const newFontSize = vw / 20;
      setFontSize(newFontSize);
    };

    // Initial font size calculation
    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      id={id}
      className="main-card-body"
      sx={{
        ...cardResponsiveStyling(status, theme, isActive),
      }}
      onClick={() => {
        swiper.slideTo(index);
      }}>
      <Box
        sx={{
          flex: 1,
          [`@media  (min-height:500px) and (max-height:630px)`]: {
            flex: "unset",
          },
        }}>
        <Box
          onClick={() =>
            navigate(
              `/schedule/patient/${patientId}/appointment/${appointmentId}`,
            )
          }>
          <Box sx={cardDateTimeStatusStyling}>
            <Text classes={"card-time"} variant="h2" sx={{ fontWeight: "700" }}>
              <span data-testid="card-time">
                {moment(time).format("hh:mm A")}
              </span>
            </Text>
          </Box>
          <Box
            sx={cardNameDiseaseImageBoxStyling}
            className="patient-profile-box">
            <Box style={{ ...flexColumn, ...justifyContentCenter }}>
              <Text
                variant="h1"
                fontWeight={700}
                classes="patient-name"
                color={`${isActive ? "oceanBlue" : "black"}`}
                sx={{ fontSize: "1.35rem !important", marginRight: "5px" }}
                margin="0px">
                <span style={{ textWrap: "wrap" }} data-testid={"card-name"}>
                  {checkNullOrUndefinedString(name)}
                </span>
              </Text>
              <Text
                classes={"card-disease"}
                marginY={1}
                variant="modalLabel"
                sx={{ color: "#625f67" }}
                fontWeight={"600"}>
                <span>Patient ID: {emrId}</span>
              </Text>
              <Text
                classes={"card-disease"}
                variant="h2"
                margin="5px 0px 0px 0px">
                <span data-testid={"card-disease"}>{disease}</span>
              </Text>
            </Box>
            <img
              data-testid="patient-img"
              src={photo || AvatarPic}
              alt={`${name || ""}`}
              style={{
                borderRadius: "100%",
                width: "124px",
                height: "124px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}>
            <Text variant="h4" fontWeight={"700"}>
              Last Visit:
            </Text>
            <Text
              variant="h4"
              padding="none"
              sx={{ marginLeft: "5px" }}
              data-testid="card-date-visit">
              {lastVisitDate ? moment(lastVisitDate).format(dateFormat) : "N/A"}
            </Text>
          </Box>
          <Box sx={cardReasonWrapperStyling}>
            <Box sx={{ flex: "2" }}>
              <Text variant="h4" fontWeight={700}>
                Reason for the visit:
              </Text>
              <Box className="visit-reason-box" sx={cardVisitReasonBoxStyling}>
                <Text
                  variant="h4"
                  fontWeight={400}
                  color="black"
                  letterSpacing="0.5px"
                  sx={{ whiteSpace: "break-spaces" }}>
                  <span data-testid="card-reason-for-visit">{description}</span>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="button-box"
          sx={{
            height: "10%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}>
          <Button
            disabled={hasPatientSummary || !isActive}
            variant="outlined"
            isLoading={patientId == summaryVisitId && visitSummaryLoading}
            styleType={isActive ? "primary" : "inactive"}
            sx={cardButtonStyling()}
            onClick={(e) => {
              e.stopPropagation();
              setPatientId(patientId);
            }}
            text={"Visit Summary"}
          />
          <Button
            disabled={!isActive}
            id="start-patient-intake"
            styleType={isActive ? "primary" : "inactive"}
            sx={{
              ...cardButtonStyling(),
              backgroundColor: !isActive ? "#bfbfbf !important" : "",
              color: !isActive ? "white !important" : "",
            }}
            onClick={() =>
              navigate(`/patients/${patientId}`, {
                state: { from: "/appointments", intakeFlag: true },
              })
            }
            text={"Patient Intake"}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default memo(Cards);
