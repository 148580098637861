export const RoundExclamatory = ({ fill = "#7119FF" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_7080_4445"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7080_4445)">
        <path
          d="M10.0001 14.1666C10.2362 14.1666 10.4341 14.0868 10.5938 13.927C10.7536 13.7673 10.8334 13.5694 10.8334 13.3333C10.8334 13.0972 10.7536 12.8993 10.5938 12.7395C10.4341 12.5798 10.2362 12.5 10.0001 12.5C9.76397 12.5 9.56605 12.5798 9.40633 12.7395C9.24661 12.8993 9.16675 13.0972 9.16675 13.3333C9.16675 13.5694 9.24661 13.7673 9.40633 13.927C9.56605 14.0868 9.76397 14.1666 10.0001 14.1666ZM9.16675 10.8333H10.8334V5.83329H9.16675V10.8333ZM10.0001 18.3333C8.8473 18.3333 7.76397 18.1145 6.75008 17.677C5.73619 17.2395 4.85425 16.6458 4.10425 15.8958C3.35425 15.1458 2.7605 14.2638 2.323 13.25C1.8855 12.2361 1.66675 11.1527 1.66675 9.99996C1.66675 8.84718 1.8855 7.76385 2.323 6.74996C2.7605 5.73607 3.35425 4.85413 4.10425 4.10413C4.85425 3.35413 5.73619 2.76038 6.75008 2.32288C7.76397 1.88538 8.8473 1.66663 10.0001 1.66663C11.1529 1.66663 12.2362 1.88538 13.2501 2.32288C14.264 2.76038 15.1459 3.35413 15.8959 4.10413C16.6459 4.85413 17.2397 5.73607 17.6772 6.74996C18.1147 7.76385 18.3334 8.84718 18.3334 9.99996C18.3334 11.1527 18.1147 12.2361 17.6772 13.25C17.2397 14.2638 16.6459 15.1458 15.8959 15.8958C15.1459 16.6458 14.264 17.2395 13.2501 17.677C12.2362 18.1145 11.1529 18.3333 10.0001 18.3333ZM10.0001 16.6666C11.8612 16.6666 13.4376 16.0208 14.7292 14.7291C16.0209 13.4375 16.6667 11.8611 16.6667 9.99996C16.6667 8.13885 16.0209 6.56246 14.7292 5.27079C13.4376 3.97913 11.8612 3.33329 10.0001 3.33329C8.13897 3.33329 6.56258 3.97913 5.27092 5.27079C3.97925 6.56246 3.33341 8.13885 3.33341 9.99996C3.33341 11.8611 3.97925 13.4375 5.27092 14.7291C6.56258 16.0208 8.13897 16.6666 10.0001 16.6666Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
