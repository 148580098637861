const HelpIconFilled = ({ fill = "#246EFD" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.95 16C10.3 16 10.5958 15.8792 10.8375 15.6375C11.0792 15.3958 11.2 15.1 11.2 14.75C11.2 14.4 11.0792 14.1042 10.8375 13.8625C10.5958 13.6208 10.3 13.5 9.95 13.5C9.6 13.5 9.30417 13.6208 9.0625 13.8625C8.82083 14.1042 8.7 14.4 8.7 14.75C8.7 15.1 8.82083 15.3958 9.0625 15.6375C9.30417 15.8792 9.6 16 9.95 16ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10.1 5.7C10.5167 5.7 10.8792 5.83333 11.1875 6.1C11.4958 6.36667 11.65 6.7 11.65 7.1C11.65 7.46667 11.5375 7.79167 11.3125 8.075C11.0875 8.35833 10.8333 8.625 10.55 8.875C10.1667 9.20833 9.82917 9.575 9.5375 9.975C9.24583 10.375 9.1 10.825 9.1 11.325C9.1 11.5583 9.1875 11.7542 9.3625 11.9125C9.5375 12.0708 9.74167 12.15 9.975 12.15C10.225 12.15 10.4375 12.0667 10.6125 11.9C10.7875 11.7333 10.9 11.525 10.95 11.275C11.0167 10.925 11.1667 10.6125 11.4 10.3375C11.6333 10.0625 11.8833 9.8 12.15 9.55C12.5333 9.18333 12.8625 8.78333 13.1375 8.35C13.4125 7.91667 13.55 7.43333 13.55 6.9C13.55 6.05 13.2042 5.35417 12.5125 4.8125C11.8208 4.27083 11.0167 4 10.1 4C9.46667 4 8.8625 4.13333 8.2875 4.4C7.7125 4.66667 7.275 5.075 6.975 5.625C6.85833 5.825 6.82083 6.0375 6.8625 6.2625C6.90417 6.4875 7.01667 6.65833 7.2 6.775C7.43333 6.90833 7.675 6.95 7.925 6.9C8.175 6.85 8.38333 6.70833 8.55 6.475C8.73333 6.225 8.9625 6.03333 9.2375 5.9C9.5125 5.76667 9.8 5.7 10.1 5.7Z"
        fill={fill}
      />
    </svg>
  );
};

export default HelpIconFilled;
