export const autoRecordFeature = "NEPH_FF_RECORDING_START_BY_DEFAULT";
export const smartRecommendationsFeature = "NEPH_FF_SMART_RECOMMENDATIONS";
export const interimPageFeature = "NEPH_FF_INTERIM_PAGE";
export const oidcFeature = "NEPH_FF_OIDC";
export const resetEncounters = "NEPH_FF_DEMO_RESET";

export function isFeatureEnabled(data, flag) {
  // Return true if the flag is set to true. Otherwise return false
  return data?.[flag] === true;
}
