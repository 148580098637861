import {
  Box,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import threedots from "assets/svg/light/threedots.svg";
import {
  deletePatientMedications,
  updatePatientMedications,
} from "redux/features/Medications/MedicationsSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/common/Typography/Text";
import { useQueryClient } from "@tanstack/react-query";
import { LogoIcon } from "assets/svg/Logo";
import Button from "components/common/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import CustomModal from "components/common/Modal/CustomModal";
import EditIcon from "assets/svg/light/editIcon";
import { Trash } from "components/common/SVG/Trash";
import AddMedication from "../AddMedication";
import { getDosageValue } from "utils/prescriptions";

const Accordian = ({ status = "", classes, data, id }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { patientId } = useParams();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  // For confirmation actions (Set Inactive, Refuse, Delete)
  const [pendingAction, setPendingAction] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // State for showing the AddMedication modal (for Edit or Active)
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalProps, setEditModalProps] = useState({}); // { title, medicationData, forceStatus }

  const { deletedMedicationSuccessfully } = useSelector(
    (state) => state.medications,
  );

  const handleMenuOpen = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMedsDelete = () => {
    setIsDeleting(true);
    dispatch(deletePatientMedications(patientId, id));
  };

  useEffect(() => {
    if (isDeleting && deletedMedicationSuccessfully) {
      handleMenuClose();
      queryClient.invalidateQueries({ queryKey: ["medications", patientId] });
      setIsDeleting(false);
    }
  }, [deletedMedicationSuccessfully, isDeleting, patientId, queryClient]);

  // Opens the AddMedication modal for edit or "active"
  const openEditModal = (actionType) => {
    if (actionType === "edit") {
      setEditModalProps({
        title: "Edit Medication",
        medicationData: data,
        forceStatus: null,
      });
    } else if (actionType === "active") {
      setEditModalProps({
        title: "Active",
        medicationData: data,
        forceStatus: "active",
      });
    }
    setEditModalOpen(true);
    handleMenuClose();
  };

  // Opens the generic confirmation modal for actions other than edit or set active
  const openConfirmationModal = (actionType) => {
    setPendingAction(actionType);
    handleMenuClose();
  };

  // Generic function to perform the requested action from the confirmation modal
  const performMedicationAction = () => {
    if (pendingAction === "stopped") {
      const payload = { ...data, end_date: new Date(), status: "stopped" };
      dispatch(updatePatientMedications(patientId, id, payload));
    } else if (pendingAction === "refused") {
      const payload = { ...data, end_date: new Date(), status: "refused" };
      dispatch(updatePatientMedications(patientId, id, payload));
    } else if (pendingAction === "delete") {
      handleMedsDelete();
    }
    setPendingAction(null);
  };

  // Modal content for the confirmation modal (for actions other than edit)
  const modalContent = (() => {
    switch (pendingAction) {
      case "stopped":
        return {
          title: "Inactive",
          message:
            "This action will set the medication as Inactive for this patient.",
        };
      case "refused":
        return {
          title: "Refuse Medication",
          message: "This confirms the patient has refused the medication.",
        };
      case "delete":
        return {
          title: "Delete Medication",
          message:
            "Are you sure? This will delete the medication from the system.",
        };
      default:
        return { title: "", message: "" };
    }
  })();

  const menuItems = [
    {
      label: "Edit",
      iconBoxSx: {
        margin: "0px 3px !important",
      },
      icon: (
        <EditIcon
          sx={{
            color: theme.palette.text.secondary,
            width: 18,
            height: 18,
            ml: "2px",
          }}
        />
      ),
      onClick: () => openEditModal("edit"),
    },
    {
      label: "Inactive",
      icon: (
        <CloseIcon
          sx={{ color: theme.palette.text.secondary, width: 18, height: 18 }}
        />
      ),
      onClick: () => openConfirmationModal("stopped"),
    },
    {
      label: "Active",
      icon: (
        <DoneIcon
          sx={{ color: theme.palette.text.secondary, width: 18, height: 18 }}
        />
      ),
      onClick: () => openEditModal("active"),
    },
    {
      label: "Refused",
      icon: (
        <CancelIcon
          sx={{ color: theme.palette.text.secondary, width: 18, height: 18 }}
        />
      ),
      onClick: () => openConfirmationModal("refused"),
    },
    {
      label: "Delete",
      iconBoxSx: {
        margin: "0px 3px !important",
      },
      icon: (
        <Trash
          sx={{
            width: 18,
            height: 18,
          }}
        />
      ),
      onClick: () => openConfirmationModal("delete"),
    },
  ];

  const getFilteredMenuItems = (statusValue, items) => {
    const processItems = (itemsList, disabledLabel) => {
      return itemsList
        .map((item) => ({
          ...item,
          disabled: item.label.toLowerCase() === disabledLabel,
        }))
        .sort((a, b) => {
          if (a.disabled && !b.disabled) return -1;
          if (!a.disabled && b.disabled) return 1;
          return 0;
        });
    };

    switch (statusValue?.toLowerCase()) {
      case "active":
        return processItems(items, "active");
      case "stopped":
        return processItems(
          items.filter((item) => item.label.toLowerCase() !== "edit"),
          "inactive",
        );
      case "refused":
        return processItems(
          items.filter(
            (item) => !["edit", "inactive"].includes(item.label.toLowerCase()),
          ),
          "refused",
        );
      default:
        return items;
    }
  };

  const accordianToggle = () => {
    if (data?.short_note) {
      setExpanded(!expanded);
    }
  };

  const getBackgroundColor = (statusValue) => {
    switch (statusValue) {
      case "refused":
        return theme?.palette?.common?.lightRed;
      case "active":
        return theme?.palette?.common?.softBlue;
      case "stopped":
        return "rgb(240, 240, 240)";
      default:
        return theme?.palette?.common?.softBlue;
    }
  };

  return (
    <Box data-testid="accordion-ui-medications-mainbox">
      <Accordion
        expanded={expanded}
        onClick={accordianToggle}
        data-testid="accordion-ui-medications-details"
        sx={{ boxShadow: "none" }}
        className={classes?.accordionRoot}
        slotProps={{
          transition: {
            unmountOnExit: true,
          },
        }}>
        <AccordionSummary
          sx={{
            background: getBackgroundColor(status),
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper": {
              marginRight: "8px",
            },
            borderRadius: expanded ? "8px 8px 0px 0px" : "8px",
          }}
          expandIcon={data?.short_note ? <ExpandMoreIcon /> : null}
          aria-controls={`panel-content`}
          id={`panel-header`}
          data-testid={`panel-header-medications-details`}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "8px",
                alignItems: "end",
              }}>
              <Box sx={{ maxWidth: "426px" }}>
                <Text
                  id="medication-heading"
                  fontWeight="700"
                  sx={{ margin: "0px" }}
                  variant="h3"
                  color={"black"}
                  data-testid="accordian-title-medications-details">
                  {data?.drug_name}
                </Text>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  padding: "4px",
                }}>
                <Text
                  variant={"body2"}
                  data-testid="scd-medication-detail-dispense-id"
                  sx={{ marginLeft: "10px", fontWeight: "500" }}>
                  {`${data?.dosage_quantity_value ? `${data.dosage_quantity_value}${data?.dosage_quantity_unit ? ` ${data.dosage_quantity_unit}` : ""}` : ""} ${getDosageValue(data)?.label ? `- ${getDosageValue(data).label}` : ""}`}
                </Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  padding: "4px",
                }}>
                <Text
                  variant={"body2"}
                  data-testid="scd-medication-detail-dispense-id"
                  sx={{
                    marginLeft: "10px",
                    fontWeight: "500",
                    whiteSpace: "break-spaces",
                  }}>
                  {data?.instructions
                    ? data.instructions.replace(/\([^()]*\)/g, "")
                    : ""}
                </Text>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {data?.etl_last_updated == null ? <LogoIcon /> : ""}
              <img
                data-testid="medication-delete-menu-open-id"
                style={{
                  marginRight: "4px",
                  width: "15px",
                  height: "15px",
                  flexShrink: 0,
                }}
                src={threedots}
                onClick={(event) => {
                  event.stopPropagation();
                  handleMenuOpen(event);
                }}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            background: getBackgroundColor(status),
            borderRadius: "0px 0px 8px 8px",
            marginBottom: "8px",
          }}
          data-testid="accordion-details">
          <Grid container rowSpacing={3} sx={{ marginBottom: "5px" }}>
            <Grid sx={{ marginLeft: "38px" }} item xs={6} sm={6} md={6} lg={6}>
              <Text variant="body2" sx={{ fontWeight: "600" }}>
                Notes
              </Text>{" "}
              <Text
                data-testid="scd-medication-detail-instruction-id"
                variant="body2">
                {data?.short_note ? data?.short_note : "N/A"}
              </Text>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        data-testid="menu-medications"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenuItem-root": {
            fontSize: "0.875rem",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
          "& .Mui-disabled": {
            opacity: 0.6,
            cursor: "not-allowed",
          },
        }}>
        {getFilteredMenuItems(status, menuItems).map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (!item.disabled) {
                item.onClick();
              }
            }}
            disabled={item.disabled}
            data-testid={`menu-item-${item.label
              .toLowerCase()
              .replace(/\s+/g, "-")}`}
            sx={{
              gap: "12px",
              width: "250px",
              borderBottom: item.disabled
                ? `1px solid ${theme.palette.common.grey}`
                : "none",
              "&.Mui-disabled": {
                opacity: 1,
                "& svg": {
                  color:
                    item.label.toLowerCase() === "active" ? "green" : "red",
                },
              },
            }}>
            {item.icon && (
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ...item.iconBoxSx,
                }}>
                {item.icon}
              </Box>
            )}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      {/* Confirmation Modal for actions like Set Inactive, Refuse, Delete */}
      <CustomModal
        setOpen={(open) => {
          if (!open) setPendingAction(null);
        }}
        open={pendingAction !== null}
        title={modalContent.title}
        sxTitleProps={{ fontSize: "20px", fontWeight: 700 }}
        maxWidth="sm">
        <Box mt={3}>
          <Box mb={2}>
            <Text variant="bodyS">{modalContent.message}</Text>
          </Box>
          <Box
            width="493px"
            display="flex"
            justifyContent="flex-end"
            gap={2}
            pb={0}
            mt={6}>
            <Button
              variant="outlined"
              id="cancel-button"
              text="Cancel"
              onClick={() => setPendingAction(null)}
            />
            <Button text="OK" onClick={performMedicationAction} />
          </Box>
        </Box>
      </CustomModal>
      {/* Render the AddMedication modal for Edit / Active */}
      {editModalOpen && (
        <CustomModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          title={editModalProps.title}
          maxWidth="sm"
          sxTitleProps={{ fontSize: "20px", fontWeight: 700 }}>
          <AddMedication
            medicationData={editModalProps.medicationData}
            setIsOpenModal={setEditModalOpen}
            forceStatus={editModalProps.forceStatus}
          />
        </CustomModal>
      )}
    </Box>
  );
};

export default Accordian;
