import { dosageMapping, frequencyOptions } from "assets/static/testData";

export const getDosageValue = (medicationData) => {
  if (medicationData?.dosage_period && medicationData?.dosage_period_unit) {
    const dosageKey = Object.keys(dosageMapping).find((key) => {
      const { dosage_period, dosage_period_unit } = dosageMapping[key];
      return (
        dosage_period === medicationData.dosage_period &&
        dosage_period_unit === medicationData.dosage_period_unit
      );
    });

    return frequencyOptions.find((option) => option.label === dosageKey);
  }
  return null;
};
