import { useContext } from "react";
import { Box, useTheme } from "@mui/material";
import MainLayoutContext from "contexts/MainLayoutContext";
import { getMainContainerBoxWidth } from "styles/AppContainer/MainContainerStyle";
import { useLocation } from "react-router-dom";

const MainContainer = ({ children, marginLeft, marginTop }) => {
  let theme = useTheme();
  const { title } = useContext(MainLayoutContext) || "";
  const { pathname } = useLocation();
  return (
    <Box
      className={title}
      sx={{
        minHeight: "50vh",
        marginTop: marginTop,
        marginLeft: {
          xs: "16px",
          sm: marginLeft,
        },
        marginRight: {
          xs: "16px",
          sm: "24px",
          md: "32px",
        },
        marginBottom: "20px",
        maxWidth: {
          xs: "calc(100vw - 32px)",
          sm: "calc(100vw - 103px)",
          md: "calc(100vw - 11px)",
          lg: "calc(100vw - 104px)",
          xl: "calc(100vw - 96px)",
          xxl: "calc(100vw - 112px)",
        },
        width: "100%",
        transition: (theme) =>
          theme.transitions.create(["margin-left", "max-width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        ...getMainContainerBoxWidth(theme, pathname),
      }}>
      {children}
    </Box>
  );
};

export default MainContainer;
